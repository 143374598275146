import './Maintainer.css'
import Navbar from '../components/Navbar'
import VehicleBoard from '../maintainer/VehicleBoard'


const Maintainer = () => {
  
  return (
    <div className='maintainer-page'>
      <Navbar />

      <div className="data-container">
        <div className='side-view'>
          <div className='depot-title-panel'>
            <span className='label-1'>DEPOT #49053</span>
          </div>
          <div className='tech-id-panel'>
            <p>Technician: SGT-8902</p>
            <p>Date: {new Date().toLocaleDateString()}</p>
          </div>
        </div>
        <div className='vehicle-board-container'>
          <VehicleBoard />
        </div>
        
      </div>
    </div>
  )
}

export default Maintainer