import "./MaintainerMetricPanel.css"
import FaultsWarningsMetricCard from "../components/cards/FaultsWarningsMetricCard"
import { FaultStats } from "../data/dataUtil"


const MetricPanel = ({ faultStats }: { faultStats: FaultStats }) => {
  
  return (
    <div className="metric-panel">

      <div className="vehicle-overview-card">
        <div className="content">
          <div>
            <div className="row">
              <span className="label">OPEN SERVICE REQUESTS</span>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <span className="value">{faultStats.totalFaults}</span>
            </div>
            <br/>
            <div className="row">
              <span className="label">ACTIVE FAULTS</span>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <span className="value">{faultStats.totalFaults}</span>
            </div>
          </div>
        </div>
      </div>

      <FaultsWarningsMetricCard title="Brakes" faultCount={faultStats.brakesFaults} imagePath="/images/icon-brakes-2.png" />
      <FaultsWarningsMetricCard title="Driveline" faultCount={faultStats.drivelineFaults} imagePath="/images/icon-driveline-2.png" />
      <FaultsWarningsMetricCard title="Electrical" faultCount={faultStats.electricalFaults} imagePath="/images/icon-electrical-2.png" />
      <FaultsWarningsMetricCard title="Engine" faultCount={faultStats.engineFaults} imagePath="/images/icon-engine-2.png" />
      <FaultsWarningsMetricCard title="Transmission" faultCount={faultStats.transmissionFaults} imagePath="/images/icon-transmission-2.png" />
    </div>
  )
}

export default MetricPanel
