import React from "react"
import { OperationStatus } from "../data/dataUtil"
import { SubSystem } from "../data/vehicleData6"


interface AppContextType {
  commandData: Array<Record<string, any>>;
  platformTypeOptions: string[];
  vehicleData: Array<Record<string, any>>;
  filteredVehicleData: Array<Record<string, any>>; 
  selectedCommandLevel: string;
  setSelectedCommandLevel: (commandLevel: string) => void;
  selectedOperationStatuses: OperationStatus[];
  setSelectedOperationStatuses: (statuses: OperationStatus[]) => void;
  selectedSubsystems: SubSystem[];
  setSelectedSubsystems: (subsystems: SubSystem[]) => void;
  selectedPlatforms: string[];
  setSelectedPlatforms: (platforms: string[]) => void;
}

const AppContext = React.createContext<AppContextType>({ 
  commandData: [], 
  vehicleData: [], 
  filteredVehicleData: [],
  platformTypeOptions: [], 
  selectedCommandLevel: '', 
  setSelectedCommandLevel: () => {},
  selectedOperationStatuses: [],
  setSelectedOperationStatuses: () => {},
  selectedSubsystems: [],
  setSelectedSubsystems: () => {},
  selectedPlatforms: [],
  setSelectedPlatforms: () => {}
})

export default AppContext
