export const depotData = [
    {
        "id": "1-67-A-002",
        "bumperNumber": "1-67-A-002",
        "registrationNumber": "2nd BCT1-67000002",
        "commandId": "HQ-A-1-67-2nd BCT",
        "unitAssignment": {
            "platoon": "Company Headquarters",
            "company": "A",
            "battalion": "1-67",
            "brigade": "2nd BCT",
            "division": "1st Armored"
        },
        "platform": "M2A4 Bradley",
        "platformCategory": "IFV",
        "platformType": "Combat Vehicle",
        "status": {
            "isDeadlined": false,
            "operationalStatus": "PMC",
            "maintenanceLevel": "10/20",
            "lastMaintenanceDate": "2024-11-24",
            "nextScheduledMaintenance": "2025-02-22",
            "fuelLevel": 86
        },
        "maintenanceHistory": {
            "mileage": 4200,
            "engineHours": 483,
            "records": [
                {
                    "workOrderNumber": "WO-2024-3",
                    "date": "2024-11-24",
                    "type": "Corrective",
                    "level": "10/20",
                    "subsystem": "Driveline",
                    "mileageAtService": 3468,
                    "hoursAtService": 447,
                    "technicianId": "SPC-854783",
                    "supervisorId": "SFC-213951",
                    "faults": [
                        {
                            "faultNumber": "F-2024-3",
                            "description": "Track tension issue",
                            "status": "In Progress",
                            "priority": "Medium",
                            "systemAffected": "NBC System",
                            "eic": "564",
                            "partsUsed": [
                                {
                                    "nsn": "2530-01-603-2462",
                                    "partName": "Track Assembly",
                                    "quantity": 1,
                                    "unitPrice": 1500
                                }
                            ],
                            "laborHours": 7,
                            "correctiveAction": "Replaced track assembly and verified operation"
                        }
                    ],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": false,
                        "category": "Repair",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-357816",
                        "inspectionDate": "2024-11-24",
                        "passed": false,
                        "remarks": "Maintenance ongoing - awaiting completion of repairs"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-4",
                    "date": "2024-06-20",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Brakes",
                    "mileageAtService": 3282,
                    "hoursAtService": 381,
                    "technicianId": "SGT-711440",
                    "supervisorId": "SFC-137790",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SFC-989295",
                        "inspectionDate": "2024-06-20",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-1",
                    "date": "2024-04-14",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Transmission",
                    "mileageAtService": 4200,
                    "hoursAtService": 483,
                    "technicianId": "SPC-559320",
                    "supervisorId": "SFC-423731",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SFC-993127",
                        "inspectionDate": "2024-04-14",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-2",
                    "date": "2024-01-05",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Driveline",
                    "mileageAtService": 3785,
                    "hoursAtService": 463,
                    "technicianId": "SGT-757",
                    "supervisorId": "SFC-215022",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-687613",
                        "inspectionDate": "2024-01-05",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                }
            ],
            "modifications": [],
            "recurringServices": [
                {
                    "serviceType": "Quarterly Service",
                    "lastPerformed": "2024-11-24",
                    "nextDue": "2025-02-22",
                    "interval": "90 days"
                },
                {
                    "serviceType": "Annual Service",
                    "lastPerformed": "2024-11-24",
                    "nextDue": "2025-11-24",
                    "interval": "365 days"
                }
            ]
        }
    },
    {
        "id": "1-67-A-102",
        "bumperNumber": "1-67-A-102",
        "registrationNumber": "2nd BCT1-67000103",
        "commandId": "1PLT-A-1-67-2nd BCT",
        "unitAssignment": {
            "platoon": "1st Platoon",
            "company": "A",
            "battalion": "1-67",
            "brigade": "2nd BCT",
            "division": "1st Armored"
        },
        "platform": "M2A4 Bradley",
        "platformCategory": "IFV",
        "platformType": "Combat Vehicle",
        "status": {
            "isDeadlined": true,
            "operationalStatus": "NMCM",
            "maintenanceLevel": "30",
            "lastMaintenanceDate": "2024-11-09",
            "nextScheduledMaintenance": "2025-02-07",
            "fuelLevel": 86
        },
        "maintenanceHistory": {
            "mileage": 3533,
            "engineHours": 359,
            "records": [
                {
                    "workOrderNumber": "WO-2024-1",
                    "date": "2024-11-09",
                    "type": "Corrective",
                    "level": "30",
                    "subsystem": "Transmission",
                    "mileageAtService": 3533,
                    "hoursAtService": 359,
                    "technicianId": "SGT-953310",
                    "supervisorId": "SFC-977787",
                    "faults": [
                        {
                            "faultNumber": "F-2024-1",
                            "description": "Ramp actuator malfunction",
                            "status": "In Progress",
                            "priority": "High",
                            "systemAffected": "Transmission",
                            "eic": "864",
                            "partsUsed": [
                                {
                                    "nsn": "2530-01-603-2462",
                                    "partName": "Ramp Actuator Assembly",
                                    "quantity": 1,
                                    "unitPrice": 1800
                                }
                            ],
                            "laborHours": 2,
                            "correctiveAction": "Replaced ramp actuator assembly and verified operation"
                        }
                    ],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": false,
                        "category": "Repair",
                        "deadlineStatus": true
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-283547",
                        "inspectionDate": "2024-11-09",
                        "passed": false,
                        "remarks": "Maintenance ongoing - awaiting completion of repairs"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-5",
                    "date": "2024-09-14",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Electrical",
                    "mileageAtService": 1737,
                    "hoursAtService": 351,
                    "technicianId": "SSG-140908",
                    "supervisorId": "SFC-858006",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-850615",
                        "inspectionDate": "2024-09-14",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-3",
                    "date": "2024-09-10",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Transmission",
                    "mileageAtService": 3221,
                    "hoursAtService": 345,
                    "technicianId": "SSG-841289",
                    "supervisorId": "SFC-593736",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SFC-992083",
                        "inspectionDate": "2024-09-10",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-2",
                    "date": "2024-08-16",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Brakes",
                    "mileageAtService": 3367,
                    "hoursAtService": 335,
                    "technicianId": "SSG-691085",
                    "supervisorId": "SFC-330534",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-352034",
                        "inspectionDate": "2024-08-16",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-4",
                    "date": "2024-06-09",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Electrical",
                    "mileageAtService": 2198,
                    "hoursAtService": 233,
                    "technicianId": "SSG-603821",
                    "supervisorId": "SFC-325104",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SFC-669933",
                        "inspectionDate": "2024-06-09",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                }
            ],
            "modifications": [],
            "recurringServices": [
                {
                    "serviceType": "Quarterly Service",
                    "lastPerformed": "2024-11-09",
                    "nextDue": "2025-02-07",
                    "interval": "90 days"
                },
                {
                    "serviceType": "Annual Service",
                    "lastPerformed": "2024-11-09",
                    "nextDue": "2025-11-09",
                    "interval": "365 days"
                }
            ]
        }
    },
    {
        "id": "1-67-A-103",
        "bumperNumber": "1-67-A-103",
        "registrationNumber": "2nd BCT1-67000104",
        "commandId": "1PLT-A-1-67-2nd BCT",
        "unitAssignment": {
            "platoon": "1st Platoon",
            "company": "A",
            "battalion": "1-67",
            "brigade": "2nd BCT",
            "division": "1st Armored"
        },
        "platform": "M2A4 Bradley",
        "platformCategory": "IFV",
        "platformType": "Combat Vehicle",
        "status": {
            "isDeadlined": true,
            "operationalStatus": "NMCM",
            "maintenanceLevel": "30",
            "lastMaintenanceDate": "2024-12-04",
            "nextScheduledMaintenance": "2025-03-04",
            "fuelLevel": 88
        },
        "maintenanceHistory": {
            "mileage": 6104,
            "engineHours": 674,
            "records": [
                {
                    "workOrderNumber": "WO-2024-3",
                    "date": "2024-12-04",
                    "type": "Corrective",
                    "level": "30",
                    "subsystem": "Transmission",
                    "mileageAtService": 5936,
                    "hoursAtService": 646,
                    "technicianId": "SPC-700500",
                    "supervisorId": "SSG-826518",
                    "faults": [
                        {
                            "faultNumber": "F-2024-3",
                            "description": "25mm feed system jam",
                            "status": "In Progress",
                            "priority": "High",
                            "systemAffected": "Hydraulics",
                            "eic": "341",
                            "partsUsed": [
                                {
                                    "nsn": "2530-01-603-2462",
                                    "partName": "Ramp Actuator Assembly",
                                    "quantity": 1,
                                    "unitPrice": 1800
                                }
                            ],
                            "laborHours": 6,
                            "correctiveAction": "Replaced ramp actuator assembly and verified operation"
                        }
                    ],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": false,
                        "category": "Repair",
                        "deadlineStatus": true
                    },
                    "qualityControl": {
                        "inspectorId": "SFC-494904",
                        "inspectionDate": "2024-12-04",
                        "passed": false,
                        "remarks": "Maintenance ongoing - awaiting completion of repairs"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-2",
                    "date": "2024-11-06",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Driveline",
                    "mileageAtService": 6096,
                    "hoursAtService": 642,
                    "technicianId": "SSG-771701",
                    "supervisorId": "SFC-825243",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-96068",
                        "inspectionDate": "2024-11-06",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-4",
                    "date": "2024-07-25",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Brakes",
                    "mileageAtService": 5498,
                    "hoursAtService": 671,
                    "technicianId": "SPC-700837",
                    "supervisorId": "SSG-225431",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-660186",
                        "inspectionDate": "2024-07-25",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-1",
                    "date": "2024-06-06",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Driveline",
                    "mileageAtService": 6104,
                    "hoursAtService": 674,
                    "technicianId": "SPC-935147",
                    "supervisorId": "SFC-853671",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SFC-467166",
                        "inspectionDate": "2024-06-06",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                }
            ],
            "modifications": [],
            "recurringServices": [
                {
                    "serviceType": "Quarterly Service",
                    "lastPerformed": "2024-12-04",
                    "nextDue": "2025-03-04",
                    "interval": "90 days"
                },
                {
                    "serviceType": "Annual Service",
                    "lastPerformed": "2024-12-04",
                    "nextDue": "2025-12-04",
                    "interval": "365 days"
                }
            ]
        }
    },
    {
        "id": "1-67-A-303",
        "bumperNumber": "1-67-A-303",
        "registrationNumber": "2nd BCT1-67000304",
        "commandId": "3PLT-A-1-67-2nd BCT",
        "unitAssignment": {
            "platoon": "3rd Platoon",
            "company": "A",
            "battalion": "1-67",
            "brigade": "2nd BCT",
            "division": "1st Armored"
        },
        "platform": "M2A4 Bradley",
        "platformCategory": "IFV",
        "platformType": "Combat Vehicle",
        "status": {
            "isDeadlined": true,
            "operationalStatus": "NMCM",
            "maintenanceLevel": "30",
            "lastMaintenanceDate": "2024-11-27",
            "nextScheduledMaintenance": "2025-02-25",
            "fuelLevel": 64
        },
        "maintenanceHistory": {
            "mileage": 2917,
            "engineHours": 272,
            "records": [
                {
                    "workOrderNumber": "WO-2024-1",
                    "date": "2024-11-27",
                    "type": "Corrective",
                    "level": "30",
                    "subsystem": "Driveline",
                    "mileageAtService": 2917,
                    "hoursAtService": 272,
                    "technicianId": "SSG-778476",
                    "supervisorId": "SFC-240433",
                    "faults": [
                        {
                            "faultNumber": "F-2024-1",
                            "description": "Track tension issue",
                            "status": "Open",
                            "priority": "High",
                            "systemAffected": "25mm Chain Gun",
                            "eic": "984",
                            "partsUsed": [
                                {
                                    "nsn": "1005-01-445-6098",
                                    "partName": "25mm Barrel",
                                    "quantity": 1,
                                    "unitPrice": 3200
                                }
                            ],
                            "laborHours": 2,
                            "correctiveAction": "Replaced 25mm barrel and verified operation"
                        }
                    ],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": false,
                        "category": "Repair",
                        "deadlineStatus": true
                    },
                    "qualityControl": {
                        "inspectorId": "SFC-737276",
                        "inspectionDate": "2024-11-27",
                        "passed": false,
                        "remarks": "Maintenance ongoing - awaiting completion of repairs"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-5",
                    "date": "2024-11-02",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Electrical",
                    "mileageAtService": 2825,
                    "hoursAtService": 252,
                    "technicianId": "SGT-529493",
                    "supervisorId": "SSG-681558",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-718586",
                        "inspectionDate": "2024-11-02",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-3",
                    "date": "2024-10-13",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Engine",
                    "mileageAtService": 2665,
                    "hoursAtService": 260,
                    "technicianId": "SPC-386809",
                    "supervisorId": "SFC-42021",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-219136",
                        "inspectionDate": "2024-10-13",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-6",
                    "date": "2024-06-17",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Brakes",
                    "mileageAtService": 2052,
                    "hoursAtService": 92,
                    "technicianId": "SSG-400648",
                    "supervisorId": "SSG-247750",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SFC-283687",
                        "inspectionDate": "2024-06-17",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-4",
                    "date": "2024-04-30",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Brakes",
                    "mileageAtService": 1948,
                    "hoursAtService": 218,
                    "technicianId": "SPC-585507",
                    "supervisorId": "SSG-753051",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SFC-993808",
                        "inspectionDate": "2024-04-30",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2023-2",
                    "date": "2023-12-16",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Electrical",
                    "mileageAtService": 2644,
                    "hoursAtService": 256,
                    "technicianId": "SSG-140899",
                    "supervisorId": "SFC-415502",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-40478",
                        "inspectionDate": "2023-12-16",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                }
            ],
            "modifications": [],
            "recurringServices": [
                {
                    "serviceType": "Quarterly Service",
                    "lastPerformed": "2024-11-27",
                    "nextDue": "2025-02-25",
                    "interval": "90 days"
                },
                {
                    "serviceType": "Annual Service",
                    "lastPerformed": "2024-11-27",
                    "nextDue": "2025-11-27",
                    "interval": "365 days"
                }
            ]
        }
    },
    {
        "id": "1-67-B-201",
        "bumperNumber": "1-67-B-201",
        "registrationNumber": "2nd BCT1-67000702",
        "commandId": "2PLT-B-1-67-2nd BCT",
        "unitAssignment": {
            "platoon": "2nd Platoon",
            "company": "B",
            "battalion": "1-67",
            "brigade": "2nd BCT",
            "division": "1st Armored"
        },
        "platform": "M2A4 Bradley",
        "platformCategory": "IFV",
        "platformType": "Combat Vehicle",
        "status": {
            "isDeadlined": false,
            "operationalStatus": "PMC",
            "maintenanceLevel": "10/20",
            "lastMaintenanceDate": "2024-11-24",
            "nextScheduledMaintenance": "2025-02-22",
            "fuelLevel": 91
        },
        "maintenanceHistory": {
            "mileage": 7969,
            "engineHours": 667,
            "records": [
                {
                    "workOrderNumber": "WO-2024-1",
                    "date": "2024-11-24",
                    "type": "Corrective",
                    "level": "10/20",
                    "subsystem": "Driveline",
                    "mileageAtService": 7969,
                    "hoursAtService": 667,
                    "technicianId": "SGT-905710",
                    "supervisorId": "SSG-848733",
                    "faults": [
                        {
                            "faultNumber": "F-2024-1",
                            "description": "Track tension issue",
                            "status": "Open",
                            "priority": "Medium",
                            "systemAffected": "Hydraulics",
                            "eic": "544",
                            "partsUsed": [
                                {
                                    "nsn": "1005-01-445-6098",
                                    "partName": "25mm Barrel",
                                    "quantity": 1,
                                    "unitPrice": 3200
                                }
                            ],
                            "laborHours": 6,
                            "correctiveAction": "Replaced 25mm barrel and verified operation"
                        }
                    ],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": false,
                        "category": "Repair",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SFC-138178",
                        "inspectionDate": "2024-11-24",
                        "passed": false,
                        "remarks": "Maintenance ongoing - awaiting completion of repairs"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-5",
                    "date": "2024-10-31",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Transmission",
                    "mileageAtService": 7481,
                    "hoursAtService": 611,
                    "technicianId": "SPC-580947",
                    "supervisorId": "SFC-598258",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SFC-80564",
                        "inspectionDate": "2024-10-31",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-4",
                    "date": "2024-09-05",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Transmission",
                    "mileageAtService": 7141,
                    "hoursAtService": 592,
                    "technicianId": "SSG-22574",
                    "supervisorId": "SFC-573071",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-70500",
                        "inspectionDate": "2024-09-05",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-2",
                    "date": "2024-07-17",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Transmission",
                    "mileageAtService": 7910,
                    "hoursAtService": 630,
                    "technicianId": "SPC-860200",
                    "supervisorId": "SFC-779147",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-203382",
                        "inspectionDate": "2024-07-17",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-3",
                    "date": "2024-04-27",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Engine",
                    "mileageAtService": 7057,
                    "hoursAtService": 637,
                    "technicianId": "SPC-507891",
                    "supervisorId": "SFC-621472",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-623397",
                        "inspectionDate": "2024-04-27",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                }
            ],
            "modifications": [],
            "recurringServices": [
                {
                    "serviceType": "Quarterly Service",
                    "lastPerformed": "2024-11-24",
                    "nextDue": "2025-02-22",
                    "interval": "90 days"
                },
                {
                    "serviceType": "Annual Service",
                    "lastPerformed": "2024-11-24",
                    "nextDue": "2025-11-24",
                    "interval": "365 days"
                }
            ]
        }
    },
    {
        "id": "1-67-C-303",
        "bumperNumber": "1-67-C-303",
        "registrationNumber": "2nd BCT1-67001304",
        "commandId": "3PLT-C-1-67-2nd BCT",
        "unitAssignment": {
            "platoon": "3rd Platoon",
            "company": "C",
            "battalion": "1-67",
            "brigade": "2nd BCT",
            "division": "1st Armored"
        },
        "platform": "M2A4 Bradley",
        "platformCategory": "IFV",
        "platformType": "Combat Vehicle",
        "status": {
            "isDeadlined": false,
            "operationalStatus": "PMC",
            "maintenanceLevel": "10/20",
            "lastMaintenanceDate": "2024-11-23",
            "nextScheduledMaintenance": "2025-02-21",
            "fuelLevel": 69
        },
        "maintenanceHistory": {
            "mileage": 4730,
            "engineHours": 443,
            "records": [
                {
                    "workOrderNumber": "WO-2024-3",
                    "date": "2024-11-23",
                    "type": "Corrective",
                    "level": "10/20",
                    "subsystem": "Driveline",
                    "mileageAtService": 4510,
                    "hoursAtService": 443,
                    "technicianId": "SPC-561959",
                    "supervisorId": "SFC-636024",
                    "faults": [
                        {
                            "faultNumber": "F-2024-3",
                            "description": "Track tension issue",
                            "status": "Open",
                            "priority": "Medium",
                            "systemAffected": "Engine",
                            "eic": "993",
                            "partsUsed": [
                                {
                                    "nsn": "2530-01-603-2462",
                                    "partName": "Track Assembly",
                                    "quantity": 1,
                                    "unitPrice": 1500
                                }
                            ],
                            "laborHours": 5,
                            "correctiveAction": "Replaced track assembly and verified operation"
                        }
                    ],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": false,
                        "category": "Repair",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-171323",
                        "inspectionDate": "2024-11-23",
                        "passed": false,
                        "remarks": "Maintenance ongoing - awaiting completion of repairs"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-1",
                    "date": "2024-09-20",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Brakes",
                    "mileageAtService": 4730,
                    "hoursAtService": 443,
                    "technicianId": "SGT-695091",
                    "supervisorId": "SFC-282953",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SFC-103523",
                        "inspectionDate": "2024-09-20",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-2",
                    "date": "2024-03-11",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Electrical",
                    "mileageAtService": 4474,
                    "hoursAtService": 429,
                    "technicianId": "SPC-531717",
                    "supervisorId": "SFC-335799",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-470721",
                        "inspectionDate": "2024-03-11",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                }
            ],
            "modifications": [],
            "recurringServices": [
                {
                    "serviceType": "Quarterly Service",
                    "lastPerformed": "2024-11-23",
                    "nextDue": "2025-02-21",
                    "interval": "90 days"
                },
                {
                    "serviceType": "Annual Service",
                    "lastPerformed": "2024-11-23",
                    "nextDue": "2025-11-23",
                    "interval": "365 days"
                }
            ]
        }
    },
    {
        "id": "1-67-D-104",
        "bumperNumber": "1-67-D-104",
        "registrationNumber": "2nd BCT1-67001605",
        "commandId": "1PLT-D-1-67-2nd BCT",
        "unitAssignment": {
            "platoon": "1st Platoon",
            "company": "D",
            "battalion": "1-67",
            "brigade": "2nd BCT",
            "division": "1st Armored"
        },
        "platform": "M1126 Stryker ICV DVH",
        "platformCategory": "APC",
        "platformType": "Combat Vehicle",
        "status": {
            "isDeadlined": true,
            "operationalStatus": "NMCM",
            "maintenanceLevel": "30",
            "lastMaintenanceDate": "2024-11-30",
            "nextScheduledMaintenance": "2025-02-28",
            "fuelLevel": 75
        },
        "maintenanceHistory": {
            "mileage": 6392,
            "engineHours": 516,
            "records": [
                {
                    "workOrderNumber": "WO-2024-6",
                    "date": "2024-11-30",
                    "type": "Corrective",
                    "level": "30",
                    "subsystem": "Electrical",
                    "mileageAtService": 4147,
                    "hoursAtService": 436,
                    "technicianId": "SPC-544567",
                    "supervisorId": "SSG-644523",
                    "faults": [
                        {
                            "faultNumber": "F-2024-6",
                            "description": "Remote weapons system malfunction",
                            "status": "In Progress",
                            "priority": "High",
                            "systemAffected": "Remote Weapons System",
                            "eic": "231",
                            "partsUsed": [
                                {
                                    "nsn": "1005-01-534-5678",
                                    "partName": "RWS Control Unit",
                                    "quantity": 1,
                                    "unitPrice": 4500
                                }
                            ],
                            "laborHours": 7,
                            "correctiveAction": "Replaced rws control unit and verified operation"
                        }
                    ],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": false,
                        "category": "Repair",
                        "deadlineStatus": true
                    },
                    "qualityControl": {
                        "inspectorId": "SFC-692553",
                        "inspectionDate": "2024-11-30",
                        "passed": false,
                        "remarks": "Maintenance ongoing - awaiting completion of repairs"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-4",
                    "date": "2024-09-19",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Transmission",
                    "mileageAtService": 5021,
                    "hoursAtService": 444,
                    "technicianId": "SSG-126343",
                    "supervisorId": "SFC-595806",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SFC-222275",
                        "inspectionDate": "2024-09-19",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-2",
                    "date": "2024-07-29",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Electrical",
                    "mileageAtService": 6318,
                    "hoursAtService": 491,
                    "technicianId": "SPC-819034",
                    "supervisorId": "SFC-746378",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-553755",
                        "inspectionDate": "2024-07-29",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-5",
                    "date": "2024-06-21",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Engine",
                    "mileageAtService": 6072,
                    "hoursAtService": 512,
                    "technicianId": "SPC-860469",
                    "supervisorId": "SSG-753614",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-993484",
                        "inspectionDate": "2024-06-21",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-3",
                    "date": "2024-02-01",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Driveline",
                    "mileageAtService": 5966,
                    "hoursAtService": 466,
                    "technicianId": "SSG-329006",
                    "supervisorId": "SFC-44133",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SFC-354945",
                        "inspectionDate": "2024-02-01",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2023-1",
                    "date": "2023-12-19",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Engine",
                    "mileageAtService": 6392,
                    "hoursAtService": 516,
                    "technicianId": "SGT-964559",
                    "supervisorId": "SSG-555315",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-143433",
                        "inspectionDate": "2023-12-19",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                }
            ],
            "modifications": [],
            "recurringServices": [
                {
                    "serviceType": "Quarterly Service",
                    "lastPerformed": "2024-11-30",
                    "nextDue": "2025-02-28",
                    "interval": "90 days"
                },
                {
                    "serviceType": "Annual Service",
                    "lastPerformed": "2024-11-30",
                    "nextDue": "2025-11-30",
                    "interval": "365 days"
                }
            ]
        }
    },
    {
        "id": "1-67-D-301",
        "bumperNumber": "1-67-D-301",
        "registrationNumber": "2nd BCT1-67001802",
        "commandId": "3PLT-D-1-67-2nd BCT",
        "unitAssignment": {
            "platoon": "3rd Platoon",
            "company": "D",
            "battalion": "1-67",
            "brigade": "2nd BCT",
            "division": "1st Armored"
        },
        "platform": "M1126 Stryker ICV DVH",
        "platformCategory": "APC",
        "platformType": "Combat Vehicle",
        "status": {
            "isDeadlined": true,
            "operationalStatus": "NMCM",
            "maintenanceLevel": "30",
            "lastMaintenanceDate": "2024-12-05",
            "nextScheduledMaintenance": "2025-03-05",
            "fuelLevel": 73
        },
        "maintenanceHistory": {
            "mileage": 2968,
            "engineHours": 318,
            "records": [
                {
                    "workOrderNumber": "WO-2024-2",
                    "date": "2024-12-05",
                    "type": "Corrective",
                    "level": "30",
                    "subsystem": "Engine",
                    "mileageAtService": 2702,
                    "hoursAtService": 300,
                    "technicianId": "SSG-869477",
                    "supervisorId": "SSG-817147",
                    "faults": [
                        {
                            "faultNumber": "F-2024-2",
                            "description": "Personnel door mechanism failure",
                            "status": "Open",
                            "priority": "High",
                            "systemAffected": "Drive Train",
                            "eic": "260",
                            "partsUsed": [
                                {
                                    "nsn": "2530-01-678-9012",
                                    "partName": "Tire Assembly",
                                    "quantity": 1,
                                    "unitPrice": 1200
                                }
                            ],
                            "laborHours": 3,
                            "correctiveAction": "Replaced tire assembly and verified operation"
                        }
                    ],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": false,
                        "category": "Repair",
                        "deadlineStatus": true
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-673530",
                        "inspectionDate": "2024-12-05",
                        "passed": false,
                        "remarks": "Maintenance ongoing - awaiting completion of repairs"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-3",
                    "date": "2024-12-03",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Driveline",
                    "mileageAtService": 2906,
                    "hoursAtService": 244,
                    "technicianId": "SPC-139080",
                    "supervisorId": "SFC-308236",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-354208",
                        "inspectionDate": "2024-12-03",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-1",
                    "date": "2024-04-13",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Driveline",
                    "mileageAtService": 2968,
                    "hoursAtService": 318,
                    "technicianId": "SGT-821363",
                    "supervisorId": "SFC-16947",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-341524",
                        "inspectionDate": "2024-04-13",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                }
            ],
            "modifications": [],
            "recurringServices": [
                {
                    "serviceType": "Quarterly Service",
                    "lastPerformed": "2024-12-05",
                    "nextDue": "2025-03-05",
                    "interval": "90 days"
                },
                {
                    "serviceType": "Annual Service",
                    "lastPerformed": "2024-12-05",
                    "nextDue": "2025-12-05",
                    "interval": "365 days"
                }
            ]
        }
    },
    {
        "id": "1-67-HHC-2008",
        "bumperNumber": "1-67-HHC-2008",
        "registrationNumber": "2nd BCT1-67002008",
        "commandId": "Medical-HHC-1-67-2nd BCT",
        "unitAssignment": {
            "platoon": "Medical Platoon",
            "company": "HHC",
            "battalion": "1-67",
            "brigade": "2nd BCT",
            "division": "1st Armored"
        },
        "platform": "AMPV Medical Evacuation",
        "platformCategory": "Support Vehicle",
        "platformType": "Support Vehicle",
        "status": {
            "isDeadlined": false,
            "operationalStatus": "PMC",
            "maintenanceLevel": "10/20",
            "lastMaintenanceDate": "2024-11-20",
            "nextScheduledMaintenance": "2025-02-18",
            "fuelLevel": 95
        },
        "maintenanceHistory": {
            "mileage": 6537,
            "engineHours": 596,
            "records": [
                {
                    "workOrderNumber": "WO-2024-3",
                    "date": "2024-11-20",
                    "type": "Corrective",
                    "level": "10/20",
                    "subsystem": "Brakes",
                    "mileageAtService": 6077,
                    "hoursAtService": 552,
                    "technicianId": "SPC-552589",
                    "supervisorId": "SFC-593741",
                    "faults": [
                        {
                            "faultNumber": "F-2024-3",
                            "description": "Air brake system fault",
                            "status": "In Progress",
                            "priority": "Medium",
                            "systemAffected": "Electrical System",
                            "eic": "594",
                            "partsUsed": [
                                {
                                    "nsn": "2930-01-890-1234",
                                    "partName": "Cooling System",
                                    "quantity": 1,
                                    "unitPrice": 2000
                                }
                            ],
                            "laborHours": 4,
                            "correctiveAction": "Replaced cooling system and verified operation"
                        }
                    ],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": false,
                        "category": "Repair",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SFC-933776",
                        "inspectionDate": "2024-11-20",
                        "passed": false,
                        "remarks": "Maintenance ongoing - awaiting completion of repairs"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-4",
                    "date": "2024-08-18",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Electrical",
                    "mileageAtService": 5865,
                    "hoursAtService": 548,
                    "technicianId": "SPC-573406",
                    "supervisorId": "SFC-703455",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SFC-48605",
                        "inspectionDate": "2024-08-18",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-2",
                    "date": "2024-05-13",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Transmission",
                    "mileageAtService": 6147,
                    "hoursAtService": 596,
                    "technicianId": "SGT-783041",
                    "supervisorId": "SFC-877771",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-194626",
                        "inspectionDate": "2024-05-13",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-1",
                    "date": "2024-02-23",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Transmission",
                    "mileageAtService": 6537,
                    "hoursAtService": 596,
                    "technicianId": "SSG-828520",
                    "supervisorId": "SFC-652408",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SFC-380151",
                        "inspectionDate": "2024-02-23",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                }
            ],
            "modifications": [],
            "recurringServices": [
                {
                    "serviceType": "Quarterly Service",
                    "lastPerformed": "2024-11-20",
                    "nextDue": "2025-02-18",
                    "interval": "90 days"
                },
                {
                    "serviceType": "Annual Service",
                    "lastPerformed": "2024-11-20",
                    "nextDue": "2025-11-20",
                    "interval": "365 days"
                }
            ]
        }
    },
    {
        "id": "1-67-HHC-2010",
        "bumperNumber": "1-67-HHC-2010",
        "registrationNumber": "2nd BCT1-67002010",
        "commandId": "Medical-HHC-1-67-2nd BCT",
        "unitAssignment": {
            "platoon": "Medical Platoon",
            "company": "HHC",
            "battalion": "1-67",
            "brigade": "2nd BCT",
            "division": "1st Armored"
        },
        "platform": "AMPV Medical Evacuation",
        "platformCategory": "Support Vehicle",
        "platformType": "Support Vehicle",
        "status": {
            "isDeadlined": false,
            "operationalStatus": "PMC",
            "maintenanceLevel": "10/20",
            "lastMaintenanceDate": "2024-11-23",
            "nextScheduledMaintenance": "2025-02-21",
            "fuelLevel": 67
        },
        "maintenanceHistory": {
            "mileage": 5489,
            "engineHours": 551,
            "records": [
                {
                    "workOrderNumber": "WO-2024-3",
                    "date": "2024-11-23",
                    "type": "Corrective",
                    "level": "10/20",
                    "subsystem": "Engine",
                    "mileageAtService": 4633,
                    "hoursAtService": 469,
                    "technicianId": "SPC-302251",
                    "supervisorId": "SFC-733318",
                    "faults": [
                        {
                            "faultNumber": "F-2024-3",
                            "description": "Engine overheating",
                            "status": "Open",
                            "priority": "Medium",
                            "systemAffected": "Suspension",
                            "eic": "197",
                            "partsUsed": [
                                {
                                    "nsn": "2530-01-789-0123",
                                    "partName": "Brake Assembly",
                                    "quantity": 1,
                                    "unitPrice": 1500
                                }
                            ],
                            "laborHours": 6,
                            "correctiveAction": "Replaced brake assembly and verified operation"
                        }
                    ],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": false,
                        "category": "Repair",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-933182",
                        "inspectionDate": "2024-11-23",
                        "passed": false,
                        "remarks": "Maintenance ongoing - awaiting completion of repairs"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-1",
                    "date": "2024-05-10",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Engine",
                    "mileageAtService": 5489,
                    "hoursAtService": 551,
                    "technicianId": "SSG-725029",
                    "supervisorId": "SSG-143347",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-952000",
                        "inspectionDate": "2024-05-10",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-2",
                    "date": "2024-02-05",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Driveline",
                    "mileageAtService": 5097,
                    "hoursAtService": 541,
                    "technicianId": "SGT-414841",
                    "supervisorId": "SSG-710771",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-977069",
                        "inspectionDate": "2024-02-05",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                }
            ],
            "modifications": [],
            "recurringServices": [
                {
                    "serviceType": "Quarterly Service",
                    "lastPerformed": "2024-11-23",
                    "nextDue": "2025-02-21",
                    "interval": "90 days"
                },
                {
                    "serviceType": "Annual Service",
                    "lastPerformed": "2024-11-23",
                    "nextDue": "2025-11-23",
                    "interval": "365 days"
                }
            ]
        }
    },
    {
        "id": "2-67-A-202",
        "bumperNumber": "2-67-A-202",
        "registrationNumber": "2nd BCT2-67010203",
        "commandId": "2PLT-A-2-67-2nd BCT",
        "unitAssignment": {
            "platoon": "2nd Platoon",
            "company": "A",
            "battalion": "2-67",
            "brigade": "2nd BCT",
            "division": "1st Armored"
        },
        "platform": "M2A4 Bradley",
        "platformCategory": "IFV",
        "platformType": "Combat Vehicle",
        "status": {
            "isDeadlined": false,
            "operationalStatus": "PMC",
            "maintenanceLevel": "10/20",
            "lastMaintenanceDate": "2024-11-13",
            "nextScheduledMaintenance": "2025-02-11",
            "fuelLevel": 70
        },
        "maintenanceHistory": {
            "mileage": 2398,
            "engineHours": 269,
            "records": [
                {
                    "workOrderNumber": "WO-2024-5",
                    "date": "2024-11-13",
                    "type": "Corrective",
                    "level": "10/20",
                    "subsystem": "Electrical",
                    "mileageAtService": 1918,
                    "hoursAtService": 241,
                    "technicianId": "SPC-511319",
                    "supervisorId": "SSG-8527",
                    "faults": [
                        {
                            "faultNumber": "F-2024-5",
                            "description": "Power distribution fault",
                            "status": "In Progress",
                            "priority": "Medium",
                            "systemAffected": "Hydraulics",
                            "eic": "504",
                            "partsUsed": [
                                {
                                    "nsn": "2530-01-603-2462",
                                    "partName": "Ramp Actuator Assembly",
                                    "quantity": 1,
                                    "unitPrice": 1800
                                }
                            ],
                            "laborHours": 5,
                            "correctiveAction": "Replaced ramp actuator assembly and verified operation"
                        }
                    ],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": false,
                        "category": "Repair",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-18982",
                        "inspectionDate": "2024-11-13",
                        "passed": false,
                        "remarks": "Maintenance ongoing - awaiting completion of repairs"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-2",
                    "date": "2024-11-09",
                    "type": "Corrective",
                    "level": "10/20",
                    "subsystem": "Brakes",
                    "mileageAtService": 1989,
                    "hoursAtService": 269,
                    "technicianId": "SGT-266687",
                    "supervisorId": "SSG-572465",
                    "faults": [
                        {
                            "faultNumber": "F-2024-2",
                            "description": "Hydraulic pressure loss",
                            "status": "In Progress",
                            "priority": "Medium",
                            "systemAffected": "Hydraulics",
                            "eic": "017",
                            "partsUsed": [
                                {
                                    "nsn": "2530-01-603-2462",
                                    "partName": "Ramp Actuator Assembly",
                                    "quantity": 1,
                                    "unitPrice": 1800
                                }
                            ],
                            "laborHours": 7,
                            "correctiveAction": "Replaced ramp actuator assembly and verified operation"
                        }
                    ],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": false,
                        "category": "Repair",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-367291",
                        "inspectionDate": "2024-11-09",
                        "passed": false,
                        "remarks": "Maintenance ongoing - awaiting completion of repairs"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-1",
                    "date": "2024-09-12",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Transmission",
                    "mileageAtService": 2398,
                    "hoursAtService": 269,
                    "technicianId": "SPC-351949",
                    "supervisorId": "SSG-58482",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-374972",
                        "inspectionDate": "2024-09-12",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-3",
                    "date": "2024-01-28",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Electrical",
                    "mileageAtService": 2264,
                    "hoursAtService": 193,
                    "technicianId": "SSG-153681",
                    "supervisorId": "SFC-610736",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SFC-284249",
                        "inspectionDate": "2024-01-28",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2023-4",
                    "date": "2023-12-26",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Transmission",
                    "mileageAtService": 2032,
                    "hoursAtService": 173,
                    "technicianId": "SGT-665387",
                    "supervisorId": "SFC-11183",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SFC-740477",
                        "inspectionDate": "2023-12-26",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                }
            ],
            "modifications": [],
            "recurringServices": [
                {
                    "serviceType": "Quarterly Service",
                    "lastPerformed": "2024-11-13",
                    "nextDue": "2025-02-11",
                    "interval": "90 days"
                },
                {
                    "serviceType": "Annual Service",
                    "lastPerformed": "2024-11-13",
                    "nextDue": "2025-11-13",
                    "interval": "365 days"
                }
            ]
        }
    },
    {
        "id": "2-67-D-103",
        "bumperNumber": "2-67-D-103",
        "registrationNumber": "2nd BCT2-67011604",
        "commandId": "1PLT-D-2-67-2nd BCT",
        "unitAssignment": {
            "platoon": "1st Platoon",
            "company": "D",
            "battalion": "2-67",
            "brigade": "2nd BCT",
            "division": "1st Armored"
        },
        "platform": "M1126 Stryker ICV DVH",
        "platformCategory": "APC",
        "platformType": "Combat Vehicle",
        "status": {
            "isDeadlined": false,
            "operationalStatus": "PMC",
            "maintenanceLevel": "10/20",
            "lastMaintenanceDate": "2024-11-11",
            "nextScheduledMaintenance": "2025-02-09",
            "fuelLevel": 90
        },
        "maintenanceHistory": {
            "mileage": 8397,
            "engineHours": 937,
            "records": [
                {
                    "workOrderNumber": "WO-2024-1",
                    "date": "2024-11-11",
                    "type": "Corrective",
                    "level": "10/20",
                    "subsystem": "Engine",
                    "mileageAtService": 8397,
                    "hoursAtService": 937,
                    "technicianId": "SSG-704125",
                    "supervisorId": "SSG-785473",
                    "faults": [
                        {
                            "faultNumber": "F-2024-1",
                            "description": "Tire pressure monitoring system error",
                            "status": "In Progress",
                            "priority": "Medium",
                            "systemAffected": "Weapons Station",
                            "eic": "940",
                            "partsUsed": [
                                {
                                    "nsn": "1005-01-534-5678",
                                    "partName": "RWS Control Unit",
                                    "quantity": 1,
                                    "unitPrice": 4500
                                }
                            ],
                            "laborHours": 7,
                            "correctiveAction": "Replaced rws control unit and verified operation"
                        }
                    ],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": false,
                        "category": "Repair",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SFC-63258",
                        "inspectionDate": "2024-11-11",
                        "passed": false,
                        "remarks": "Maintenance ongoing - awaiting completion of repairs"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-5",
                    "date": "2024-10-01",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Brakes",
                    "mileageAtService": 6521,
                    "hoursAtService": 825,
                    "technicianId": "SGT-291025",
                    "supervisorId": "SFC-83929",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SFC-688193",
                        "inspectionDate": "2024-10-01",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-4",
                    "date": "2024-09-05",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Driveline",
                    "mileageAtService": 8148,
                    "hoursAtService": 808,
                    "technicianId": "SPC-896621",
                    "supervisorId": "SSG-608625",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-21546",
                        "inspectionDate": "2024-09-05",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-6",
                    "date": "2024-07-10",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Engine",
                    "mileageAtService": 6692,
                    "hoursAtService": 887,
                    "technicianId": "SGT-925047",
                    "supervisorId": "SFC-275300",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-15258",
                        "inspectionDate": "2024-07-10",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-2",
                    "date": "2024-06-05",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Transmission",
                    "mileageAtService": 8171,
                    "hoursAtService": 896,
                    "technicianId": "SGT-912177",
                    "supervisorId": "SSG-796509",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-619441",
                        "inspectionDate": "2024-06-05",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2023-3",
                    "date": "2023-12-28",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Electrical",
                    "mileageAtService": 7903,
                    "hoursAtService": 855,
                    "technicianId": "SSG-440605",
                    "supervisorId": "SFC-156482",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-673207",
                        "inspectionDate": "2023-12-28",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                }
            ],
            "modifications": [],
            "recurringServices": [
                {
                    "serviceType": "Quarterly Service",
                    "lastPerformed": "2024-11-11",
                    "nextDue": "2025-02-09",
                    "interval": "90 days"
                },
                {
                    "serviceType": "Annual Service",
                    "lastPerformed": "2024-11-11",
                    "nextDue": "2025-11-11",
                    "interval": "365 days"
                }
            ]
        }
    },
    {
        "id": "2-67-D-104",
        "bumperNumber": "2-67-D-104",
        "registrationNumber": "2nd BCT2-67011605",
        "commandId": "1PLT-D-2-67-2nd BCT",
        "unitAssignment": {
            "platoon": "1st Platoon",
            "company": "D",
            "battalion": "2-67",
            "brigade": "2nd BCT",
            "division": "1st Armored"
        },
        "platform": "M1126 Stryker ICV DVH",
        "platformCategory": "APC",
        "platformType": "Combat Vehicle",
        "status": {
            "isDeadlined": false,
            "operationalStatus": "PMC",
            "maintenanceLevel": "10/20",
            "lastMaintenanceDate": "2024-12-07",
            "nextScheduledMaintenance": "2025-03-07",
            "fuelLevel": 73
        },
        "maintenanceHistory": {
            "mileage": 2752,
            "engineHours": 315,
            "records": [
                {
                    "workOrderNumber": "WO-2024-1",
                    "date": "2024-12-07",
                    "type": "Corrective",
                    "level": "10/20",
                    "subsystem": "Driveline",
                    "mileageAtService": 2752,
                    "hoursAtService": 315,
                    "technicianId": "SSG-793248",
                    "supervisorId": "SFC-771789",
                    "faults": [
                        {
                            "faultNumber": "F-2024-1",
                            "description": "Personnel door mechanism failure",
                            "status": "Open",
                            "priority": "Medium",
                            "systemAffected": "NBC System",
                            "eic": "883",
                            "partsUsed": [
                                {
                                    "nsn": "2530-01-678-9012",
                                    "partName": "Tire Assembly",
                                    "quantity": 1,
                                    "unitPrice": 1200
                                }
                            ],
                            "laborHours": 3,
                            "correctiveAction": "Replaced tire assembly and verified operation"
                        }
                    ],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": false,
                        "category": "Repair",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-936410",
                        "inspectionDate": "2024-12-07",
                        "passed": false,
                        "remarks": "Maintenance ongoing - awaiting completion of repairs"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-3",
                    "date": "2024-09-11",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Driveline",
                    "mileageAtService": 2082,
                    "hoursAtService": 315,
                    "technicianId": "SGT-484472",
                    "supervisorId": "SSG-128069",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-339900",
                        "inspectionDate": "2024-09-11",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-4",
                    "date": "2024-03-25",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Electrical",
                    "mileageAtService": 2281,
                    "hoursAtService": 171,
                    "technicianId": "SPC-216368",
                    "supervisorId": "SFC-337664",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SFC-766936",
                        "inspectionDate": "2024-03-25",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-2",
                    "date": "2024-01-18",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Transmission",
                    "mileageAtService": 2483,
                    "hoursAtService": 290,
                    "technicianId": "SPC-968547",
                    "supervisorId": "SSG-303102",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SFC-825112",
                        "inspectionDate": "2024-01-18",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                }
            ],
            "modifications": [],
            "recurringServices": [
                {
                    "serviceType": "Quarterly Service",
                    "lastPerformed": "2024-12-07",
                    "nextDue": "2025-03-07",
                    "interval": "90 days"
                },
                {
                    "serviceType": "Annual Service",
                    "lastPerformed": "2024-12-07",
                    "nextDue": "2025-12-07",
                    "interval": "365 days"
                }
            ]
        }
    },
    {
        "id": "2-67-D-202",
        "bumperNumber": "2-67-D-202",
        "registrationNumber": "2nd BCT2-67011703",
        "commandId": "2PLT-D-2-67-2nd BCT",
        "unitAssignment": {
            "platoon": "2nd Platoon",
            "company": "D",
            "battalion": "2-67",
            "brigade": "2nd BCT",
            "division": "1st Armored"
        },
        "platform": "M1126 Stryker ICV DVH",
        "platformCategory": "APC",
        "platformType": "Combat Vehicle",
        "status": {
            "isDeadlined": true,
            "operationalStatus": "NMCM",
            "maintenanceLevel": "30",
            "lastMaintenanceDate": "2024-12-05",
            "nextScheduledMaintenance": "2025-03-05",
            "fuelLevel": 63
        },
        "maintenanceHistory": {
            "mileage": 4973,
            "engineHours": 425,
            "records": [
                {
                    "workOrderNumber": "WO-2024-2",
                    "date": "2024-12-05",
                    "type": "Corrective",
                    "level": "30",
                    "subsystem": "Transmission",
                    "mileageAtService": 4639,
                    "hoursAtService": 376,
                    "technicianId": "SPC-498454",
                    "supervisorId": "SSG-902484",
                    "faults": [
                        {
                            "faultNumber": "F-2024-2",
                            "description": "Remote weapons system malfunction",
                            "status": "In Progress",
                            "priority": "High",
                            "systemAffected": "Hull Electronics",
                            "eic": "190",
                            "partsUsed": [
                                {
                                    "nsn": "2530-01-612-3456",
                                    "partName": "Wheel Hub Assembly",
                                    "quantity": 1,
                                    "unitPrice": 2200
                                }
                            ],
                            "laborHours": 5,
                            "correctiveAction": "Replaced wheel hub assembly and verified operation"
                        }
                    ],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": false,
                        "category": "Repair",
                        "deadlineStatus": true
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-83506",
                        "inspectionDate": "2024-12-05",
                        "passed": false,
                        "remarks": "Maintenance ongoing - awaiting completion of repairs"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-1",
                    "date": "2024-09-24",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Driveline",
                    "mileageAtService": 4973,
                    "hoursAtService": 425,
                    "technicianId": "SSG-895676",
                    "supervisorId": "SSG-479931",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SFC-701901",
                        "inspectionDate": "2024-09-24",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-4",
                    "date": "2024-06-23",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Engine",
                    "mileageAtService": 4631,
                    "hoursAtService": 362,
                    "technicianId": "SSG-557979",
                    "supervisorId": "SSG-849135",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-366375",
                        "inspectionDate": "2024-06-23",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-3",
                    "date": "2024-03-31",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Brakes",
                    "mileageAtService": 4869,
                    "hoursAtService": 373,
                    "technicianId": "SGT-414736",
                    "supervisorId": "SSG-827193",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SFC-912475",
                        "inspectionDate": "2024-03-31",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-5",
                    "date": "2024-02-03",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Engine",
                    "mileageAtService": 3629,
                    "hoursAtService": 417,
                    "technicianId": "SGT-493848",
                    "supervisorId": "SSG-618476",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SFC-413477",
                        "inspectionDate": "2024-02-03",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                }
            ],
            "modifications": [],
            "recurringServices": [
                {
                    "serviceType": "Quarterly Service",
                    "lastPerformed": "2024-12-05",
                    "nextDue": "2025-03-05",
                    "interval": "90 days"
                },
                {
                    "serviceType": "Annual Service",
                    "lastPerformed": "2024-12-05",
                    "nextDue": "2025-12-05",
                    "interval": "365 days"
                }
            ]
        }
    },
    {
        "id": "2-67-D-304",
        "bumperNumber": "2-67-D-304",
        "registrationNumber": "2nd BCT2-67011805",
        "commandId": "3PLT-D-2-67-2nd BCT",
        "unitAssignment": {
            "platoon": "3rd Platoon",
            "company": "D",
            "battalion": "2-67",
            "brigade": "2nd BCT",
            "division": "1st Armored"
        },
        "platform": "M1126 Stryker ICV DVH",
        "platformCategory": "APC",
        "platformType": "Combat Vehicle",
        "status": {
            "isDeadlined": false,
            "operationalStatus": "PMC",
            "maintenanceLevel": "10/20",
            "lastMaintenanceDate": "2024-11-23",
            "nextScheduledMaintenance": "2025-02-21",
            "fuelLevel": 89
        },
        "maintenanceHistory": {
            "mileage": 8857,
            "engineHours": 922,
            "records": [
                {
                    "workOrderNumber": "WO-2024-3",
                    "date": "2024-11-23",
                    "type": "Corrective",
                    "level": "10/20",
                    "subsystem": "Transmission",
                    "mileageAtService": 8755,
                    "hoursAtService": 882,
                    "technicianId": "SSG-760369",
                    "supervisorId": "SSG-865470",
                    "faults": [
                        {
                            "faultNumber": "F-2024-3",
                            "description": "Tire pressure monitoring system error",
                            "status": "In Progress",
                            "priority": "Medium",
                            "systemAffected": "Transmission",
                            "eic": "182",
                            "partsUsed": [
                                {
                                    "nsn": "2530-01-612-3456",
                                    "partName": "Wheel Hub Assembly",
                                    "quantity": 1,
                                    "unitPrice": 2200
                                }
                            ],
                            "laborHours": 5,
                            "correctiveAction": "Replaced wheel hub assembly and verified operation"
                        }
                    ],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": false,
                        "category": "Repair",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-117483",
                        "inspectionDate": "2024-11-23",
                        "passed": false,
                        "remarks": "Maintenance ongoing - awaiting completion of repairs"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-2",
                    "date": "2024-02-25",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Engine",
                    "mileageAtService": 8850,
                    "hoursAtService": 909,
                    "technicianId": "SSG-766229",
                    "supervisorId": "SSG-583951",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SFC-207947",
                        "inspectionDate": "2024-02-25",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2023-1",
                    "date": "2023-12-24",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Driveline",
                    "mileageAtService": 8857,
                    "hoursAtService": 922,
                    "technicianId": "SSG-970661",
                    "supervisorId": "SSG-301458",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SFC-933053",
                        "inspectionDate": "2023-12-24",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2023-4",
                    "date": "2023-12-12",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Transmission",
                    "mileageAtService": 8842,
                    "hoursAtService": 844,
                    "technicianId": "SPC-486594",
                    "supervisorId": "SFC-742862",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-281420",
                        "inspectionDate": "2023-12-12",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                }
            ],
            "modifications": [],
            "recurringServices": [
                {
                    "serviceType": "Quarterly Service",
                    "lastPerformed": "2024-11-23",
                    "nextDue": "2025-02-21",
                    "interval": "90 days"
                },
                {
                    "serviceType": "Annual Service",
                    "lastPerformed": "2024-11-23",
                    "nextDue": "2025-11-23",
                    "interval": "365 days"
                }
            ]
        }
    },
    {
        "id": "2-67-HHC-12009",
        "bumperNumber": "2-67-HHC-12009",
        "registrationNumber": "2nd BCT2-67012009",
        "commandId": "Medical-HHC-2-67-2nd BCT",
        "unitAssignment": {
            "platoon": "Medical Platoon",
            "company": "HHC",
            "battalion": "2-67",
            "brigade": "2nd BCT",
            "division": "1st Armored"
        },
        "platform": "AMPV Medical Evacuation",
        "platformCategory": "Support Vehicle",
        "platformType": "Support Vehicle",
        "status": {
            "isDeadlined": false,
            "operationalStatus": "PMC",
            "maintenanceLevel": "10/20",
            "lastMaintenanceDate": "2024-11-15",
            "nextScheduledMaintenance": "2025-02-13",
            "fuelLevel": 77
        },
        "maintenanceHistory": {
            "mileage": 9516,
            "engineHours": 1028,
            "records": [
                {
                    "workOrderNumber": "WO-2024-3",
                    "date": "2024-11-15",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Transmission",
                    "mileageAtService": 9370,
                    "hoursAtService": 934,
                    "technicianId": "SGT-227900",
                    "supervisorId": "SFC-910030",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SFC-401363",
                        "inspectionDate": "2024-11-15",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-2",
                    "date": "2024-11-09",
                    "type": "Corrective",
                    "level": "10/20",
                    "subsystem": "Engine",
                    "mileageAtService": 9516,
                    "hoursAtService": 1026,
                    "technicianId": "SSG-349648",
                    "supervisorId": "SFC-964013",
                    "faults": [
                        {
                            "faultNumber": "F-2024-2",
                            "description": "Engine overheating",
                            "status": "Open",
                            "priority": "Medium",
                            "systemAffected": "Climate Control",
                            "eic": "109",
                            "partsUsed": [
                                {
                                    "nsn": "2930-01-890-1234",
                                    "partName": "Cooling System",
                                    "quantity": 1,
                                    "unitPrice": 2000
                                }
                            ],
                            "laborHours": 6,
                            "correctiveAction": "Replaced cooling system and verified operation"
                        }
                    ],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": false,
                        "category": "Repair",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SFC-845822",
                        "inspectionDate": "2024-11-09",
                        "passed": false,
                        "remarks": "Maintenance ongoing - awaiting completion of repairs"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-4",
                    "date": "2024-11-05",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Engine",
                    "mileageAtService": 9180,
                    "hoursAtService": 1019,
                    "technicianId": "SGT-232382",
                    "supervisorId": "SFC-535996",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SFC-748078",
                        "inspectionDate": "2024-11-05",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-1",
                    "date": "2024-03-27",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Engine",
                    "mileageAtService": 9516,
                    "hoursAtService": 1028,
                    "technicianId": "SGT-967662",
                    "supervisorId": "SFC-119889",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SFC-203093",
                        "inspectionDate": "2024-03-27",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                }
            ],
            "modifications": [],
            "recurringServices": [
                {
                    "serviceType": "Quarterly Service",
                    "lastPerformed": "2024-11-15",
                    "nextDue": "2025-02-13",
                    "interval": "90 days"
                },
                {
                    "serviceType": "Annual Service",
                    "lastPerformed": "2024-11-15",
                    "nextDue": "2025-11-15",
                    "interval": "365 days"
                }
            ]
        }
    },
    {
        "id": "3-67-B-202",
        "bumperNumber": "3-67-B-202",
        "registrationNumber": "2nd BCT3-67020703",
        "commandId": "2PLT-B-3-67-2nd BCT",
        "unitAssignment": {
            "platoon": "2nd Platoon",
            "company": "B",
            "battalion": "3-67",
            "brigade": "2nd BCT",
            "division": "1st Armored"
        },
        "platform": "M2A4 Bradley",
        "platformCategory": "IFV",
        "platformType": "Combat Vehicle",
        "status": {
            "isDeadlined": false,
            "operationalStatus": "PMC",
            "maintenanceLevel": "10/20",
            "lastMaintenanceDate": "2024-12-03",
            "nextScheduledMaintenance": "2025-03-03",
            "fuelLevel": 95
        },
        "maintenanceHistory": {
            "mileage": 4365,
            "engineHours": 493,
            "records": [
                {
                    "workOrderNumber": "WO-2024-4",
                    "date": "2024-12-03",
                    "type": "Corrective",
                    "level": "10/20",
                    "subsystem": "Electrical",
                    "mileageAtService": 3732,
                    "hoursAtService": 349,
                    "technicianId": "SSG-378829",
                    "supervisorId": "SSG-725646",
                    "faults": [
                        {
                            "faultNumber": "F-2024-4",
                            "description": "25mm feed system jam",
                            "status": "Open",
                            "priority": "Medium",
                            "systemAffected": "Drive Train",
                            "eic": "942",
                            "partsUsed": [
                                {
                                    "nsn": "2530-01-603-2462",
                                    "partName": "Track Assembly",
                                    "quantity": 1,
                                    "unitPrice": 1500
                                }
                            ],
                            "laborHours": 5,
                            "correctiveAction": "Replaced track assembly and verified operation"
                        }
                    ],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": false,
                        "category": "Repair",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-366343",
                        "inspectionDate": "2024-12-03",
                        "passed": false,
                        "remarks": "Maintenance ongoing - awaiting completion of repairs"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-5",
                    "date": "2024-11-15",
                    "type": "Corrective",
                    "level": "10/20",
                    "subsystem": "Electrical",
                    "mileageAtService": 2937,
                    "hoursAtService": 305,
                    "technicianId": "SPC-762368",
                    "supervisorId": "SSG-964522",
                    "faults": [
                        {
                            "faultNumber": "F-2024-5",
                            "description": "Power distribution fault",
                            "status": "In Progress",
                            "priority": "Medium",
                            "systemAffected": "Hull Electronics",
                            "eic": "707",
                            "partsUsed": [
                                {
                                    "nsn": "1005-01-445-6098",
                                    "partName": "25mm Barrel",
                                    "quantity": 1,
                                    "unitPrice": 3200
                                }
                            ],
                            "laborHours": 5,
                            "correctiveAction": "Replaced 25mm barrel and verified operation"
                        }
                    ],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": false,
                        "category": "Repair",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SFC-977525",
                        "inspectionDate": "2024-11-15",
                        "passed": false,
                        "remarks": "Maintenance ongoing - awaiting completion of repairs"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-2",
                    "date": "2024-09-22",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Transmission",
                    "mileageAtService": 4243,
                    "hoursAtService": 447,
                    "technicianId": "SPC-783806",
                    "supervisorId": "SSG-427322",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-682126",
                        "inspectionDate": "2024-09-22",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-3",
                    "date": "2024-04-03",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Driveline",
                    "mileageAtService": 3453,
                    "hoursAtService": 461,
                    "technicianId": "SPC-451016",
                    "supervisorId": "SFC-819179",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-15553",
                        "inspectionDate": "2024-04-03",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-6",
                    "date": "2024-04-01",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Electrical",
                    "mileageAtService": 3200,
                    "hoursAtService": 373,
                    "technicianId": "SGT-487746",
                    "supervisorId": "SSG-513904",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SFC-226606",
                        "inspectionDate": "2024-04-01",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-1",
                    "date": "2024-01-05",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Brakes",
                    "mileageAtService": 4365,
                    "hoursAtService": 493,
                    "technicianId": "SPC-522629",
                    "supervisorId": "SSG-411542",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-421227",
                        "inspectionDate": "2024-01-05",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                }
            ],
            "modifications": [],
            "recurringServices": [
                {
                    "serviceType": "Quarterly Service",
                    "lastPerformed": "2024-12-03",
                    "nextDue": "2025-03-03",
                    "interval": "90 days"
                },
                {
                    "serviceType": "Annual Service",
                    "lastPerformed": "2024-12-03",
                    "nextDue": "2025-12-03",
                    "interval": "365 days"
                }
            ]
        }
    },
    {
        "id": "3-67-B-303",
        "bumperNumber": "3-67-B-303",
        "registrationNumber": "2nd BCT3-67020804",
        "commandId": "3PLT-B-3-67-2nd BCT",
        "unitAssignment": {
            "platoon": "3rd Platoon",
            "company": "B",
            "battalion": "3-67",
            "brigade": "2nd BCT",
            "division": "1st Armored"
        },
        "platform": "M2A4 Bradley",
        "platformCategory": "IFV",
        "platformType": "Combat Vehicle",
        "status": {
            "isDeadlined": true,
            "operationalStatus": "NMCM",
            "maintenanceLevel": "30",
            "lastMaintenanceDate": "2024-12-04",
            "nextScheduledMaintenance": "2025-03-04",
            "fuelLevel": 96
        },
        "maintenanceHistory": {
            "mileage": 3355,
            "engineHours": 277,
            "records": [
                {
                    "workOrderNumber": "WO-2024-3",
                    "date": "2024-12-04",
                    "type": "Corrective",
                    "level": "30",
                    "subsystem": "Electrical",
                    "mileageAtService": 3081,
                    "hoursAtService": 217,
                    "technicianId": "SSG-94872",
                    "supervisorId": "SSG-165259",
                    "faults": [
                        {
                            "faultNumber": "F-2024-3",
                            "description": "Ramp actuator malfunction",
                            "status": "Open",
                            "priority": "High",
                            "systemAffected": "Transmission",
                            "eic": "387",
                            "partsUsed": [
                                {
                                    "nsn": "2530-01-603-2462",
                                    "partName": "Ramp Actuator Assembly",
                                    "quantity": 1,
                                    "unitPrice": 1800
                                }
                            ],
                            "laborHours": 4,
                            "correctiveAction": "Replaced ramp actuator assembly and verified operation"
                        }
                    ],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": false,
                        "category": "Repair",
                        "deadlineStatus": true
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-866981",
                        "inspectionDate": "2024-12-04",
                        "passed": false,
                        "remarks": "Maintenance ongoing - awaiting completion of repairs"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-2",
                    "date": "2024-11-16",
                    "type": "Corrective",
                    "level": "30",
                    "subsystem": "Driveline",
                    "mileageAtService": 3061,
                    "hoursAtService": 259,
                    "technicianId": "SPC-605808",
                    "supervisorId": "SFC-523500",
                    "faults": [
                        {
                            "faultNumber": "F-2024-2",
                            "description": "Track tension issue",
                            "status": "In Progress",
                            "priority": "High",
                            "systemAffected": "Weapons System",
                            "eic": "567",
                            "partsUsed": [
                                {
                                    "nsn": "2530-01-603-2462",
                                    "partName": "Track Assembly",
                                    "quantity": 1,
                                    "unitPrice": 1500
                                }
                            ],
                            "laborHours": 4,
                            "correctiveAction": "Replaced track assembly and verified operation"
                        }
                    ],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": false,
                        "category": "Repair",
                        "deadlineStatus": true
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-656633",
                        "inspectionDate": "2024-11-16",
                        "passed": false,
                        "remarks": "Maintenance ongoing - awaiting completion of repairs"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-6",
                    "date": "2024-10-30",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Electrical",
                    "mileageAtService": 3280,
                    "hoursAtService": 62,
                    "technicianId": "SPC-568535",
                    "supervisorId": "SFC-487369",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-836795",
                        "inspectionDate": "2024-10-30",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-1",
                    "date": "2024-08-02",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Brakes",
                    "mileageAtService": 3355,
                    "hoursAtService": 277,
                    "technicianId": "SSG-584452",
                    "supervisorId": "SFC-405107",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-793548",
                        "inspectionDate": "2024-08-02",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-4",
                    "date": "2024-04-22",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Driveline",
                    "mileageAtService": 2128,
                    "hoursAtService": 229,
                    "technicianId": "SSG-967522",
                    "supervisorId": "SFC-151172",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-839135",
                        "inspectionDate": "2024-04-22",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-5",
                    "date": "2024-03-17",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Transmission",
                    "mileageAtService": 3307,
                    "hoursAtService": 185,
                    "technicianId": "SPC-681049",
                    "supervisorId": "SFC-575390",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-443677",
                        "inspectionDate": "2024-03-17",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                }
            ],
            "modifications": [],
            "recurringServices": [
                {
                    "serviceType": "Quarterly Service",
                    "lastPerformed": "2024-12-04",
                    "nextDue": "2025-03-04",
                    "interval": "90 days"
                },
                {
                    "serviceType": "Annual Service",
                    "lastPerformed": "2024-12-04",
                    "nextDue": "2025-12-04",
                    "interval": "365 days"
                }
            ]
        }
    },
    {
        "id": "3-67-C-102",
        "bumperNumber": "3-67-C-102",
        "registrationNumber": "2nd BCT3-67021103",
        "commandId": "1PLT-C-3-67-2nd BCT",
        "unitAssignment": {
            "platoon": "1st Platoon",
            "company": "C",
            "battalion": "3-67",
            "brigade": "2nd BCT",
            "division": "1st Armored"
        },
        "platform": "M2A4 Bradley",
        "platformCategory": "IFV",
        "platformType": "Combat Vehicle",
        "status": {
            "isDeadlined": false,
            "operationalStatus": "PMC",
            "maintenanceLevel": "10/20",
            "lastMaintenanceDate": "2024-11-14",
            "nextScheduledMaintenance": "2025-02-12",
            "fuelLevel": 87
        },
        "maintenanceHistory": {
            "mileage": 4775,
            "engineHours": 509,
            "records": [
                {
                    "workOrderNumber": "WO-2024-1",
                    "date": "2024-11-14",
                    "type": "Corrective",
                    "level": "10/20",
                    "subsystem": "Transmission",
                    "mileageAtService": 4775,
                    "hoursAtService": 509,
                    "technicianId": "SSG-921663",
                    "supervisorId": "SFC-754248",
                    "faults": [
                        {
                            "faultNumber": "F-2024-1",
                            "description": "Ramp actuator malfunction",
                            "status": "In Progress",
                            "priority": "Medium",
                            "systemAffected": "Hull Electronics",
                            "eic": "510",
                            "partsUsed": [
                                {
                                    "nsn": "1005-01-445-6098",
                                    "partName": "25mm Barrel",
                                    "quantity": 1,
                                    "unitPrice": 3200
                                }
                            ],
                            "laborHours": 6,
                            "correctiveAction": "Replaced 25mm barrel and verified operation"
                        }
                    ],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": false,
                        "category": "Repair",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-228013",
                        "inspectionDate": "2024-11-14",
                        "passed": false,
                        "remarks": "Maintenance ongoing - awaiting completion of repairs"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-2",
                    "date": "2024-07-17",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Engine",
                    "mileageAtService": 4466,
                    "hoursAtService": 492,
                    "technicianId": "SSG-227992",
                    "supervisorId": "SSG-325501",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-48059",
                        "inspectionDate": "2024-07-17",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-4",
                    "date": "2024-04-12",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Driveline",
                    "mileageAtService": 3434,
                    "hoursAtService": 389,
                    "technicianId": "SGT-478953",
                    "supervisorId": "SFC-191571",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SFC-766561",
                        "inspectionDate": "2024-04-12",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-3",
                    "date": "2024-01-11",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Electrical",
                    "mileageAtService": 4161,
                    "hoursAtService": 435,
                    "technicianId": "SPC-924560",
                    "supervisorId": "SFC-162764",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SFC-440559",
                        "inspectionDate": "2024-01-11",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                }
            ],
            "modifications": [],
            "recurringServices": [
                {
                    "serviceType": "Quarterly Service",
                    "lastPerformed": "2024-11-14",
                    "nextDue": "2025-02-12",
                    "interval": "90 days"
                },
                {
                    "serviceType": "Annual Service",
                    "lastPerformed": "2024-11-14",
                    "nextDue": "2025-11-14",
                    "interval": "365 days"
                }
            ]
        }
    },
    {
        "id": "3-67-C-201",
        "bumperNumber": "3-67-C-201",
        "registrationNumber": "2nd BCT3-67021202",
        "commandId": "2PLT-C-3-67-2nd BCT",
        "unitAssignment": {
            "platoon": "2nd Platoon",
            "company": "C",
            "battalion": "3-67",
            "brigade": "2nd BCT",
            "division": "1st Armored"
        },
        "platform": "M2A4 Bradley",
        "platformCategory": "IFV",
        "platformType": "Combat Vehicle",
        "status": {
            "isDeadlined": false,
            "operationalStatus": "PMC",
            "maintenanceLevel": "10/20",
            "lastMaintenanceDate": "2024-11-30",
            "nextScheduledMaintenance": "2025-02-28",
            "fuelLevel": 95
        },
        "maintenanceHistory": {
            "mileage": 5164,
            "engineHours": 510,
            "records": [
                {
                    "workOrderNumber": "WO-2024-1",
                    "date": "2024-11-30",
                    "type": "Corrective",
                    "level": "10/20",
                    "subsystem": "Brakes",
                    "mileageAtService": 5164,
                    "hoursAtService": 510,
                    "technicianId": "SSG-780885",
                    "supervisorId": "SSG-649918",
                    "faults": [
                        {
                            "faultNumber": "F-2024-1",
                            "description": "25mm feed system jam",
                            "status": "In Progress",
                            "priority": "Medium",
                            "systemAffected": "Hydraulics",
                            "eic": "944",
                            "partsUsed": [
                                {
                                    "nsn": "2530-01-603-2462",
                                    "partName": "Track Assembly",
                                    "quantity": 1,
                                    "unitPrice": 1500
                                }
                            ],
                            "laborHours": 3,
                            "correctiveAction": "Replaced track assembly and verified operation"
                        }
                    ],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": false,
                        "category": "Repair",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-986279",
                        "inspectionDate": "2024-11-30",
                        "passed": false,
                        "remarks": "Maintenance ongoing - awaiting completion of repairs"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-2",
                    "date": "2024-11-12",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Engine",
                    "mileageAtService": 4793,
                    "hoursAtService": 498,
                    "technicianId": "SGT-847199",
                    "supervisorId": "SFC-947699",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-587286",
                        "inspectionDate": "2024-11-12",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-5",
                    "date": "2024-11-09",
                    "type": "Corrective",
                    "level": "10/20",
                    "subsystem": "Electrical",
                    "mileageAtService": 3460,
                    "hoursAtService": 386,
                    "technicianId": "SGT-118771",
                    "supervisorId": "SSG-554970",
                    "faults": [
                        {
                            "faultNumber": "F-2024-5",
                            "description": "Power distribution fault",
                            "status": "Open",
                            "priority": "Medium",
                            "systemAffected": "Transmission",
                            "eic": "236",
                            "partsUsed": [
                                {
                                    "nsn": "1005-01-445-6098",
                                    "partName": "25mm Barrel",
                                    "quantity": 1,
                                    "unitPrice": 3200
                                }
                            ],
                            "laborHours": 5,
                            "correctiveAction": "Replaced 25mm barrel and verified operation"
                        }
                    ],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": false,
                        "category": "Repair",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SFC-213205",
                        "inspectionDate": "2024-11-09",
                        "passed": false,
                        "remarks": "Maintenance ongoing - awaiting completion of repairs"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-6",
                    "date": "2024-09-13",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Engine",
                    "mileageAtService": 2904,
                    "hoursAtService": 465,
                    "technicianId": "SSG-757529",
                    "supervisorId": "SSG-249141",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SFC-528247",
                        "inspectionDate": "2024-09-13",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-4",
                    "date": "2024-08-05",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Driveline",
                    "mileageAtService": 4633,
                    "hoursAtService": 372,
                    "technicianId": "SGT-2038",
                    "supervisorId": "SSG-952100",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-186147",
                        "inspectionDate": "2024-08-05",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-3",
                    "date": "2024-02-24",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Electrical",
                    "mileageAtService": 4726,
                    "hoursAtService": 462,
                    "technicianId": "SSG-898472",
                    "supervisorId": "SFC-107297",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-544823",
                        "inspectionDate": "2024-02-24",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                }
            ],
            "modifications": [],
            "recurringServices": [
                {
                    "serviceType": "Quarterly Service",
                    "lastPerformed": "2024-11-30",
                    "nextDue": "2025-02-28",
                    "interval": "90 days"
                },
                {
                    "serviceType": "Annual Service",
                    "lastPerformed": "2024-11-30",
                    "nextDue": "2025-11-30",
                    "interval": "365 days"
                }
            ]
        }
    },
    {
        "id": "3-67-C-204",
        "bumperNumber": "3-67-C-204",
        "registrationNumber": "2nd BCT3-67021205",
        "commandId": "2PLT-C-3-67-2nd BCT",
        "unitAssignment": {
            "platoon": "2nd Platoon",
            "company": "C",
            "battalion": "3-67",
            "brigade": "2nd BCT",
            "division": "1st Armored"
        },
        "platform": "M2A4 Bradley",
        "platformCategory": "IFV",
        "platformType": "Combat Vehicle",
        "status": {
            "isDeadlined": true,
            "operationalStatus": "NMCM",
            "maintenanceLevel": "30",
            "lastMaintenanceDate": "2024-11-13",
            "nextScheduledMaintenance": "2025-02-11",
            "fuelLevel": 94
        },
        "maintenanceHistory": {
            "mileage": 7175,
            "engineHours": 735,
            "records": [
                {
                    "workOrderNumber": "WO-2024-2",
                    "date": "2024-11-13",
                    "type": "Corrective",
                    "level": "30",
                    "subsystem": "Driveline",
                    "mileageAtService": 6921,
                    "hoursAtService": 708,
                    "technicianId": "SGT-257286",
                    "supervisorId": "SFC-93383",
                    "faults": [
                        {
                            "faultNumber": "F-2024-2",
                            "description": "Track tension issue",
                            "status": "In Progress",
                            "priority": "High",
                            "systemAffected": "Hydraulics",
                            "eic": "550",
                            "partsUsed": [
                                {
                                    "nsn": "2530-01-603-2462",
                                    "partName": "Ramp Actuator Assembly",
                                    "quantity": 1,
                                    "unitPrice": 1800
                                }
                            ],
                            "laborHours": 4,
                            "correctiveAction": "Replaced ramp actuator assembly and verified operation"
                        }
                    ],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": false,
                        "category": "Repair",
                        "deadlineStatus": true
                    },
                    "qualityControl": {
                        "inspectorId": "SFC-933143",
                        "inspectionDate": "2024-11-13",
                        "passed": false,
                        "remarks": "Maintenance ongoing - awaiting completion of repairs"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-6",
                    "date": "2024-09-19",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Electrical",
                    "mileageAtService": 5010,
                    "hoursAtService": 595,
                    "technicianId": "SPC-207781",
                    "supervisorId": "SFC-632029",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-421538",
                        "inspectionDate": "2024-09-19",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-4",
                    "date": "2024-06-26",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Transmission",
                    "mileageAtService": 6209,
                    "hoursAtService": 723,
                    "technicianId": "SGT-931323",
                    "supervisorId": "SSG-953685",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SFC-47161",
                        "inspectionDate": "2024-06-26",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-1",
                    "date": "2024-03-15",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Transmission",
                    "mileageAtService": 7175,
                    "hoursAtService": 735,
                    "technicianId": "SSG-719434",
                    "supervisorId": "SSG-227251",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SFC-105982",
                        "inspectionDate": "2024-03-15",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-5",
                    "date": "2024-02-19",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Driveline",
                    "mileageAtService": 5811,
                    "hoursAtService": 607,
                    "technicianId": "SSG-41321",
                    "supervisorId": "SSG-942257",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-941975",
                        "inspectionDate": "2024-02-19",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-3",
                    "date": "2024-01-13",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Driveline",
                    "mileageAtService": 6595,
                    "hoursAtService": 663,
                    "technicianId": "SPC-769387",
                    "supervisorId": "SFC-465929",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-800616",
                        "inspectionDate": "2024-01-13",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                }
            ],
            "modifications": [],
            "recurringServices": [
                {
                    "serviceType": "Quarterly Service",
                    "lastPerformed": "2024-11-13",
                    "nextDue": "2025-02-11",
                    "interval": "90 days"
                },
                {
                    "serviceType": "Annual Service",
                    "lastPerformed": "2024-11-13",
                    "nextDue": "2025-11-13",
                    "interval": "365 days"
                }
            ]
        }
    },
    {
        "id": "3-67-C-301",
        "bumperNumber": "3-67-C-301",
        "registrationNumber": "2nd BCT3-67021302",
        "commandId": "3PLT-C-3-67-2nd BCT",
        "unitAssignment": {
            "platoon": "3rd Platoon",
            "company": "C",
            "battalion": "3-67",
            "brigade": "2nd BCT",
            "division": "1st Armored"
        },
        "platform": "M2A4 Bradley",
        "platformCategory": "IFV",
        "platformType": "Combat Vehicle",
        "status": {
            "isDeadlined": false,
            "operationalStatus": "PMC",
            "maintenanceLevel": "10/20",
            "lastMaintenanceDate": "2024-12-03",
            "nextScheduledMaintenance": "2025-03-03",
            "fuelLevel": 81
        },
        "maintenanceHistory": {
            "mileage": 7786,
            "engineHours": 926,
            "records": [
                {
                    "workOrderNumber": "WO-2024-2",
                    "date": "2024-12-03",
                    "type": "Corrective",
                    "level": "10/20",
                    "subsystem": "Electrical",
                    "mileageAtService": 7475,
                    "hoursAtService": 897,
                    "technicianId": "SSG-48245",
                    "supervisorId": "SFC-767694",
                    "faults": [
                        {
                            "faultNumber": "F-2024-2",
                            "description": "Power distribution fault",
                            "status": "In Progress",
                            "priority": "Medium",
                            "systemAffected": "Transmission",
                            "eic": "167",
                            "partsUsed": [
                                {
                                    "nsn": "2530-01-603-2462",
                                    "partName": "Track Assembly",
                                    "quantity": 1,
                                    "unitPrice": 1500
                                }
                            ],
                            "laborHours": 4,
                            "correctiveAction": "Replaced track assembly and verified operation"
                        }
                    ],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": false,
                        "category": "Repair",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-857676",
                        "inspectionDate": "2024-12-03",
                        "passed": false,
                        "remarks": "Maintenance ongoing - awaiting completion of repairs"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-4",
                    "date": "2024-10-28",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Driveline",
                    "mileageAtService": 6337,
                    "hoursAtService": 917,
                    "technicianId": "SPC-765874",
                    "supervisorId": "SSG-679265",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-748280",
                        "inspectionDate": "2024-10-28",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-5",
                    "date": "2024-08-11",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Brakes",
                    "mileageAtService": 7190,
                    "hoursAtService": 862,
                    "technicianId": "SSG-923197",
                    "supervisorId": "SSG-830230",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SFC-619953",
                        "inspectionDate": "2024-08-11",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-1",
                    "date": "2024-03-08",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Electrical",
                    "mileageAtService": 7786,
                    "hoursAtService": 926,
                    "technicianId": "SSG-753896",
                    "supervisorId": "SSG-446499",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-520715",
                        "inspectionDate": "2024-03-08",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-3",
                    "date": "2024-02-04",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Electrical",
                    "mileageAtService": 7194,
                    "hoursAtService": 836,
                    "technicianId": "SGT-833581",
                    "supervisorId": "SFC-408712",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SFC-142484",
                        "inspectionDate": "2024-02-04",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                }
            ],
            "modifications": [],
            "recurringServices": [
                {
                    "serviceType": "Quarterly Service",
                    "lastPerformed": "2024-12-03",
                    "nextDue": "2025-03-03",
                    "interval": "90 days"
                },
                {
                    "serviceType": "Annual Service",
                    "lastPerformed": "2024-12-03",
                    "nextDue": "2025-12-03",
                    "interval": "365 days"
                }
            ]
        }
    },
    {
        "id": "3-67-D-204",
        "bumperNumber": "3-67-D-204",
        "registrationNumber": "2nd BCT3-67021705",
        "commandId": "2PLT-D-3-67-2nd BCT",
        "unitAssignment": {
            "platoon": "2nd Platoon",
            "company": "D",
            "battalion": "3-67",
            "brigade": "2nd BCT",
            "division": "1st Armored"
        },
        "platform": "M1126 Stryker ICV DVH",
        "platformCategory": "APC",
        "platformType": "Combat Vehicle",
        "status": {
            "isDeadlined": false,
            "operationalStatus": "PMC",
            "maintenanceLevel": "10/20",
            "lastMaintenanceDate": "2024-11-26",
            "nextScheduledMaintenance": "2025-02-24",
            "fuelLevel": 98
        },
        "maintenanceHistory": {
            "mileage": 7608,
            "engineHours": 895,
            "records": [
                {
                    "workOrderNumber": "WO-2024-1",
                    "date": "2024-11-26",
                    "type": "Corrective",
                    "level": "10/20",
                    "subsystem": "Driveline",
                    "mileageAtService": 7608,
                    "hoursAtService": 895,
                    "technicianId": "SGT-132616",
                    "supervisorId": "SSG-921134",
                    "faults": [
                        {
                            "faultNumber": "F-2024-1",
                            "description": "Suspension system fault",
                            "status": "Open",
                            "priority": "Medium",
                            "systemAffected": "Weapons Station",
                            "eic": "538",
                            "partsUsed": [
                                {
                                    "nsn": "1005-01-534-5678",
                                    "partName": "RWS Control Unit",
                                    "quantity": 1,
                                    "unitPrice": 4500
                                }
                            ],
                            "laborHours": 4,
                            "correctiveAction": "Replaced rws control unit and verified operation"
                        }
                    ],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": false,
                        "category": "Repair",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-725889",
                        "inspectionDate": "2024-11-26",
                        "passed": false,
                        "remarks": "Maintenance ongoing - awaiting completion of repairs"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-5",
                    "date": "2024-08-30",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Engine",
                    "mileageAtService": 7364,
                    "hoursAtService": 711,
                    "technicianId": "SGT-750162",
                    "supervisorId": "SFC-759578",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-292851",
                        "inspectionDate": "2024-08-30",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-4",
                    "date": "2024-03-14",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Transmission",
                    "mileageAtService": 7290,
                    "hoursAtService": 748,
                    "technicianId": "SSG-765101",
                    "supervisorId": "SSG-206727",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SFC-107233",
                        "inspectionDate": "2024-03-14",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-2",
                    "date": "2024-03-05",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Electrical",
                    "mileageAtService": 7158,
                    "hoursAtService": 849,
                    "technicianId": "SPC-457360",
                    "supervisorId": "SSG-44516",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SFC-411496",
                        "inspectionDate": "2024-03-05",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-3",
                    "date": "2024-02-28",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Brakes",
                    "mileageAtService": 7344,
                    "hoursAtService": 879,
                    "technicianId": "SGT-823036",
                    "supervisorId": "SSG-192831",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SFC-14207",
                        "inspectionDate": "2024-02-28",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                }
            ],
            "modifications": [],
            "recurringServices": [
                {
                    "serviceType": "Quarterly Service",
                    "lastPerformed": "2024-11-26",
                    "nextDue": "2025-02-24",
                    "interval": "90 days"
                },
                {
                    "serviceType": "Annual Service",
                    "lastPerformed": "2024-11-26",
                    "nextDue": "2025-11-26",
                    "interval": "365 days"
                }
            ]
        }
    },
    {
        "id": "3-67-D-302",
        "bumperNumber": "3-67-D-302",
        "registrationNumber": "2nd BCT3-67021803",
        "commandId": "3PLT-D-3-67-2nd BCT",
        "unitAssignment": {
            "platoon": "3rd Platoon",
            "company": "D",
            "battalion": "3-67",
            "brigade": "2nd BCT",
            "division": "1st Armored"
        },
        "platform": "M1126 Stryker ICV DVH",
        "platformCategory": "APC",
        "platformType": "Combat Vehicle",
        "status": {
            "isDeadlined": true,
            "operationalStatus": "NMCM",
            "maintenanceLevel": "30",
            "lastMaintenanceDate": "2024-11-23",
            "nextScheduledMaintenance": "2025-02-21",
            "fuelLevel": 73
        },
        "maintenanceHistory": {
            "mileage": 4829,
            "engineHours": 452,
            "records": [
                {
                    "workOrderNumber": "WO-2024-5",
                    "date": "2024-11-23",
                    "type": "Corrective",
                    "level": "30",
                    "subsystem": "Driveline",
                    "mileageAtService": 4581,
                    "hoursAtService": 356,
                    "technicianId": "SGT-277140",
                    "supervisorId": "SFC-500450",
                    "faults": [
                        {
                            "faultNumber": "F-2024-5",
                            "description": "Suspension system fault",
                            "status": "In Progress",
                            "priority": "High",
                            "systemAffected": "Hull Electronics",
                            "eic": "977",
                            "partsUsed": [
                                {
                                    "nsn": "2530-01-612-3456",
                                    "partName": "Wheel Hub Assembly",
                                    "quantity": 1,
                                    "unitPrice": 2200
                                }
                            ],
                            "laborHours": 4,
                            "correctiveAction": "Replaced wheel hub assembly and verified operation"
                        }
                    ],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": false,
                        "category": "Repair",
                        "deadlineStatus": true
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-849911",
                        "inspectionDate": "2024-11-23",
                        "passed": false,
                        "remarks": "Maintenance ongoing - awaiting completion of repairs"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-4",
                    "date": "2024-09-13",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Engine",
                    "mileageAtService": 4067,
                    "hoursAtService": 386,
                    "technicianId": "SPC-432367",
                    "supervisorId": "SFC-764220",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-318854",
                        "inspectionDate": "2024-09-13",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-3",
                    "date": "2024-08-22",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Engine",
                    "mileageAtService": 3933,
                    "hoursAtService": 386,
                    "technicianId": "SPC-890970",
                    "supervisorId": "SFC-543671",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SFC-635298",
                        "inspectionDate": "2024-08-22",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-1",
                    "date": "2024-07-18",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Electrical",
                    "mileageAtService": 4829,
                    "hoursAtService": 452,
                    "technicianId": "SSG-489070",
                    "supervisorId": "SSG-439044",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-937393",
                        "inspectionDate": "2024-07-18",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-2",
                    "date": "2024-07-01",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Transmission",
                    "mileageAtService": 4476,
                    "hoursAtService": 419,
                    "technicianId": "SPC-505158",
                    "supervisorId": "SSG-823452",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SFC-890050",
                        "inspectionDate": "2024-07-01",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-6",
                    "date": "2024-03-07",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Engine",
                    "mileageAtService": 3039,
                    "hoursAtService": 207,
                    "technicianId": "SPC-579867",
                    "supervisorId": "SFC-958422",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SFC-371583",
                        "inspectionDate": "2024-03-07",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                }
            ],
            "modifications": [],
            "recurringServices": [
                {
                    "serviceType": "Quarterly Service",
                    "lastPerformed": "2024-11-23",
                    "nextDue": "2025-02-21",
                    "interval": "90 days"
                },
                {
                    "serviceType": "Annual Service",
                    "lastPerformed": "2024-11-23",
                    "nextDue": "2025-11-23",
                    "interval": "365 days"
                }
            ]
        }
    },
    {
        "id": "1-77-A-301",
        "bumperNumber": "1-77-A-301",
        "registrationNumber": "3rd BCT1-77030302",
        "commandId": "3PLT-A-1-77-3rd BCT",
        "unitAssignment": {
            "platoon": "3rd Platoon",
            "company": "A",
            "battalion": "1-77",
            "brigade": "3rd BCT",
            "division": "1st Armored"
        },
        "platform": "M2A4 Bradley",
        "platformCategory": "IFV",
        "platformType": "Combat Vehicle",
        "status": {
            "isDeadlined": false,
            "operationalStatus": "PMC",
            "maintenanceLevel": "10/20",
            "lastMaintenanceDate": "2024-11-10",
            "nextScheduledMaintenance": "2025-02-08",
            "fuelLevel": 63
        },
        "maintenanceHistory": {
            "mileage": 2498,
            "engineHours": 292,
            "records": [
                {
                    "workOrderNumber": "WO-2024-2",
                    "date": "2024-11-10",
                    "type": "Corrective",
                    "level": "10/20",
                    "subsystem": "Engine",
                    "mileageAtService": 2056,
                    "hoursAtService": 266,
                    "technicianId": "SPC-184229",
                    "supervisorId": "SSG-420587",
                    "faults": [
                        {
                            "faultNumber": "F-2024-2",
                            "description": "Ramp actuator malfunction",
                            "status": "In Progress",
                            "priority": "Medium",
                            "systemAffected": "Drive Train",
                            "eic": "736",
                            "partsUsed": [
                                {
                                    "nsn": "1005-01-445-6098",
                                    "partName": "25mm Barrel",
                                    "quantity": 1,
                                    "unitPrice": 3200
                                }
                            ],
                            "laborHours": 2,
                            "correctiveAction": "Replaced 25mm barrel and verified operation"
                        }
                    ],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": false,
                        "category": "Repair",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-567415",
                        "inspectionDate": "2024-11-10",
                        "passed": false,
                        "remarks": "Maintenance ongoing - awaiting completion of repairs"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-1",
                    "date": "2024-09-04",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Driveline",
                    "mileageAtService": 2498,
                    "hoursAtService": 292,
                    "technicianId": "SGT-438044",
                    "supervisorId": "SFC-663836",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SFC-984437",
                        "inspectionDate": "2024-09-04",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-4",
                    "date": "2024-07-24",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Transmission",
                    "mileageAtService": 1583,
                    "hoursAtService": 211,
                    "technicianId": "SGT-601703",
                    "supervisorId": "SFC-353516",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-847970",
                        "inspectionDate": "2024-07-24",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-5",
                    "date": "2024-06-19",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Electrical",
                    "mileageAtService": 1334,
                    "hoursAtService": 116,
                    "technicianId": "SPC-110901",
                    "supervisorId": "SFC-723225",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SFC-626606",
                        "inspectionDate": "2024-06-19",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-3",
                    "date": "2024-05-11",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Transmission",
                    "mileageAtService": 2342,
                    "hoursAtService": 198,
                    "technicianId": "SSG-38905",
                    "supervisorId": "SSG-907552",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SFC-388362",
                        "inspectionDate": "2024-05-11",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                }
            ],
            "modifications": [],
            "recurringServices": [
                {
                    "serviceType": "Quarterly Service",
                    "lastPerformed": "2024-11-10",
                    "nextDue": "2025-02-08",
                    "interval": "90 days"
                },
                {
                    "serviceType": "Annual Service",
                    "lastPerformed": "2024-11-10",
                    "nextDue": "2025-11-10",
                    "interval": "365 days"
                }
            ]
        }
    },
    {
        "id": "1-77-C-203",
        "bumperNumber": "1-77-C-203",
        "registrationNumber": "3rd BCT1-77031204",
        "commandId": "2PLT-C-1-77-3rd BCT",
        "unitAssignment": {
            "platoon": "2nd Platoon",
            "company": "C",
            "battalion": "1-77",
            "brigade": "3rd BCT",
            "division": "1st Armored"
        },
        "platform": "M2A4 Bradley",
        "platformCategory": "IFV",
        "platformType": "Combat Vehicle",
        "status": {
            "isDeadlined": true,
            "operationalStatus": "NMCM",
            "maintenanceLevel": "30",
            "lastMaintenanceDate": "2024-11-12",
            "nextScheduledMaintenance": "2025-02-10",
            "fuelLevel": 69
        },
        "maintenanceHistory": {
            "mileage": 2954,
            "engineHours": 260,
            "records": [
                {
                    "workOrderNumber": "WO-2024-2",
                    "date": "2024-11-12",
                    "type": "Corrective",
                    "level": "30",
                    "subsystem": "Electrical",
                    "mileageAtService": 2468,
                    "hoursAtService": 254,
                    "technicianId": "SGT-738166",
                    "supervisorId": "SFC-188984",
                    "faults": [
                        {
                            "faultNumber": "F-2024-2",
                            "description": "Power distribution fault",
                            "status": "In Progress",
                            "priority": "High",
                            "systemAffected": "Engine",
                            "eic": "279",
                            "partsUsed": [
                                {
                                    "nsn": "1005-01-445-6098",
                                    "partName": "25mm Barrel",
                                    "quantity": 1,
                                    "unitPrice": 3200
                                }
                            ],
                            "laborHours": 5,
                            "correctiveAction": "Replaced 25mm barrel and verified operation"
                        }
                    ],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": false,
                        "category": "Repair",
                        "deadlineStatus": true
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-838037",
                        "inspectionDate": "2024-11-12",
                        "passed": false,
                        "remarks": "Maintenance ongoing - awaiting completion of repairs"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-1",
                    "date": "2024-09-13",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Transmission",
                    "mileageAtService": 2954,
                    "hoursAtService": 260,
                    "technicianId": "SGT-774480",
                    "supervisorId": "SSG-640014",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-449209",
                        "inspectionDate": "2024-09-13",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-3",
                    "date": "2024-03-12",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Brakes",
                    "mileageAtService": 2456,
                    "hoursAtService": 214,
                    "technicianId": "SSG-655994",
                    "supervisorId": "SFC-884418",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-604344",
                        "inspectionDate": "2024-03-12",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                }
            ],
            "modifications": [],
            "recurringServices": [
                {
                    "serviceType": "Quarterly Service",
                    "lastPerformed": "2024-11-12",
                    "nextDue": "2025-02-10",
                    "interval": "90 days"
                },
                {
                    "serviceType": "Annual Service",
                    "lastPerformed": "2024-11-12",
                    "nextDue": "2025-11-12",
                    "interval": "365 days"
                }
            ]
        }
    },
    {
        "id": "1-77-HHC-32008",
        "bumperNumber": "1-77-HHC-32008",
        "registrationNumber": "3rd BCT1-77032008",
        "commandId": "Medical-HHC-1-77-3rd BCT",
        "unitAssignment": {
            "platoon": "Medical Platoon",
            "company": "HHC",
            "battalion": "1-77",
            "brigade": "3rd BCT",
            "division": "1st Armored"
        },
        "platform": "AMPV Medical Evacuation",
        "platformCategory": "Support Vehicle",
        "platformType": "Support Vehicle",
        "status": {
            "isDeadlined": false,
            "operationalStatus": "PMC",
            "maintenanceLevel": "10/20",
            "lastMaintenanceDate": "2024-11-19",
            "nextScheduledMaintenance": "2025-02-17",
            "fuelLevel": 77
        },
        "maintenanceHistory": {
            "mileage": 3866,
            "engineHours": 382,
            "records": [
                {
                    "workOrderNumber": "WO-2024-1",
                    "date": "2024-11-19",
                    "type": "Corrective",
                    "level": "10/20",
                    "subsystem": "Transmission",
                    "mileageAtService": 3866,
                    "hoursAtService": 382,
                    "technicianId": "SGT-534836",
                    "supervisorId": "SSG-769202",
                    "faults": [
                        {
                            "faultNumber": "F-2024-1",
                            "description": "Transmission slipping",
                            "status": "In Progress",
                            "priority": "Medium",
                            "systemAffected": "Suspension",
                            "eic": "648",
                            "partsUsed": [
                                {
                                    "nsn": "2930-01-890-1234",
                                    "partName": "Cooling System",
                                    "quantity": 1,
                                    "unitPrice": 2000
                                }
                            ],
                            "laborHours": 6,
                            "correctiveAction": "Replaced cooling system and verified operation"
                        }
                    ],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": false,
                        "category": "Repair",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-872087",
                        "inspectionDate": "2024-11-19",
                        "passed": false,
                        "remarks": "Maintenance ongoing - awaiting completion of repairs"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-3",
                    "date": "2024-11-16",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Brakes",
                    "mileageAtService": 3390,
                    "hoursAtService": 334,
                    "technicianId": "SPC-609909",
                    "supervisorId": "SSG-146945",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-22127",
                        "inspectionDate": "2024-11-16",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-4",
                    "date": "2024-07-18",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Driveline",
                    "mileageAtService": 3296,
                    "hoursAtService": 271,
                    "technicianId": "SPC-299322",
                    "supervisorId": "SFC-862419",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-69416",
                        "inspectionDate": "2024-07-18",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-2",
                    "date": "2024-02-14",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Driveline",
                    "mileageAtService": 3430,
                    "hoursAtService": 337,
                    "technicianId": "SSG-738650",
                    "supervisorId": "SSG-953082",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SFC-169750",
                        "inspectionDate": "2024-02-14",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2023-5",
                    "date": "2023-12-23",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Engine",
                    "mileageAtService": 2890,
                    "hoursAtService": 282,
                    "technicianId": "SSG-118346",
                    "supervisorId": "SFC-54713",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SFC-799091",
                        "inspectionDate": "2023-12-23",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                }
            ],
            "modifications": [],
            "recurringServices": [
                {
                    "serviceType": "Quarterly Service",
                    "lastPerformed": "2024-11-19",
                    "nextDue": "2025-02-17",
                    "interval": "90 days"
                },
                {
                    "serviceType": "Annual Service",
                    "lastPerformed": "2024-11-19",
                    "nextDue": "2025-11-19",
                    "interval": "365 days"
                }
            ]
        }
    },
    {
        "id": "1-77-HHC-32011",
        "bumperNumber": "1-77-HHC-32011",
        "registrationNumber": "3rd BCT1-77032011",
        "commandId": "Medical-HHC-1-77-3rd BCT",
        "unitAssignment": {
            "platoon": "Medical Platoon",
            "company": "HHC",
            "battalion": "1-77",
            "brigade": "3rd BCT",
            "division": "1st Armored"
        },
        "platform": "AMPV Medical Evacuation",
        "platformCategory": "Support Vehicle",
        "platformType": "Support Vehicle",
        "status": {
            "isDeadlined": true,
            "operationalStatus": "NMCM",
            "maintenanceLevel": "30",
            "lastMaintenanceDate": "2024-11-28",
            "nextScheduledMaintenance": "2025-02-26",
            "fuelLevel": 75
        },
        "maintenanceHistory": {
            "mileage": 5372,
            "engineHours": 465,
            "records": [
                {
                    "workOrderNumber": "WO-2024-3",
                    "date": "2024-11-28",
                    "type": "Corrective",
                    "level": "30",
                    "subsystem": "Electrical",
                    "mileageAtService": 4736,
                    "hoursAtService": 371,
                    "technicianId": "SPC-874820",
                    "supervisorId": "SFC-106012",
                    "faults": [
                        {
                            "faultNumber": "F-2024-3",
                            "description": "Electrical system short",
                            "status": "Open",
                            "priority": "High",
                            "systemAffected": "Engine",
                            "eic": "085",
                            "partsUsed": [
                                {
                                    "nsn": "2520-01-901-2345",
                                    "partName": "Transmission Kit",
                                    "quantity": 1,
                                    "unitPrice": 3500
                                }
                            ],
                            "laborHours": 2,
                            "correctiveAction": "Replaced transmission kit and verified operation"
                        }
                    ],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": false,
                        "category": "Repair",
                        "deadlineStatus": true
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-532660",
                        "inspectionDate": "2024-11-28",
                        "passed": false,
                        "remarks": "Maintenance ongoing - awaiting completion of repairs"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-4",
                    "date": "2024-11-16",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Transmission",
                    "mileageAtService": 4883,
                    "hoursAtService": 336,
                    "technicianId": "SGT-826482",
                    "supervisorId": "SFC-516109",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-956375",
                        "inspectionDate": "2024-11-16",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-5",
                    "date": "2024-06-30",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Engine",
                    "mileageAtService": 5040,
                    "hoursAtService": 397,
                    "technicianId": "SSG-323508",
                    "supervisorId": "SFC-113826",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-335019",
                        "inspectionDate": "2024-06-30",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-1",
                    "date": "2024-03-24",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Electrical",
                    "mileageAtService": 5372,
                    "hoursAtService": 465,
                    "technicianId": "SPC-783000",
                    "supervisorId": "SSG-638105",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-696613",
                        "inspectionDate": "2024-03-24",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-2",
                    "date": "2024-01-12",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Electrical",
                    "mileageAtService": 5222,
                    "hoursAtService": 447,
                    "technicianId": "SSG-59132",
                    "supervisorId": "SSG-454896",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SFC-930299",
                        "inspectionDate": "2024-01-12",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                }
            ],
            "modifications": [],
            "recurringServices": [
                {
                    "serviceType": "Quarterly Service",
                    "lastPerformed": "2024-11-28",
                    "nextDue": "2025-02-26",
                    "interval": "90 days"
                },
                {
                    "serviceType": "Annual Service",
                    "lastPerformed": "2024-11-28",
                    "nextDue": "2025-11-28",
                    "interval": "365 days"
                }
            ]
        }
    },
    {
        "id": "1-77-HHC-32023",
        "bumperNumber": "1-77-HHC-32023",
        "registrationNumber": "3rd BCT1-77032023",
        "commandId": "Maintenance-HHC-1-77-3rd BCT",
        "unitAssignment": {
            "platoon": "Maintenance Platoon",
            "company": "HHC",
            "battalion": "1-77",
            "brigade": "3rd BCT",
            "division": "1st Armored"
        },
        "platform": "M977 HEMTT Maintenance",
        "platformCategory": "Support Vehicle",
        "platformType": "Support Vehicle",
        "status": {
            "isDeadlined": false,
            "operationalStatus": "PMC",
            "maintenanceLevel": "10/20",
            "lastMaintenanceDate": "2024-12-05",
            "nextScheduledMaintenance": "2025-03-05",
            "fuelLevel": 65
        },
        "maintenanceHistory": {
            "mileage": 7991,
            "engineHours": 727,
            "records": [
                {
                    "workOrderNumber": "WO-2024-3",
                    "date": "2024-12-05",
                    "type": "Corrective",
                    "level": "10/20",
                    "subsystem": "Electrical",
                    "mileageAtService": 7691,
                    "hoursAtService": 649,
                    "technicianId": "SGT-658948",
                    "supervisorId": "SFC-650519",
                    "faults": [
                        {
                            "faultNumber": "F-2024-3",
                            "description": "Electrical system short",
                            "status": "In Progress",
                            "priority": "Medium",
                            "systemAffected": "Engine",
                            "eic": "221",
                            "partsUsed": [
                                {
                                    "nsn": "2530-01-789-0123",
                                    "partName": "Brake Assembly",
                                    "quantity": 1,
                                    "unitPrice": 1500
                                }
                            ],
                            "laborHours": 4,
                            "correctiveAction": "Replaced brake assembly and verified operation"
                        }
                    ],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": false,
                        "category": "Repair",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-30821",
                        "inspectionDate": "2024-12-05",
                        "passed": false,
                        "remarks": "Maintenance ongoing - awaiting completion of repairs"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-5",
                    "date": "2024-07-12",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Transmission",
                    "mileageAtService": 6007,
                    "hoursAtService": 531,
                    "technicianId": "SGT-686829",
                    "supervisorId": "SFC-756181",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SFC-586156",
                        "inspectionDate": "2024-07-12",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-6",
                    "date": "2024-04-13",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Transmission",
                    "mileageAtService": 5616,
                    "hoursAtService": 622,
                    "technicianId": "SPC-751047",
                    "supervisorId": "SSG-128702",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SFC-120132",
                        "inspectionDate": "2024-04-13",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-4",
                    "date": "2024-03-04",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Brakes",
                    "mileageAtService": 7553,
                    "hoursAtService": 604,
                    "technicianId": "SPC-841381",
                    "supervisorId": "SFC-599686",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SFC-697296",
                        "inspectionDate": "2024-03-04",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-2",
                    "date": "2024-02-23",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Transmission",
                    "mileageAtService": 7792,
                    "hoursAtService": 726,
                    "technicianId": "SSG-516196",
                    "supervisorId": "SSG-52961",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-693206",
                        "inspectionDate": "2024-02-23",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-1",
                    "date": "2024-01-22",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Engine",
                    "mileageAtService": 7991,
                    "hoursAtService": 727,
                    "technicianId": "SGT-99443",
                    "supervisorId": "SSG-147658",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SFC-806257",
                        "inspectionDate": "2024-01-22",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                }
            ],
            "modifications": [],
            "recurringServices": [
                {
                    "serviceType": "Quarterly Service",
                    "lastPerformed": "2024-12-05",
                    "nextDue": "2025-03-05",
                    "interval": "90 days"
                },
                {
                    "serviceType": "Annual Service",
                    "lastPerformed": "2024-12-05",
                    "nextDue": "2025-12-05",
                    "interval": "365 days"
                }
            ]
        }
    },
    {
        "id": "2-77-A-104",
        "bumperNumber": "2-77-A-104",
        "registrationNumber": "3rd BCT2-77040105",
        "commandId": "1PLT-A-2-77-3rd BCT",
        "unitAssignment": {
            "platoon": "1st Platoon",
            "company": "A",
            "battalion": "2-77",
            "brigade": "3rd BCT",
            "division": "1st Armored"
        },
        "platform": "M2A4 Bradley",
        "platformCategory": "IFV",
        "platformType": "Combat Vehicle",
        "status": {
            "isDeadlined": false,
            "operationalStatus": "PMC",
            "maintenanceLevel": "10/20",
            "lastMaintenanceDate": "2024-12-08",
            "nextScheduledMaintenance": "2025-03-08",
            "fuelLevel": 60
        },
        "maintenanceHistory": {
            "mileage": 5809,
            "engineHours": 596,
            "records": [
                {
                    "workOrderNumber": "WO-2024-2",
                    "date": "2024-12-08",
                    "type": "Corrective",
                    "level": "10/20",
                    "subsystem": "Driveline",
                    "mileageAtService": 5367,
                    "hoursAtService": 588,
                    "technicianId": "SPC-853128",
                    "supervisorId": "SSG-465140",
                    "faults": [
                        {
                            "faultNumber": "F-2024-2",
                            "description": "Track tension issue",
                            "status": "Open",
                            "priority": "Medium",
                            "systemAffected": "Infantry Compartment",
                            "eic": "560",
                            "partsUsed": [
                                {
                                    "nsn": "1005-01-445-6098",
                                    "partName": "25mm Barrel",
                                    "quantity": 1,
                                    "unitPrice": 3200
                                }
                            ],
                            "laborHours": 6,
                            "correctiveAction": "Replaced 25mm barrel and verified operation"
                        }
                    ],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": false,
                        "category": "Repair",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SFC-376231",
                        "inspectionDate": "2024-12-08",
                        "passed": false,
                        "remarks": "Maintenance ongoing - awaiting completion of repairs"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-3",
                    "date": "2024-05-13",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Brakes",
                    "mileageAtService": 4837,
                    "hoursAtService": 578,
                    "technicianId": "SPC-570739",
                    "supervisorId": "SSG-561006",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-691478",
                        "inspectionDate": "2024-05-13",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-4",
                    "date": "2024-02-27",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Brakes",
                    "mileageAtService": 5737,
                    "hoursAtService": 575,
                    "technicianId": "SPC-660453",
                    "supervisorId": "SSG-725515",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SFC-811190",
                        "inspectionDate": "2024-02-27",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2023-1",
                    "date": "2023-12-27",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Driveline",
                    "mileageAtService": 5809,
                    "hoursAtService": 596,
                    "technicianId": "SGT-370799",
                    "supervisorId": "SSG-422541",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SFC-551899",
                        "inspectionDate": "2023-12-27",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                }
            ],
            "modifications": [],
            "recurringServices": [
                {
                    "serviceType": "Quarterly Service",
                    "lastPerformed": "2024-12-08",
                    "nextDue": "2025-03-08",
                    "interval": "90 days"
                },
                {
                    "serviceType": "Annual Service",
                    "lastPerformed": "2024-12-08",
                    "nextDue": "2025-12-08",
                    "interval": "365 days"
                }
            ]
        }
    },
    {
        "id": "2-77-B-102",
        "bumperNumber": "2-77-B-102",
        "registrationNumber": "3rd BCT2-77040603",
        "commandId": "1PLT-B-2-77-3rd BCT",
        "unitAssignment": {
            "platoon": "1st Platoon",
            "company": "B",
            "battalion": "2-77",
            "brigade": "3rd BCT",
            "division": "1st Armored"
        },
        "platform": "M2A4 Bradley",
        "platformCategory": "IFV",
        "platformType": "Combat Vehicle",
        "status": {
            "isDeadlined": false,
            "operationalStatus": "PMC",
            "maintenanceLevel": "10/20",
            "lastMaintenanceDate": "2024-11-12",
            "nextScheduledMaintenance": "2025-02-10",
            "fuelLevel": 93
        },
        "maintenanceHistory": {
            "mileage": 2853,
            "engineHours": 241,
            "records": [
                {
                    "workOrderNumber": "WO-2024-2",
                    "date": "2024-11-12",
                    "type": "Corrective",
                    "level": "10/20",
                    "subsystem": "Driveline",
                    "mileageAtService": 2436,
                    "hoursAtService": 211,
                    "technicianId": "SSG-229188",
                    "supervisorId": "SFC-49790",
                    "faults": [
                        {
                            "faultNumber": "F-2024-2",
                            "description": "Ramp actuator malfunction",
                            "status": "In Progress",
                            "priority": "Medium",
                            "systemAffected": "NBC System",
                            "eic": "845",
                            "partsUsed": [
                                {
                                    "nsn": "2530-01-603-2462",
                                    "partName": "Track Assembly",
                                    "quantity": 1,
                                    "unitPrice": 1500
                                }
                            ],
                            "laborHours": 3,
                            "correctiveAction": "Replaced track assembly and verified operation"
                        }
                    ],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": false,
                        "category": "Repair",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-432088",
                        "inspectionDate": "2024-11-12",
                        "passed": false,
                        "remarks": "Maintenance ongoing - awaiting completion of repairs"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-4",
                    "date": "2024-09-22",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Electrical",
                    "mileageAtService": 1722,
                    "hoursAtService": 130,
                    "technicianId": "SPC-327485",
                    "supervisorId": "SFC-190791",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SFC-13857",
                        "inspectionDate": "2024-09-22",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-3",
                    "date": "2024-07-05",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Brakes",
                    "mileageAtService": 1865,
                    "hoursAtService": 187,
                    "technicianId": "SSG-3512",
                    "supervisorId": "SSG-708390",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SFC-526641",
                        "inspectionDate": "2024-07-05",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-1",
                    "date": "2024-01-20",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Electrical",
                    "mileageAtService": 2853,
                    "hoursAtService": 241,
                    "technicianId": "SPC-372193",
                    "supervisorId": "SSG-138197",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-454703",
                        "inspectionDate": "2024-01-20",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                }
            ],
            "modifications": [],
            "recurringServices": [
                {
                    "serviceType": "Quarterly Service",
                    "lastPerformed": "2024-11-12",
                    "nextDue": "2025-02-10",
                    "interval": "90 days"
                },
                {
                    "serviceType": "Annual Service",
                    "lastPerformed": "2024-11-12",
                    "nextDue": "2025-11-12",
                    "interval": "365 days"
                }
            ]
        }
    },
    {
        "id": "2-77-B-204",
        "bumperNumber": "2-77-B-204",
        "registrationNumber": "3rd BCT2-77040705",
        "commandId": "2PLT-B-2-77-3rd BCT",
        "unitAssignment": {
            "platoon": "2nd Platoon",
            "company": "B",
            "battalion": "2-77",
            "brigade": "3rd BCT",
            "division": "1st Armored"
        },
        "platform": "M2A4 Bradley",
        "platformCategory": "IFV",
        "platformType": "Combat Vehicle",
        "status": {
            "isDeadlined": false,
            "operationalStatus": "PMC",
            "maintenanceLevel": "10/20",
            "lastMaintenanceDate": "2024-11-25",
            "nextScheduledMaintenance": "2025-02-23",
            "fuelLevel": 74
        },
        "maintenanceHistory": {
            "mileage": 3180,
            "engineHours": 293,
            "records": [
                {
                    "workOrderNumber": "WO-2024-6",
                    "date": "2024-11-25",
                    "type": "Corrective",
                    "level": "10/20",
                    "subsystem": "Engine",
                    "mileageAtService": 1015,
                    "hoursAtService": 88,
                    "technicianId": "SSG-645209",
                    "supervisorId": "SSG-127810",
                    "faults": [
                        {
                            "faultNumber": "F-2024-6",
                            "description": "25mm feed system jam",
                            "status": "In Progress",
                            "priority": "Medium",
                            "systemAffected": "Weapons System",
                            "eic": "943",
                            "partsUsed": [
                                {
                                    "nsn": "2530-01-603-2462",
                                    "partName": "Ramp Actuator Assembly",
                                    "quantity": 1,
                                    "unitPrice": 1800
                                }
                            ],
                            "laborHours": 2,
                            "correctiveAction": "Replaced ramp actuator assembly and verified operation"
                        }
                    ],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": false,
                        "category": "Repair",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-535438",
                        "inspectionDate": "2024-11-25",
                        "passed": false,
                        "remarks": "Maintenance ongoing - awaiting completion of repairs"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-1",
                    "date": "2024-08-25",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Electrical",
                    "mileageAtService": 3180,
                    "hoursAtService": 293,
                    "technicianId": "SSG-19089",
                    "supervisorId": "SSG-960321",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SFC-951227",
                        "inspectionDate": "2024-08-25",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-4",
                    "date": "2024-07-25",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Engine",
                    "mileageAtService": 3147,
                    "hoursAtService": 167,
                    "technicianId": "SSG-288919",
                    "supervisorId": "SFC-349059",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-473265",
                        "inspectionDate": "2024-07-25",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-3",
                    "date": "2024-06-15",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Driveline",
                    "mileageAtService": 2918,
                    "hoursAtService": 263,
                    "technicianId": "SGT-154090",
                    "supervisorId": "SFC-517743",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SFC-484918",
                        "inspectionDate": "2024-06-15",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-5",
                    "date": "2024-06-01",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Transmission",
                    "mileageAtService": 1792,
                    "hoursAtService": 189,
                    "technicianId": "SGT-503539",
                    "supervisorId": "SFC-237468",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-466211",
                        "inspectionDate": "2024-06-01",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-2",
                    "date": "2024-03-08",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Electrical",
                    "mileageAtService": 2971,
                    "hoursAtService": 283,
                    "technicianId": "SGT-110908",
                    "supervisorId": "SFC-190022",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-528217",
                        "inspectionDate": "2024-03-08",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                }
            ],
            "modifications": [],
            "recurringServices": [
                {
                    "serviceType": "Quarterly Service",
                    "lastPerformed": "2024-11-25",
                    "nextDue": "2025-02-23",
                    "interval": "90 days"
                },
                {
                    "serviceType": "Annual Service",
                    "lastPerformed": "2024-11-25",
                    "nextDue": "2025-11-25",
                    "interval": "365 days"
                }
            ]
        }
    },
    {
        "id": "2-77-C-103",
        "bumperNumber": "2-77-C-103",
        "registrationNumber": "3rd BCT2-77041104",
        "commandId": "1PLT-C-2-77-3rd BCT",
        "unitAssignment": {
            "platoon": "1st Platoon",
            "company": "C",
            "battalion": "2-77",
            "brigade": "3rd BCT",
            "division": "1st Armored"
        },
        "platform": "M2A4 Bradley",
        "platformCategory": "IFV",
        "platformType": "Combat Vehicle",
        "status": {
            "isDeadlined": true,
            "operationalStatus": "NMCM",
            "maintenanceLevel": "30",
            "lastMaintenanceDate": "2024-11-17",
            "nextScheduledMaintenance": "2025-02-15",
            "fuelLevel": 66
        },
        "maintenanceHistory": {
            "mileage": 3517,
            "engineHours": 397,
            "records": [
                {
                    "workOrderNumber": "WO-2024-5",
                    "date": "2024-11-17",
                    "type": "Corrective",
                    "level": "30",
                    "subsystem": "Driveline",
                    "mileageAtService": 2905,
                    "hoursAtService": 269,
                    "technicianId": "SSG-302907",
                    "supervisorId": "SSG-464632",
                    "faults": [
                        {
                            "faultNumber": "F-2024-5",
                            "description": "Ramp actuator malfunction",
                            "status": "Open",
                            "priority": "High",
                            "systemAffected": "Infantry Compartment",
                            "eic": "070",
                            "partsUsed": [
                                {
                                    "nsn": "2530-01-603-2462",
                                    "partName": "Ramp Actuator Assembly",
                                    "quantity": 1,
                                    "unitPrice": 1800
                                }
                            ],
                            "laborHours": 4,
                            "correctiveAction": "Replaced ramp actuator assembly and verified operation"
                        }
                    ],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": false,
                        "category": "Repair",
                        "deadlineStatus": true
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-426422",
                        "inspectionDate": "2024-11-17",
                        "passed": false,
                        "remarks": "Maintenance ongoing - awaiting completion of repairs"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-1",
                    "date": "2024-10-19",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Transmission",
                    "mileageAtService": 3517,
                    "hoursAtService": 397,
                    "technicianId": "SPC-818930",
                    "supervisorId": "SFC-175121",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-381378",
                        "inspectionDate": "2024-10-19",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-4",
                    "date": "2024-07-18",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Engine",
                    "mileageAtService": 2392,
                    "hoursAtService": 394,
                    "technicianId": "SGT-510617",
                    "supervisorId": "SFC-134435",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-698095",
                        "inspectionDate": "2024-07-18",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-3",
                    "date": "2024-05-19",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Driveline",
                    "mileageAtService": 2561,
                    "hoursAtService": 343,
                    "technicianId": "SGT-688703",
                    "supervisorId": "SSG-153145",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SFC-942032",
                        "inspectionDate": "2024-05-19",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-2",
                    "date": "2024-02-09",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Driveline",
                    "mileageAtService": 3027,
                    "hoursAtService": 387,
                    "technicianId": "SGT-185966",
                    "supervisorId": "SFC-541427",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-555726",
                        "inspectionDate": "2024-02-09",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2023-6",
                    "date": "2023-12-23",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Transmission",
                    "mileageAtService": 1127,
                    "hoursAtService": 392,
                    "technicianId": "SSG-442438",
                    "supervisorId": "SFC-586083",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SFC-268530",
                        "inspectionDate": "2023-12-23",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                }
            ],
            "modifications": [],
            "recurringServices": [
                {
                    "serviceType": "Quarterly Service",
                    "lastPerformed": "2024-11-17",
                    "nextDue": "2025-02-15",
                    "interval": "90 days"
                },
                {
                    "serviceType": "Annual Service",
                    "lastPerformed": "2024-11-17",
                    "nextDue": "2025-11-17",
                    "interval": "365 days"
                }
            ]
        }
    },
    {
        "id": "2-77-C-104",
        "bumperNumber": "2-77-C-104",
        "registrationNumber": "3rd BCT2-77041105",
        "commandId": "1PLT-C-2-77-3rd BCT",
        "unitAssignment": {
            "platoon": "1st Platoon",
            "company": "C",
            "battalion": "2-77",
            "brigade": "3rd BCT",
            "division": "1st Armored"
        },
        "platform": "M2A4 Bradley",
        "platformCategory": "IFV",
        "platformType": "Combat Vehicle",
        "status": {
            "isDeadlined": false,
            "operationalStatus": "PMC",
            "maintenanceLevel": "10/20",
            "lastMaintenanceDate": "2024-11-14",
            "nextScheduledMaintenance": "2025-02-12",
            "fuelLevel": 93
        },
        "maintenanceHistory": {
            "mileage": 7851,
            "engineHours": 826,
            "records": [
                {
                    "workOrderNumber": "WO-2024-1",
                    "date": "2024-11-14",
                    "type": "Corrective",
                    "level": "10/20",
                    "subsystem": "Transmission",
                    "mileageAtService": 7851,
                    "hoursAtService": 826,
                    "technicianId": "SSG-979619",
                    "supervisorId": "SFC-472528",
                    "faults": [
                        {
                            "faultNumber": "F-2024-1",
                            "description": "Ramp actuator malfunction",
                            "status": "Open",
                            "priority": "Medium",
                            "systemAffected": "Transmission",
                            "eic": "364",
                            "partsUsed": [
                                {
                                    "nsn": "2530-01-603-2462",
                                    "partName": "Track Assembly",
                                    "quantity": 1,
                                    "unitPrice": 1500
                                }
                            ],
                            "laborHours": 3,
                            "correctiveAction": "Replaced track assembly and verified operation"
                        }
                    ],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": false,
                        "category": "Repair",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-620145",
                        "inspectionDate": "2024-11-14",
                        "passed": false,
                        "remarks": "Maintenance ongoing - awaiting completion of repairs"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-2",
                    "date": "2024-11-14",
                    "type": "Corrective",
                    "level": "10/20",
                    "subsystem": "Transmission",
                    "mileageAtService": 7633,
                    "hoursAtService": 823,
                    "technicianId": "SSG-352037",
                    "supervisorId": "SSG-550877",
                    "faults": [
                        {
                            "faultNumber": "F-2024-2",
                            "description": "25mm feed system jam",
                            "status": "In Progress",
                            "priority": "Medium",
                            "systemAffected": "Weapons System",
                            "eic": "455",
                            "partsUsed": [
                                {
                                    "nsn": "2530-01-603-2462",
                                    "partName": "Ramp Actuator Assembly",
                                    "quantity": 1,
                                    "unitPrice": 1800
                                }
                            ],
                            "laborHours": 7,
                            "correctiveAction": "Replaced ramp actuator assembly and verified operation"
                        }
                    ],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": false,
                        "category": "Repair",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-64521",
                        "inspectionDate": "2024-11-14",
                        "passed": false,
                        "remarks": "Maintenance ongoing - awaiting completion of repairs"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-4",
                    "date": "2024-08-30",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Brakes",
                    "mileageAtService": 7089,
                    "hoursAtService": 721,
                    "technicianId": "SPC-320516",
                    "supervisorId": "SFC-924358",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-111169",
                        "inspectionDate": "2024-08-30",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-3",
                    "date": "2024-08-15",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Transmission",
                    "mileageAtService": 7605,
                    "hoursAtService": 740,
                    "technicianId": "SSG-134991",
                    "supervisorId": "SSG-41279",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SFC-282555",
                        "inspectionDate": "2024-08-15",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                }
            ],
            "modifications": [],
            "recurringServices": [
                {
                    "serviceType": "Quarterly Service",
                    "lastPerformed": "2024-11-14",
                    "nextDue": "2025-02-12",
                    "interval": "90 days"
                },
                {
                    "serviceType": "Annual Service",
                    "lastPerformed": "2024-11-14",
                    "nextDue": "2025-11-14",
                    "interval": "365 days"
                }
            ]
        }
    },
    {
        "id": "2-77-HHC-42021",
        "bumperNumber": "2-77-HHC-42021",
        "registrationNumber": "3rd BCT2-77042021",
        "commandId": "Maintenance-HHC-2-77-3rd BCT",
        "unitAssignment": {
            "platoon": "Maintenance Platoon",
            "company": "HHC",
            "battalion": "2-77",
            "brigade": "3rd BCT",
            "division": "1st Armored"
        },
        "platform": "M984 HEMTT Wrecker",
        "platformCategory": "Recovery Vehicle",
        "platformType": "Support Vehicle",
        "status": {
            "isDeadlined": true,
            "operationalStatus": "NMCM",
            "maintenanceLevel": "30",
            "lastMaintenanceDate": "2024-12-04",
            "nextScheduledMaintenance": "2025-03-04",
            "fuelLevel": 81
        },
        "maintenanceHistory": {
            "mileage": 1120,
            "engineHours": 106,
            "records": [
                {
                    "workOrderNumber": "WO-2024-1",
                    "date": "2024-12-04",
                    "type": "Corrective",
                    "level": "30",
                    "subsystem": "Engine",
                    "mileageAtService": 1120,
                    "hoursAtService": 106,
                    "technicianId": "SSG-691838",
                    "supervisorId": "SSG-951139",
                    "faults": [
                        {
                            "faultNumber": "F-2024-1",
                            "description": "Outrigger system fault",
                            "status": "In Progress",
                            "priority": "High",
                            "systemAffected": "Winch System",
                            "eic": "389",
                            "partsUsed": [
                                {
                                    "nsn": "3950-01-012-3456",
                                    "partName": "Winch Assembly",
                                    "quantity": 1,
                                    "unitPrice": 5000
                                }
                            ],
                            "laborHours": 6,
                            "correctiveAction": "Replaced winch assembly and verified operation"
                        }
                    ],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": false,
                        "category": "Repair",
                        "deadlineStatus": true
                    },
                    "qualityControl": {
                        "inspectorId": "SFC-213026",
                        "inspectionDate": "2024-12-04",
                        "passed": false,
                        "remarks": "Maintenance ongoing - awaiting completion of repairs"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-4",
                    "date": "2024-10-07",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Engine",
                    "mileageAtService": 685,
                    "hoursAtService": 28,
                    "technicianId": "SSG-187973",
                    "supervisorId": "SFC-359444",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SFC-872011",
                        "inspectionDate": "2024-10-07",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-2",
                    "date": "2024-08-27",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Engine",
                    "mileageAtService": 658,
                    "hoursAtService": 97,
                    "technicianId": "SGT-246904",
                    "supervisorId": "SSG-854225",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SFC-625647",
                        "inspectionDate": "2024-08-27",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-3",
                    "date": "2024-06-26",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Driveline",
                    "mileageAtService": 292,
                    "hoursAtService": 80,
                    "technicianId": "SSG-434352",
                    "supervisorId": "SSG-502192",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-842740",
                        "inspectionDate": "2024-06-26",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                }
            ],
            "modifications": [],
            "recurringServices": [
                {
                    "serviceType": "Quarterly Service",
                    "lastPerformed": "2024-12-04",
                    "nextDue": "2025-03-04",
                    "interval": "90 days"
                },
                {
                    "serviceType": "Annual Service",
                    "lastPerformed": "2024-12-04",
                    "nextDue": "2025-12-04",
                    "interval": "365 days"
                }
            ]
        }
    },
    {
        "id": "3-77-A-103",
        "bumperNumber": "3-77-A-103",
        "registrationNumber": "3rd BCT3-77050104",
        "commandId": "1PLT-A-3-77-3rd BCT",
        "unitAssignment": {
            "platoon": "1st Platoon",
            "company": "A",
            "battalion": "3-77",
            "brigade": "3rd BCT",
            "division": "1st Armored"
        },
        "platform": "M2A4 Bradley",
        "platformCategory": "IFV",
        "platformType": "Combat Vehicle",
        "status": {
            "isDeadlined": false,
            "operationalStatus": "PMC",
            "maintenanceLevel": "10/20",
            "lastMaintenanceDate": "2024-11-12",
            "nextScheduledMaintenance": "2025-02-10",
            "fuelLevel": 85
        },
        "maintenanceHistory": {
            "mileage": 5067,
            "engineHours": 536,
            "records": [
                {
                    "workOrderNumber": "WO-2024-3",
                    "date": "2024-11-12",
                    "type": "Corrective",
                    "level": "10/20",
                    "subsystem": "Driveline",
                    "mileageAtService": 4819,
                    "hoursAtService": 532,
                    "technicianId": "SSG-557000",
                    "supervisorId": "SSG-137923",
                    "faults": [
                        {
                            "faultNumber": "F-2024-3",
                            "description": "25mm feed system jam",
                            "status": "Open",
                            "priority": "Medium",
                            "systemAffected": "25mm Chain Gun",
                            "eic": "655",
                            "partsUsed": [
                                {
                                    "nsn": "2530-01-603-2462",
                                    "partName": "Track Assembly",
                                    "quantity": 1,
                                    "unitPrice": 1500
                                }
                            ],
                            "laborHours": 2,
                            "correctiveAction": "Replaced track assembly and verified operation"
                        }
                    ],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": false,
                        "category": "Repair",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SFC-365122",
                        "inspectionDate": "2024-11-12",
                        "passed": false,
                        "remarks": "Maintenance ongoing - awaiting completion of repairs"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-1",
                    "date": "2024-09-17",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Engine",
                    "mileageAtService": 5067,
                    "hoursAtService": 536,
                    "technicianId": "SGT-12837",
                    "supervisorId": "SFC-645137",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SFC-565806",
                        "inspectionDate": "2024-09-17",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-2",
                    "date": "2024-06-13",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Driveline",
                    "mileageAtService": 4918,
                    "hoursAtService": 518,
                    "technicianId": "SGT-103193",
                    "supervisorId": "SSG-33836",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SFC-101262",
                        "inspectionDate": "2024-06-13",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-5",
                    "date": "2024-05-16",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Electrical",
                    "mileageAtService": 3931,
                    "hoursAtService": 376,
                    "technicianId": "SPC-634636",
                    "supervisorId": "SFC-669295",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SFC-349674",
                        "inspectionDate": "2024-05-16",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-4",
                    "date": "2024-05-05",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Driveline",
                    "mileageAtService": 3591,
                    "hoursAtService": 476,
                    "technicianId": "SGT-765407",
                    "supervisorId": "SSG-970164",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-681835",
                        "inspectionDate": "2024-05-05",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                }
            ],
            "modifications": [],
            "recurringServices": [
                {
                    "serviceType": "Quarterly Service",
                    "lastPerformed": "2024-11-12",
                    "nextDue": "2025-02-10",
                    "interval": "90 days"
                },
                {
                    "serviceType": "Annual Service",
                    "lastPerformed": "2024-11-12",
                    "nextDue": "2025-11-12",
                    "interval": "365 days"
                }
            ]
        }
    },
    {
        "id": "3-77-B-301",
        "bumperNumber": "3-77-B-301",
        "registrationNumber": "3rd BCT3-77050802",
        "commandId": "3PLT-B-3-77-3rd BCT",
        "unitAssignment": {
            "platoon": "3rd Platoon",
            "company": "B",
            "battalion": "3-77",
            "brigade": "3rd BCT",
            "division": "1st Armored"
        },
        "platform": "M2A4 Bradley",
        "platformCategory": "IFV",
        "platformType": "Combat Vehicle",
        "status": {
            "isDeadlined": false,
            "operationalStatus": "PMC",
            "maintenanceLevel": "10/20",
            "lastMaintenanceDate": "2024-12-04",
            "nextScheduledMaintenance": "2025-03-04",
            "fuelLevel": 91
        },
        "maintenanceHistory": {
            "mileage": 2470,
            "engineHours": 252,
            "records": [
                {
                    "workOrderNumber": "WO-2024-1",
                    "date": "2024-12-04",
                    "type": "Corrective",
                    "level": "10/20",
                    "subsystem": "Brakes",
                    "mileageAtService": 2470,
                    "hoursAtService": 252,
                    "technicianId": "SGT-73162",
                    "supervisorId": "SFC-891434",
                    "faults": [
                        {
                            "faultNumber": "F-2024-1",
                            "description": "25mm feed system jam",
                            "status": "In Progress",
                            "priority": "Medium",
                            "systemAffected": "Transmission",
                            "eic": "064",
                            "partsUsed": [
                                {
                                    "nsn": "2530-01-603-2462",
                                    "partName": "Ramp Actuator Assembly",
                                    "quantity": 1,
                                    "unitPrice": 1800
                                }
                            ],
                            "laborHours": 4,
                            "correctiveAction": "Replaced ramp actuator assembly and verified operation"
                        }
                    ],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": false,
                        "category": "Repair",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-348794",
                        "inspectionDate": "2024-12-04",
                        "passed": false,
                        "remarks": "Maintenance ongoing - awaiting completion of repairs"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-2",
                    "date": "2024-10-07",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Driveline",
                    "mileageAtService": 2111,
                    "hoursAtService": 225,
                    "technicianId": "SSG-408264",
                    "supervisorId": "SSG-923773",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SFC-236217",
                        "inspectionDate": "2024-10-07",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-4",
                    "date": "2024-04-22",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Driveline",
                    "mileageAtService": 2167,
                    "hoursAtService": 216,
                    "technicianId": "SPC-968447",
                    "supervisorId": "SSG-676222",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SFC-182",
                        "inspectionDate": "2024-04-22",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-3",
                    "date": "2024-02-10",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Driveline",
                    "mileageAtService": 1738,
                    "hoursAtService": 194,
                    "technicianId": "SSG-145157",
                    "supervisorId": "SFC-25045",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SFC-477313",
                        "inspectionDate": "2024-02-10",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-5",
                    "date": "2024-02-08",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Driveline",
                    "mileageAtService": 1726,
                    "hoursAtService": 132,
                    "technicianId": "SSG-245910",
                    "supervisorId": "SFC-422290",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-593048",
                        "inspectionDate": "2024-02-08",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                }
            ],
            "modifications": [],
            "recurringServices": [
                {
                    "serviceType": "Quarterly Service",
                    "lastPerformed": "2024-12-04",
                    "nextDue": "2025-03-04",
                    "interval": "90 days"
                },
                {
                    "serviceType": "Annual Service",
                    "lastPerformed": "2024-12-04",
                    "nextDue": "2025-12-04",
                    "interval": "365 days"
                }
            ]
        }
    },
    {
        "id": "3-77-C-303",
        "bumperNumber": "3-77-C-303",
        "registrationNumber": "3rd BCT3-77051304",
        "commandId": "3PLT-C-3-77-3rd BCT",
        "unitAssignment": {
            "platoon": "3rd Platoon",
            "company": "C",
            "battalion": "3-77",
            "brigade": "3rd BCT",
            "division": "1st Armored"
        },
        "platform": "M2A4 Bradley",
        "platformCategory": "IFV",
        "platformType": "Combat Vehicle",
        "status": {
            "isDeadlined": false,
            "operationalStatus": "PMC",
            "maintenanceLevel": "10/20",
            "lastMaintenanceDate": "2024-11-18",
            "nextScheduledMaintenance": "2025-02-16",
            "fuelLevel": 81
        },
        "maintenanceHistory": {
            "mileage": 5874,
            "engineHours": 530,
            "records": [
                {
                    "workOrderNumber": "WO-2024-3",
                    "date": "2024-11-18",
                    "type": "Corrective",
                    "level": "10/20",
                    "subsystem": "Brakes",
                    "mileageAtService": 5488,
                    "hoursAtService": 462,
                    "technicianId": "SPC-563673",
                    "supervisorId": "SSG-39673",
                    "faults": [
                        {
                            "faultNumber": "F-2024-3",
                            "description": "Ramp actuator malfunction",
                            "status": "Open",
                            "priority": "Medium",
                            "systemAffected": "Infantry Compartment",
                            "eic": "328",
                            "partsUsed": [
                                {
                                    "nsn": "2530-01-603-2462",
                                    "partName": "Track Assembly",
                                    "quantity": 1,
                                    "unitPrice": 1500
                                }
                            ],
                            "laborHours": 7,
                            "correctiveAction": "Replaced track assembly and verified operation"
                        }
                    ],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": false,
                        "category": "Repair",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SFC-401568",
                        "inspectionDate": "2024-11-18",
                        "passed": false,
                        "remarks": "Maintenance ongoing - awaiting completion of repairs"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-4",
                    "date": "2024-11-18",
                    "type": "Corrective",
                    "level": "10/20",
                    "subsystem": "Electrical",
                    "mileageAtService": 4710,
                    "hoursAtService": 467,
                    "technicianId": "SSG-543485",
                    "supervisorId": "SFC-784096",
                    "faults": [
                        {
                            "faultNumber": "F-2024-4",
                            "description": "Power distribution fault",
                            "status": "In Progress",
                            "priority": "Medium",
                            "systemAffected": "Hydraulics",
                            "eic": "069",
                            "partsUsed": [
                                {
                                    "nsn": "2530-01-603-2462",
                                    "partName": "Track Assembly",
                                    "quantity": 1,
                                    "unitPrice": 1500
                                }
                            ],
                            "laborHours": 2,
                            "correctiveAction": "Replaced track assembly and verified operation"
                        }
                    ],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": false,
                        "category": "Repair",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-174255",
                        "inspectionDate": "2024-11-18",
                        "passed": false,
                        "remarks": "Maintenance ongoing - awaiting completion of repairs"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-5",
                    "date": "2024-03-07",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Transmission",
                    "mileageAtService": 4266,
                    "hoursAtService": 414,
                    "technicianId": "SSG-963662",
                    "supervisorId": "SSG-104370",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-453610",
                        "inspectionDate": "2024-03-07",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-2",
                    "date": "2024-03-02",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Driveline",
                    "mileageAtService": 5498,
                    "hoursAtService": 485,
                    "technicianId": "SSG-648687",
                    "supervisorId": "SFC-154490",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SFC-46575",
                        "inspectionDate": "2024-03-02",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-1",
                    "date": "2024-02-12",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Transmission",
                    "mileageAtService": 5874,
                    "hoursAtService": 530,
                    "technicianId": "SPC-466427",
                    "supervisorId": "SSG-911669",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-442580",
                        "inspectionDate": "2024-02-12",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                }
            ],
            "modifications": [],
            "recurringServices": [
                {
                    "serviceType": "Quarterly Service",
                    "lastPerformed": "2024-11-18",
                    "nextDue": "2025-02-16",
                    "interval": "90 days"
                },
                {
                    "serviceType": "Annual Service",
                    "lastPerformed": "2024-11-18",
                    "nextDue": "2025-11-18",
                    "interval": "365 days"
                }
            ]
        }
    },
    {
        "id": "3-77-D-51502",
        "bumperNumber": "3-77-D-51502",
        "registrationNumber": "3rd BCT3-77051502",
        "commandId": "HQ-D-3-77-3rd BCT",
        "unitAssignment": {
            "platoon": "Company Headquarters",
            "company": "D",
            "battalion": "3-77",
            "brigade": "3rd BCT",
            "division": "1st Armored"
        },
        "platform": "M1126 Stryker ICV DVH",
        "platformCategory": "APC",
        "platformType": "Combat Vehicle",
        "status": {
            "isDeadlined": false,
            "operationalStatus": "PMC",
            "maintenanceLevel": "10/20",
            "lastMaintenanceDate": "2024-12-01",
            "nextScheduledMaintenance": "2025-03-01",
            "fuelLevel": 76
        },
        "maintenanceHistory": {
            "mileage": 2689,
            "engineHours": 232,
            "records": [
                {
                    "workOrderNumber": "WO-2024-2",
                    "date": "2024-12-01",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Engine",
                    "mileageAtService": 2234,
                    "hoursAtService": 193,
                    "technicianId": "SPC-841373",
                    "supervisorId": "SSG-739118",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-467381",
                        "inspectionDate": "2024-12-01",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-4",
                    "date": "2024-11-30",
                    "type": "Corrective",
                    "level": "10/20",
                    "subsystem": "Driveline",
                    "mileageAtService": 1234,
                    "hoursAtService": 181,
                    "technicianId": "SPC-434915",
                    "supervisorId": "SFC-454266",
                    "faults": [
                        {
                            "faultNumber": "F-2024-4",
                            "description": "Suspension system fault",
                            "status": "In Progress",
                            "priority": "Medium",
                            "systemAffected": "Weapons Station",
                            "eic": "122",
                            "partsUsed": [
                                {
                                    "nsn": "2530-01-678-9012",
                                    "partName": "Tire Assembly",
                                    "quantity": 1,
                                    "unitPrice": 1200
                                }
                            ],
                            "laborHours": 2,
                            "correctiveAction": "Replaced tire assembly and verified operation"
                        }
                    ],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": false,
                        "category": "Repair",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SFC-167818",
                        "inspectionDate": "2024-11-30",
                        "passed": false,
                        "remarks": "Maintenance ongoing - awaiting completion of repairs"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-3",
                    "date": "2024-11-23",
                    "type": "Corrective",
                    "level": "10/20",
                    "subsystem": "Electrical",
                    "mileageAtService": 1715,
                    "hoursAtService": 200,
                    "technicianId": "SGT-138478",
                    "supervisorId": "SFC-169727",
                    "faults": [
                        {
                            "faultNumber": "F-2024-3",
                            "description": "Personnel door mechanism failure",
                            "status": "In Progress",
                            "priority": "Medium",
                            "systemAffected": "Drive Train",
                            "eic": "320",
                            "partsUsed": [
                                {
                                    "nsn": "1005-01-534-5678",
                                    "partName": "RWS Control Unit",
                                    "quantity": 1,
                                    "unitPrice": 4500
                                }
                            ],
                            "laborHours": 6,
                            "correctiveAction": "Replaced rws control unit and verified operation"
                        }
                    ],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": false,
                        "category": "Repair",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SFC-654620",
                        "inspectionDate": "2024-11-23",
                        "passed": false,
                        "remarks": "Maintenance ongoing - awaiting completion of repairs"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-5",
                    "date": "2024-03-24",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Driveline",
                    "mileageAtService": 985,
                    "hoursAtService": 196,
                    "technicianId": "SPC-108778",
                    "supervisorId": "SSG-349095",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-603660",
                        "inspectionDate": "2024-03-24",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2023-1",
                    "date": "2023-12-31",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Brakes",
                    "mileageAtService": 2689,
                    "hoursAtService": 232,
                    "technicianId": "SSG-594599",
                    "supervisorId": "SFC-227043",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-724012",
                        "inspectionDate": "2023-12-31",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                }
            ],
            "modifications": [],
            "recurringServices": [
                {
                    "serviceType": "Quarterly Service",
                    "lastPerformed": "2024-12-01",
                    "nextDue": "2025-03-01",
                    "interval": "90 days"
                },
                {
                    "serviceType": "Annual Service",
                    "lastPerformed": "2024-12-01",
                    "nextDue": "2025-12-01",
                    "interval": "365 days"
                }
            ]
        }
    },
    {
        "id": "3-77-D-101",
        "bumperNumber": "3-77-D-101",
        "registrationNumber": "3rd BCT3-77051602",
        "commandId": "1PLT-D-3-77-3rd BCT",
        "unitAssignment": {
            "platoon": "1st Platoon",
            "company": "D",
            "battalion": "3-77",
            "brigade": "3rd BCT",
            "division": "1st Armored"
        },
        "platform": "M1126 Stryker ICV DVH",
        "platformCategory": "APC",
        "platformType": "Combat Vehicle",
        "status": {
            "isDeadlined": true,
            "operationalStatus": "NMCM",
            "maintenanceLevel": "30",
            "lastMaintenanceDate": "2024-11-19",
            "nextScheduledMaintenance": "2025-02-17",
            "fuelLevel": 90
        },
        "maintenanceHistory": {
            "mileage": 3102,
            "engineHours": 370,
            "records": [
                {
                    "workOrderNumber": "WO-2024-2",
                    "date": "2024-11-19",
                    "type": "Corrective",
                    "level": "30",
                    "subsystem": "Electrical",
                    "mileageAtService": 2763,
                    "hoursAtService": 363,
                    "technicianId": "SGT-95053",
                    "supervisorId": "SSG-20728",
                    "faults": [
                        {
                            "faultNumber": "F-2024-2",
                            "description": "Tire pressure monitoring system error",
                            "status": "Open",
                            "priority": "High",
                            "systemAffected": "Hydraulics",
                            "eic": "714",
                            "partsUsed": [
                                {
                                    "nsn": "2530-01-612-3456",
                                    "partName": "Wheel Hub Assembly",
                                    "quantity": 1,
                                    "unitPrice": 2200
                                }
                            ],
                            "laborHours": 2,
                            "correctiveAction": "Replaced wheel hub assembly and verified operation"
                        }
                    ],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": false,
                        "category": "Repair",
                        "deadlineStatus": true
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-756153",
                        "inspectionDate": "2024-11-19",
                        "passed": false,
                        "remarks": "Maintenance ongoing - awaiting completion of repairs"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-1",
                    "date": "2024-04-08",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Driveline",
                    "mileageAtService": 3102,
                    "hoursAtService": 370,
                    "technicianId": "SPC-8663",
                    "supervisorId": "SSG-892373",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-101110",
                        "inspectionDate": "2024-04-08",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-3",
                    "date": "2024-03-03",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Brakes",
                    "mileageAtService": 2780,
                    "hoursAtService": 316,
                    "technicianId": "SSG-805910",
                    "supervisorId": "SSG-549614",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-314073",
                        "inspectionDate": "2024-03-03",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                }
            ],
            "modifications": [],
            "recurringServices": [
                {
                    "serviceType": "Quarterly Service",
                    "lastPerformed": "2024-11-19",
                    "nextDue": "2025-02-17",
                    "interval": "90 days"
                },
                {
                    "serviceType": "Annual Service",
                    "lastPerformed": "2024-11-19",
                    "nextDue": "2025-11-19",
                    "interval": "365 days"
                }
            ]
        }
    },
    {
        "id": "3-77-HHC-52005",
        "bumperNumber": "3-77-HHC-52005",
        "registrationNumber": "3rd BCT3-77052005",
        "commandId": "Headquarters-HHC-3-77-3rd BCT",
        "unitAssignment": {
            "platoon": "Headquarters Platoon",
            "company": "HHC",
            "battalion": "3-77",
            "brigade": "3rd BCT",
            "division": "1st Armored"
        },
        "platform": "M1068 Command Post",
        "platformCategory": "Command Vehicle",
        "platformType": "Support Vehicle",
        "status": {
            "isDeadlined": false,
            "operationalStatus": "PMC",
            "maintenanceLevel": "10/20",
            "lastMaintenanceDate": "2024-11-28",
            "nextScheduledMaintenance": "2025-02-26",
            "fuelLevel": 67
        },
        "maintenanceHistory": {
            "mileage": 3522,
            "engineHours": 342,
            "records": [
                {
                    "workOrderNumber": "WO-2024-5",
                    "date": "2024-11-28",
                    "type": "Corrective",
                    "level": "10/20",
                    "subsystem": "Engine",
                    "mileageAtService": 1862,
                    "hoursAtService": 278,
                    "technicianId": "SGT-623668",
                    "supervisorId": "SFC-137150",
                    "faults": [
                        {
                            "faultNumber": "F-2024-5",
                            "description": "Network system fault",
                            "status": "In Progress",
                            "priority": "Medium",
                            "systemAffected": "Engine",
                            "eic": "219",
                            "partsUsed": [
                                {
                                    "nsn": "5895-01-345-6789",
                                    "partName": "Radio Set",
                                    "quantity": 1,
                                    "unitPrice": 5500
                                }
                            ],
                            "laborHours": 6,
                            "correctiveAction": "Replaced radio set and verified operation"
                        }
                    ],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": false,
                        "category": "Repair",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SFC-319775",
                        "inspectionDate": "2024-11-28",
                        "passed": false,
                        "remarks": "Maintenance ongoing - awaiting completion of repairs"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-4",
                    "date": "2024-09-24",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Engine",
                    "mileageAtService": 2157,
                    "hoursAtService": 321,
                    "technicianId": "SSG-380536",
                    "supervisorId": "SFC-71456",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-867990",
                        "inspectionDate": "2024-09-24",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-3",
                    "date": "2024-09-21",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Electrical",
                    "mileageAtService": 2728,
                    "hoursAtService": 254,
                    "technicianId": "SGT-882098",
                    "supervisorId": "SFC-140143",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-653186",
                        "inspectionDate": "2024-09-21",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-2",
                    "date": "2024-07-31",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Electrical",
                    "mileageAtService": 3458,
                    "hoursAtService": 342,
                    "technicianId": "SGT-196149",
                    "supervisorId": "SSG-479005",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SFC-671562",
                        "inspectionDate": "2024-07-31",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-1",
                    "date": "2024-06-24",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Electrical",
                    "mileageAtService": 3522,
                    "hoursAtService": 342,
                    "technicianId": "SPC-308138",
                    "supervisorId": "SFC-871839",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-212733",
                        "inspectionDate": "2024-06-24",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                },
                {
                    "workOrderNumber": "WO-2024-6",
                    "date": "2024-03-30",
                    "type": "Scheduled",
                    "level": "10/20",
                    "subsystem": "Driveline",
                    "mileageAtService": 2362,
                    "hoursAtService": 162,
                    "technicianId": "SGT-466471",
                    "supervisorId": "SSG-386296",
                    "faults": [],
                    "pmcsPerformed": true,
                    "serviceType": {
                        "scheduled": true,
                        "category": "Quarterly Service",
                        "deadlineStatus": false
                    },
                    "qualityControl": {
                        "inspectorId": "SSG-340312",
                        "inspectionDate": "2024-03-30",
                        "passed": true,
                        "remarks": "All maintenance performed to standard"
                    }
                }
            ],
            "modifications": [],
            "recurringServices": [
                {
                    "serviceType": "Quarterly Service",
                    "lastPerformed": "2024-11-28",
                    "nextDue": "2025-02-26",
                    "interval": "90 days"
                },
                {
                    "serviceType": "Annual Service",
                    "lastPerformed": "2024-11-28",
                    "nextDue": "2025-11-28",
                    "interval": "365 days"
                }
            ]
        }
    }
]