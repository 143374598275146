import './ReadinessView.css'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import DataTable from 'react-data-table-component'
import { PieChart as DeadlinedPieChart } from '../charts/PieCharTwoLabels'


interface ReadinessViewProps {
  selectedCommandLevel: string;
  setSelectedCommandLevel: (commandLevel: string) => void;
  readinessData: ReadinessData[];
}

export interface ReadinessData {
  id: string
  name: string
  commandId: string
  commandLevel: string
  vehicleCount: number
  operationalCount: number
  deadlinedCount: number
  operationalPercent: number
  deadlinedPercent: number
  fmcCount: number
  fmcPercent: number
  pmcCount: number
  pmcPercent: number
  nmcmCount: number
  nmcmPercent: number
  nmcsCount: number
  nmcsPercent: number
  actions: string
}


const ReadinessView: React.FC<ReadinessViewProps> = ({ readinessData }: { readinessData: ReadinessData[] }) => {
  const navigate = useNavigate()

  const handleRowClick = (id: string) => {
    navigate(`/command/fleet/vehicle-list?commandLevel=${id}`)
  }

  const capitalize = (str: string) => {
    if (typeof str !== 'string' || !str) return str;
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }
  

  const columns = [
    {
      name: 'COMMAND LEVEL',
      selector: (row: ReadinessData) => row.commandId,
      cell: (row: ReadinessData) => (
        <div onClick={() => handleRowClick(row.id)} className='clickable'>
          <div className=''>{row.commandId}</div>
          <div className='neutral-label'>{capitalize(row.commandLevel)}</div>
        </div>
      ),
      sortable: true,
      width: '180px',
    },
    {
      name: 'OPERATIONAL',
      selector: (row: ReadinessData) => row.operationalCount, // This is used for sorting
      cell: (row: ReadinessData) => (
        <div className="pie-chart-cell green-cell clickable" onClick={() => handleRowClick(row.id)}>
          <div className='col1'>
            <div className='count'>{row.operationalCount}</div>
            <div className='green-label'>Operational</div>
          </div>
          <div className='col2'>
            <DeadlinedPieChart 
              value1={row.operationalPercent} 
              value2={row.deadlinedPercent} 
              status={'good'}
              width={50}
              height={50} 
            />
            <div className='green-percent'>{row.operationalPercent}%</div>
          </div>
        </div>
      ),
      sortable: true,
      width: '200px',
    },
    {
      name: (
        <>
        DEADLINED<br/>(NMCM / NMCS)
        </>
      ),
      selector: (row: ReadinessData) => row.deadlinedCount, // This is used for sorting
      cell: (row: ReadinessData) => (
        <div className="pie-chart-cell clickable" onClick={() => handleRowClick(row.id)}>
          <div className='col1'>
            <div className='count'>{row.deadlinedCount}</div>
            <div className='red-label'>Deadlined</div>
          </div>
          <div className='col2'>
            <DeadlinedPieChart 
              value1={row.operationalPercent} 
              value2={row.deadlinedPercent} 
              status={'bad'} 
              width={50}
              height={50}
            />
            <div className='red-percent'>{row.deadlinedPercent}%</div>
          </div>
        </div>
      ),
      sortable: true,
      width: '200px',
    },
    {
      name: 'TOTAL',
      selector: (row: ReadinessData) => row.vehicleCount,
      cell: (row: ReadinessData) => (
        <div onClick={() => handleRowClick(row.id)} className='clickable'>
          <div className='count'>{row.vehicleCount}</div>
          <div className='neutral-label'>Total</div>
        </div>
      ),
      sortable: true,
      width: 'auto',
    },
    {
      name: 'FMC',
      selector: (row: ReadinessData) => row.fmcCount,
      cell: (row: ReadinessData) => (
        <div onClick={() => handleRowClick(row.id)} className='clickable'>
          <div className='count'>{row.fmcCount}</div>
          <div className='neutral-label'>Total</div>
        </div>
      ),
      sortable: true,
      width: 'auto',
    },
    {
      name: 'PMC',
      selector: (row: ReadinessData) => row.pmcCount,
      cell: (row: ReadinessData) => (
        <div onClick={() => handleRowClick(row.id)} className='clickable'>
          <div className='count'>{row.pmcCount}</div>
          <div className='neutral-label'>Total</div>
        </div>
      ),
      sortable: true,
      width: 'auto',
    },
    {
      name: 'NMCM',
      selector: (row: ReadinessData) => row.nmcmCount,
      cell: (row: ReadinessData) => (
        <div onClick={() => handleRowClick(row.id)} className='clickable'>
          <div className='count'>{row.nmcmCount}</div>
          <div className='neutral-label'>Total</div>
        </div>
      ),
      sortable: true,
      width: 'auto',
    },
    {
      name: 'NMCS',
      selector: (row: ReadinessData) => row.nmcsCount,
      cell: (row: ReadinessData) => (
        <div onClick={() => handleRowClick(row.id)} className='clickable'>
          <div className='count'>{row.nmcsCount}</div>
          <div className='neutral-label'>Total</div>
        </div>
      ),
      sortable: true,
      width: 'auto',
    },
    // {
    //   name: 'ACTIONS',
    //   selector: (row: ReadinessData) => row.actions,
    //   cell: (row: ReadinessData) => (
    //     <div className="ellipsis-cell">
    //       <i className="icon-ellipsis-horizontal nav-icon large-icon"></i> {row.actions}
    //     </div>
    //   ),
    //   sortable: false,
    //   width: 'auto',
    // },
  ]


  return (
    <div className="readiness-view">
      <DataTable
        columns={columns}
        data={readinessData}
        pagination
        responsive
        className='readiness-data-table'
      />
    </div>
  )
}

export default ReadinessView