import './AddMaintenanceForm.css'
import { getCurrentDate, getCurrentYear } from '../utils/utils'
import { useState } from 'react';
import { useEffect } from 'react';

interface Props {
  onCancel: () => void;
  onSave: () => void;
}

/**
 * Returns a random work order number in the format WO-yyyy-nnn
 * @returns string
 */
export function getRandomWorkOrderNumber(): string {
  const randomNumber = Math.floor(Math.random() * 1000);
  return `WO-${getCurrentYear()}-${randomNumber}`;
}

/**
 * Returns a random tech ID in the format T-nnn
 * @returns string
 */
export function getRandomTechId(): string {
  const randomNumber = Math.floor(Math.random() * 100000);
  return `SGT-${randomNumber}`;
}

const AddMaintenanceForm = ({ onCancel, onSave }: Props) => {
  const [formData, setFormData] = useState({
    faultName: '',
    parts: Array(7).fill(''),
    notes: ''
  });

  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * maintenanceData.maintenanceRecords.length);
    const randomRecord = maintenanceData.maintenanceRecords[randomIndex];
    
    const selectedParts = [...randomRecord.parts];
    while (selectedParts.length < 7) {
      selectedParts.push('');
    }

    setFormData({
      faultName: randomRecord.faultName,
      parts: selectedParts,
      notes: randomRecord.notes
    });
  }, []);

  
  return (
    <div className='add-maintenance-form'>

      <div className='title'>ADD MAINTENANCE RECORD</div>

      <div>
        <div className='label'>Date</div>
        <div className='input'>
          <input type="date" defaultValue={getCurrentDate()} />
        </div>
      </div>
      
      <div>
        <div className='label'>Work Order #</div>
        <div className='input'>
          <input type="text" defaultValue={getRandomWorkOrderNumber()} />
        </div>
      </div>

      <div>
        <div className='label'>Tech ID</div>
        <div className='input'>
          <input type="text" defaultValue={getRandomTechId()} />
        </div>
      </div>
      
      <div>
        <div className='label'>Fault Name</div>
        <div className='input'>
          <input type="text" size={40} defaultValue={formData.faultName} />
        </div>
      </div>

      <div>
        <div className='label'>Parts</div>
          {Array(7).fill(null).map((_, index) => (
            <select
              key={index}
              value={formData.parts[index]}
              onChange={(e) => {
                const newParts = [...formData.parts];
                newParts[index] = e.target.value;
                setFormData({...formData, parts: newParts});
              }}
              className="w-full p-2 border rounded"
            >
              <option value="">Select Part</option>
              {allParts.map((part) => (
                <option key={part.id} value={part.name}>
                  {`${part.name} (${part.id})`}
                </option>
              ))}
            </select>
          ))}
      </div>

      <div>
        <div className='label'>Notes</div>
        <div className='input'>
          <textarea defaultValue={formData.notes} />
        </div>
      </div>

      <div className='button-container'>
        <button className='btn-cancel' onClick={onCancel}>Cancel</button>
        <button className='btn-save' onClick={onSave}>Save</button>
      </div>
    </div>
  )
}

export default AddMaintenanceForm



const maintenanceData = {
  maintenanceRecords: [
    {
      "faultName": "Track Tension System Failure",
      "parts": ["Track Adjuster", "Tension Spring", "Track Pin", "Track Block"],
      "notes": "Right track showing excessive slack, tension mechanism not maintaining proper adjustment. Required track tension system overhaul."
    },
    {
      "faultName": "Turret Drive Malfunction",
      "parts": ["Turret Drive Motor", "Servo Control Unit", "Slip Ring Assembly"],
      "notes": "Intermittent turret rotation issues. Electronic diagnostic revealed servo control unit failure."
    },
    {
      "faultName": "Engine Oil Pressure Warning",
      "parts": ["Oil Pressure Sensor", "Oil Filter", "Oil Pump", "Gaskets"],
      "notes": "Low oil pressure warning during operation. Found contaminated oil and failing pressure sensor."
    },
    {
      "faultName": "TOW Launcher System Error",
      "parts": ["TOW Control Interface Unit", "Launch Motor Assembly", "Wiring Harness"],
      "notes": "System failing to complete pre-launch checks. Electrical connectivity issues in control interface."
    }
  ]
};


const allParts = [
  { id: "12345-678", name: "Track Adjuster" },
  { id: "23456-789", name: "Tension Spring" },
  { id: "34567-890", name: "Track Pin" },
  { id: "45678-901", name: "Track Block" },
  { id: "56789-012", name: "Turret Drive Motor" },
  { id: "67890-123", name: "Servo Control Unit" },
  { id: "78901-234", name: "Slip Ring Assembly" },
  { id: "89012-345", name: "Oil Pressure Sensor" },
  { id: "90123-456", name: "Oil Filter" },
  { id: "01234-567", name: "Oil Pump" },
  { id: "11234-567", name: "Gaskets" },
  { id: "21234-567", name: "TOW Control Interface Unit" },
  { id: "31234-567", name: "Launch Motor Assembly" },
  { id: "41234-567", name: "Wiring Harness" }
];