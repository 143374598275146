import { useContext } from 'react'
import AppContext from '../../context/AppContext'
import ButtonFilterPanel from './ButtonFilterPanel'
import CheckboxFilterPanel from './CheckboxFilterPanel'
// import { PlatformType } from '../../data/vehicleData6'

const SideFilterPanel = () => {
  //@ts-ignore
  const { platformTypeOptions } = useContext(AppContext)

  return (
    <div>
      <ButtonFilterPanel 
        title="Platform" 
        buttons={platformTypeOptions.map(type => ({ label: type }))}
        buttonGroup="platform"
      />

      <br/>

      <div style={{ display: 'flex', gap: '10px' }}>
        <div style={{ flex: '1 1 50%' }}>
          <CheckboxFilterPanel 
            title="Subsystem" 
            checkboxes={["Brakes", "Driveline", "Electrical", "Engine", "Transmission"]} 
            checkboxGroup="subsystem"
          />
        </div>
        <div style={{ flex: '1 1 50%' }}>
          <CheckboxFilterPanel 
            title="Operation Status" 
            checkboxes={["Deadlined", "FMC", "PMC", "NMCM", "NMCS"]} 
            checkboxGroup="operationStatus"
          />
        </div>
      </div>

      <br />
      {/* <div className="panel">
        <div className="title">Readiness</div>
        <DeadlinedBarChart />
      </div> */}
    </div>
  )
}

export default SideFilterPanel
