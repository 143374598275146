import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import AppContext from '../../context/AppContext'

import './VehicleListView.css'
import React from 'react'
import DataTable from 'react-data-table-component'
import PlatformImage from './PlatformImage'

import { TransformedVehicle as VehicleData, vehicleList } from '../../data/dataUtil.ts'



interface VehicleListViewProps {
  selectedCommandLevel: string;
  setSelectedCommandLevel: (commandLevel: string) => void;
}


const VehicleListView: React.FC<VehicleListViewProps> = ({ selectedCommandLevel }) => {
  const navigate = useNavigate()
  const { filteredVehicleData }: { filteredVehicleData: any } = useContext(AppContext)
  // @ts-ignore: Ignore unused variable warning
  const [aggregateData, setAggregateData] = useState<[]>([])
  const [data, setData] = useState<VehicleData[]>([])


  const handleRowClick = (id: string) => {
    navigate(`/command/vehicle/detail/${id}`)
  }


  const columns = [
    {
      name: 'PLATFORM',
      selector: (row: VehicleData) => row.id,
      cell: (row: VehicleData) => (
        <div onClick={() => handleRowClick(row.id)} className='clickable'>
          <PlatformImage platform={row.platform} />
        </div>
      ),
      sortable: true,
      width: '190px',
    },
    {
      name: 'BUMPER #',
      selector: (row: VehicleData) => row.bumperNumber,
      cell: (row: VehicleData) => (
        <div onClick={() => handleRowClick(row.id)} className='clickable'>
          {row.bumperNumber}
        </div>
      ),
      sortable: true,
      width: 'auto',
    },
    {
      name: 'REG #',
      selector: (row: VehicleData) => row.registrationNumber,
      cell: (row: VehicleData) => (
        <div onClick={() => handleRowClick(row.id)} className='clickable'>
          {row.registrationNumber}
        </div>
      ),
      sortable: true,
      width: 'auto',
    },
    {
      name: 'STAUS',
      selector: (row: VehicleData) => row.operationalStatus,
      cell: (row: VehicleData) => (
        <div 
          onClick={() => handleRowClick(row.id)} 
          className='clickable' 
        >
          {row.operationalStatus}
        </div>
      ),
      sortable: true,
      width: 'auto',
    },
    {
      name: (
        <>
        DEADLINED<br/>(NMCM / NMCS)
        </>
      ),
      selector: (row: VehicleData) => row.deadlinedStatus,
      cell: (row: VehicleData) => (
        <div 
          onClick={() => handleRowClick(row.id)} 
          className='clickable'
          style={{ 
            backgroundColor: (row.operationalStatus === 'NMCM' || row.operationalStatus === 'NMCS') ? '#850811' : 'transparent',
            padding: '12px',
          }}
        >
          {row.operationalStatus === 'NMCM' || row.operationalStatus === 'NMCS' ? 'YES' : 'NO'}
        </div>
      ),
      sortable: true,
      width: 'auto',
    },
    {
      name: (
        <>
          LAST<br />MAINTENANCE
        </>
      ),
      selector: (row: VehicleData) => row.lastMaintenanceDate,
      cell: (row: VehicleData) => (
        <div onClick={() => handleRowClick(row.id)} className='clickable'>
          {row.lastMaintenanceDate}
        </div>
      ),
      sortable: true,
      width: 'auto',
    },
    // {
    //   name: 'ACTIONS',
    //   selector: (row: VehicleData) => row.actions,
    //   cell: (row: VehicleData) => (
    //     <div className="ellipsis-cell">
    //       <i className="icon-ellipsis-horizontal nav-icon large-icon"></i> {row.actions}
    //     </div>
    //   ),
    //   sortable: false,
    //   width: 'auto',
    // },
  ]



  useEffect(() => {
    setData(vehicleList(filteredVehicleData) as unknown as VehicleData[])

    // console.log('vehicleData: ', vehicleData)
    // console.log('aggregateData: ', newAggregateData)
    console.log('vehicleList: ', vehicleList(filteredVehicleData))

  }, [selectedCommandLevel, filteredVehicleData])
  

  return (
    <div className="vehicle-list-view">

      <DataTable
        columns={columns}
        data={data}
        pagination
        paginationPerPage={30}
        responsive
        className='readiness-data-table'
      />
    </div>
  )
}

export default VehicleListView