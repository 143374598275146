/************************************************************************************************
 * Dynamically generate vehicle data version 2.
 * Generates a full battalion of vehicles.
 * 
 * Revisions:
 * 
 * vehicleData6:
 *    - open, or closed status for maintenance record
 *    - warnings
 *    - technician notes
 *    - work category (brakes, engine, driveline, electrical, transmission)
 * 
 * vehicleData5:
 *    - added maintenance history
 *    - added helper functions for maintenance history generation
 *    - synced status object with maintenance history (last maintenance date, isDeadlined, op. status, etc)
 *    - removed vehicle getter functions (aggregate fleet data, get vehicle by id, etc). Moved to dataUtil.ts
 * 
 * vehicleData4:
 *    - added command level aggregation
 ************************************************************************************************/



// ------------------------------------------------------------------------------------------------
// Vehicle Data Generation
// ------------------------------------------------------------------------------------------------

// Types for better structure
/**
 * FMC = Fully Mission Capable
 * - Vehicle is completely operational and can perform all its designated missions
 * - No major issues or repairs needed
 * 
 * PMC = Partially Mission Capable
 * - Vehicle can perform some but not all of its missions
 * - Has issues that limit its full capability but is still operational
 * 
 * NMCM = Non-Mission Capable Maintenance
 * - Vehicle cannot perform its mission due to maintenance requirements
 * - Waiting for repairs or scheduled maintenance
 *  Often called "deadlined" in maintenance terms
 * 
 * NMCS = Non-Mission Capable Supply
 * - Vehicle cannot perform its mission due to waiting for parts/supplies
 * - The maintenance team knows what's wrong but lacks necessary parts
 * - Also considered "deadlined" status
 */



// Maintenance History functions -------------------------------------

type ServiceType = 'Scheduled' | 'Corrective' | 'Emergency';
type MaintenanceCategory = 'Repair' | 'Annual Service' | 'Quarterly Service' | 'Monthly Service';
export type SubSystem = 'Brakes' | 'Driveline' | 'Electrical' | 'Engine' | 'Transmission';

interface MaintenancePart {
  nsn: string;
  partName: string;
  quantity: number;
  unitPrice: number;
}

interface MaintenanceCheck {
  checkType: string;
  completed: boolean;
  measurement?: string;
  quantity?: number;
  remarks?: string;
}

interface Fault {
  faultNumber: string;
  description: string;
  status: 'Open' | 'In Progress' | 'Completed' | 'Deferred';
  priority: 'High' | 'Medium' | 'Low';
  systemAffected: string;
  eic: string;
  partsUsed: MaintenancePart[];
  laborHours: number;
  correctiveAction?: string;
}

interface QualityControl {
  inspectorId: string;
  inspectionDate: string;
  passed: boolean;
  remarks: string;
}

export interface MaintenanceRecord {
  workOrderNumber: string;
  date: string;
  type: ServiceType;
  level: MaintenanceLevel;
  subsystem: SubSystem;
  mileageAtService: number;
  hoursAtService: number;
  technicianId: string;
  supervisorId: string;
  faults: Fault[];
  pmcsPerformed: boolean;
  serviceType: {
    scheduled: boolean;
    category: MaintenanceCategory;
    deadlineStatus: boolean;
  };
  maintenanceChecks?: MaintenanceCheck[];
  qualityControl: QualityControl;
}

interface Modification {
  modNumber: string;
  date: string;
  description: string;
  authority: string;
  technicianId: string;
  verified: boolean;
}

interface RecurringService {
  serviceType: string;
  lastPerformed: string;
  nextDue: string;
  interval: string;
}

interface MaintenanceHistory {
  mileage: number;
  engineHours: number;
  records: MaintenanceRecord[];
  modifications: Modification[];
  recurringServices: RecurringService[];
}


// Common maintenance data
const MAINTENANCE_DATA = {
  systems: {
    IFV: [
      'Engine', 'Transmission', 'Drive Train', 'Weapons System', 'Infantry Compartment',
      '25mm Chain Gun', 'Hull Electronics', 'NBC System', 'Hydraulics'
    ],
    APC: [
      'Engine', 'Transmission', 'Drive Train', 'Weapons Station', 'Troop Compartment',
      'Hull Electronics', 'NBC System', 'Hydraulics', 'Remote Weapons System'
    ],
    'Command Vehicle': [
      'Engine', 'Communications Suite', 'Power Distribution', 'Environmental Control',
      'Mission Command Systems', 'Antenna Systems'
    ],
    'Support Vehicle': [
      'Engine', 'Transmission', 'Brake System', 'Electrical System',
      'Suspension', 'Climate Control'
    ],
    'Recovery Vehicle': [
      'Engine', 'Winch System', 'Crane Assembly', 'Hydraulics', 'Drive Train',
      'Recovery Equipment', 'Tool Systems'
    ]
  },
  commonFaults: {
    IFV: [
      'Ramp actuator malfunction',
      '25mm feed system jam',
      'Track tension issue',
      'Power distribution fault',
      'Hydraulic pressure loss'
    ],
    APC: [
      'Wheel hub assembly failure',
      'Remote weapons system malfunction',
      'Tire pressure monitoring system error',
      'Suspension system fault',
      'Personnel door mechanism failure'
    ],
    'Command Vehicle': [
      'Communications system error',
      'Generator malfunction',
      'AC unit failure',
      'Network system fault',
      'Antenna alignment issue'
    ],
    'Support Vehicle': [
      'Engine overheating',
      'Transmission slipping',
      'Air brake system fault',
      'Electrical system short',
      'Cooling system leak'
    ],
    'Recovery Vehicle': [
      'Winch system failure',
      'Crane hydraulic leak',
      'Boom extension malfunction',
      'Outrigger system fault',
      'Towing system failure'
    ]
  },
  parts: {
    IFV: [
      { nsn: '2530-01-603-2462', name: 'Ramp Actuator Assembly', price: 1800 },
      { nsn: '1005-01-445-6098', name: '25mm Barrel', price: 3200 },
      { nsn: '2530-01-603-2462', name: 'Track Assembly', price: 1500 }
    ],
    APC: [
      { nsn: '2530-01-612-3456', name: 'Wheel Hub Assembly', price: 2200 },
      { nsn: '1005-01-534-5678', name: 'RWS Control Unit', price: 4500 },
      { nsn: '2530-01-678-9012', name: 'Tire Assembly', price: 1200 }
    ],
    'Command Vehicle': [
      { nsn: '5895-01-345-6789', name: 'Radio Set', price: 5500 },
      { nsn: '6110-01-456-7890', name: 'Power Supply Unit', price: 3200 },
      { nsn: '5895-01-567-8901', name: 'Antenna System', price: 2800 }
    ],
    'Support Vehicle': [
      { nsn: '2530-01-789-0123', name: 'Brake Assembly', price: 1500 },
      { nsn: '2930-01-890-1234', name: 'Cooling System', price: 2000 },
      { nsn: '2520-01-901-2345', name: 'Transmission Kit', price: 3500 }
    ],
    'Recovery Vehicle': [
      { nsn: '3950-01-012-3456', name: 'Winch Assembly', price: 5000 },
      { nsn: '3950-01-123-4567', name: 'Crane Control Unit', price: 3800 },
      { nsn: '2590-01-234-5678', name: 'Recovery Boom', price: 6500 }
    ]
  }
};

// Helper functions for maintenance history generation
const deriveOperationalStatus = (maintenanceHistory: MaintenanceHistory): {
  operationalStatus: OperationalStatus;
  isDeadlined: boolean;
} => {
  // Check most recent maintenance record
  // const latestRecord = maintenanceHistory.records[0];
  
  // Check for open faults across all recent records (last 30 days)
  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
  
  const recentRecords = maintenanceHistory.records.filter(record => 
    new Date(record.date) >= thirtyDaysAgo
  );

  const openFaults = recentRecords.flatMap(record => 
    record.faults.filter(fault => 
      fault.status === 'Open' || fault.status === 'In Progress'
    )
  );

  // Check for parts on order
  const waitingForParts = openFaults.some(fault => 
    fault.partsUsed.some(part => part.quantity === 0 || !part.quantity)
  );

  // Logic for status determination
  if (openFaults.length === 0) {
    return { operationalStatus: 'FMC', isDeadlined: false };
  } else if (waitingForParts) {
    return { operationalStatus: 'NMCS', isDeadlined: true };
  } else if (openFaults.some(fault => fault.priority === 'High')) {
    return { operationalStatus: 'NMCM', isDeadlined: true };
  } else {
    return { operationalStatus: 'PMC', isDeadlined: false };
  }
};

const determineMaintenanceLevel = (maintenanceHistory: MaintenanceHistory): MaintenanceLevel => {
  const latestRecord = maintenanceHistory.records[0];
  if (!latestRecord) return '10/20';
  
  // If there's an open high priority fault, escalate to higher maintenance level
  const hasHighPriorityFault = latestRecord.faults.some(
    fault => fault.priority === 'High' && 
    (fault.status === 'Open' || fault.status === 'In Progress')
  );

  if (hasHighPriorityFault) {
    return '30';
  }

  return latestRecord.level;
};

const generateMileage = (platformCategory: PlatformCategory): number => {
  const baseRange = {
    'IFV': { min: 2000, max: 8000 },      // Bradleys tend to move more than tanks
    'APC': { min: 2500, max: 9000 },      // Strykers typically accumulate more mileage
    'Command Vehicle': { min: 1500, max: 5000 },
    'Support Vehicle': { min: 3000, max: 10000 },  // Support vehicles often travel further
    'Recovery Vehicle': { min: 1000, max: 4000 }
  }[platformCategory] || { min: 2000, max: 6000 };

  return Math.floor(Math.random() * (baseRange.max - baseRange.min) + baseRange.min);
};

const generateEngineHours = (mileage: number): number => {
  return Math.floor(mileage / 10 * (0.8 + Math.random() * 0.4));
};

const generateFault = (platformCategory: PlatformCategory, date: string, number: number): Fault => {
  //@ts-ignore
  const faults = MAINTENANCE_DATA.commonFaults[platformCategory] || MAINTENANCE_DATA.commonFaults.Tank;
  //@ts-ignore
  const systems = MAINTENANCE_DATA.systems[platformCategory] || MAINTENANCE_DATA.systems.Tank;
  //@ts-ignore
  const parts = MAINTENANCE_DATA.parts[platformCategory] || MAINTENANCE_DATA.parts.Tank;
  
  const fault = faults[Math.floor(Math.random() * faults.length)];
  const system = systems[Math.floor(Math.random() * systems.length)];
  const part = parts[Math.floor(Math.random() * parts.length)];

  return {
    faultNumber: `F-${date.split('-')[0]}-${number}`,
    description: fault,
    status: 'Completed',
    priority: Math.random() > 0.7 ? 'High' : Math.random() > 0.5 ? 'Medium' : 'Low',
    systemAffected: system,
    eic: Math.floor(Math.random() * 999).toString().padStart(3, '0'),
    partsUsed: [
      {
        nsn: part.nsn,
        partName: part.name,
        quantity: 1,
        unitPrice: part.price
      }
    ],
    laborHours: Math.floor(Math.random() * 6) + 2,
    correctiveAction: `Replaced ${part.name.toLowerCase()} and verified operation`
  };
};

// Helper function to determine subsystem based on fault description
const determineSubsystem = (faults: Fault[]): SubSystem => {
  const faultDescriptions = faults.map(f => f.description.toLowerCase());
  const systemPatterns = {
    Brakes: ['brake', 'stopping', 'pedal'],
    Driveline: ['track', 'wheel', 'drive', 'hub', 'suspension'],
    Electrical: ['power', 'electrical', 'short', 'battery', 'electronics', 'communications'],
    Engine: ['engine', 'cooling', 'overheating', 'fuel'],
    Transmission: ['transmission', 'gear', 'shifting']
  };

  for (const [system, patterns] of Object.entries(systemPatterns)) {
    if (faultDescriptions.some(desc => patterns.some(pattern => desc.includes(pattern)))) {
      return system as SubSystem;
    }
  }

  // For scheduled maintenance without specific faults, randomly assign a subsystem
  const subsystems: SubSystem[] = ['Brakes', 'Driveline', 'Electrical', 'Engine', 'Transmission'];
  return subsystems[Math.floor(Math.random() * subsystems.length)];
};

const generateMaintenanceHistory = (platformCategory: PlatformCategory): MaintenanceHistory => {
  const mileage = generateMileage(platformCategory);
  const engineHours = generateEngineHours(mileage);
  const records: MaintenanceRecord[] = [];
  
  // Generate 3-6 maintenance records over the past year
  const numRecords = Math.floor(Math.random() * 4) + 3;
  const today = new Date();
  
  // Generate records with potential open faults
  for (let i = 0; i < numRecords; i++) {
    const recordDate = new Date(today);
    recordDate.setDate(today.getDate() - Math.floor(Math.random() * 365));
    
    const isRecent = (today.getTime() - recordDate.getTime()) < (30 * 24 * 60 * 60 * 1000);
    const faults: Fault[] = [];
    
    // For recent records, maybe generate an open fault
    if (isRecent && Math.random() > 0.7) {
      const fault = generateFault(platformCategory, formatDate(recordDate), i + 1);
      fault.status = Math.random() > 0.5 ? 'Open' : 'In Progress';
      fault.priority = Math.random() > 0.7 ? 'High' : 'Medium';
      faults.push(fault);
    }
    
    const record: MaintenanceRecord = {
      workOrderNumber: `WO-${recordDate.getFullYear()}-${i + 1}`,
      date: formatDate(recordDate),
      type: faults.length > 0 ? 'Corrective' : 'Scheduled',
      level: faults.length > 0 && faults[0].priority === 'High' ? '30' : '10/20',
      subsystem: determineSubsystem(faults),
      mileageAtService: mileage - (i * Math.floor(Math.random() * 500)),
      hoursAtService: engineHours - (i * Math.floor(Math.random() * 50)),
      technicianId: `${['SPC', 'SGT', 'SSG'][Math.floor(Math.random() * 3)]}-${Math.floor(Math.random() * 999999)}`,
      supervisorId: `${['SSG', 'SFC'][Math.floor(Math.random() * 2)]}-${Math.floor(Math.random() * 999999)}`,
      faults: faults,
      pmcsPerformed: true,
      serviceType: {
        scheduled: faults.length === 0,
        category: faults.length === 0 ? 'Quarterly Service' : 'Repair',
        deadlineStatus: faults.length > 0 && faults[0].priority === 'High'
      },
      qualityControl: {
        inspectorId: `${['SSG', 'SFC'][Math.floor(Math.random() * 2)]}-${Math.floor(Math.random() * 999999)}`,
        inspectionDate: formatDate(recordDate),
        passed: faults.length === 0,
        remarks: faults.length === 0 ? 
          'All maintenance performed to standard' : 
          'Maintenance ongoing - awaiting completion of repairs'
      }
    };
    
    records.push(record);
  }

  // Sort records by date, most recent first
  records.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

  const maintenanceHistory: MaintenanceHistory = {
    mileage,
    engineHours,
    records,
    modifications: [],
    recurringServices: [
      {
        serviceType: 'Quarterly Service',
        lastPerformed: records[0].date,
        nextDue: formatDate(new Date(new Date(records[0].date).getTime() + 90 * 24 * 60 * 60 * 1000)),
        interval: '90 days'
      },
      {
        serviceType: 'Annual Service',
        lastPerformed: records[0].date,
        nextDue: formatDate(new Date(new Date(records[0].date).getTime() + 365 * 24 * 60 * 60 * 1000)),
        interval: '365 days'
      }
    ]
  };

  return maintenanceHistory;
};


// -----------------------------




type OperationalStatus = 'FMC' | 'PMC' | 'NMCM' | 'NMCS';
type MaintenanceLevel = '10/20' | '30' | '40' | '50';
type PlatformCategory = 'IFV' | 'APC' | 'Command Vehicle' | 'Support Vehicle' | 'Recovery Vehicle';


interface VehicleStatus {
  isDeadlined: boolean;
  operationalStatus: OperationalStatus;
  maintenanceLevel: MaintenanceLevel;
  lastMaintenanceDate: string;
  nextScheduledMaintenance: string;
  fuelLevel: number;
}

interface UnitAssignment {
  platoon: string;
  company: string;
  battalion: string;
  brigade: string;
  division: string;
}

export interface Vehicle {
  id: string;
  bumperNumber: string;
  registrationNumber: string;
  commandId: string;
  unitAssignment: UnitAssignment;
  platform: string;
  platformCategory: PlatformCategory;
  platformType: string;
  status: VehicleStatus;
  maintenanceHistory: MaintenanceHistory;
}

const DIVISION_CONFIG = {
  name: "1st Armored",
  brigades: [
    {
      name: "2nd BCT",
      battalions: [
        { name: "1-67", type: "Combined Arms" },
        { name: "2-67", type: "Combined Arms" },
        { name: "3-67", type: "Combined Arms" }
      ]
    },
    {
      name: "3rd BCT",
      battalions: [
        { name: "1-77", type: "Combined Arms" },
        { name: "2-77", type: "Combined Arms" },
        { name: "3-77", type: "Combined Arms" }
      ]
    }
  ]
};

// Configuration for unit structure
const UNIT_CONFIG = {
  companies: {
    // Line Companies (3-4 typically in a battalion)
    A: { type: 'Infantry', platformType: 'M2A4 Bradley', category: 'IFV' },   
    B: { type: 'Infantry', platformType: 'M2A4 Bradley', category: 'IFV' },   
    C: { type: 'Infantry', platformType: 'M2A4 Bradley', category: 'IFV' },
    D: { type: 'Weapons', platformType: 'M1126 Stryker ICV DVH', category: 'APC' }, // Double-V Hull variant
    // Headquarters and Support
    HHC: { type: 'Headquarters', platformType: 'Mixed', category: 'Support Vehicle' }
  },

  // Structure constants
  platoonsPerLineCompany: 3, // Typical infantry company has 3 line platoons + weapons platoon
  platoonsInHHC: 5, // HQ PLT, Medical PLT, Comms PLT, Support PLT, Maintenance PLT
  vehiclesPerLinePlatoon: 4, // Standard infantry platoon vehicle count

  // HHC Platoon configurations
  hqPlatoonVehicles: [
    { type: 'AMPV Mission Command', category: 'Command Vehicle', count: 3 },
    { type: 'M1068 Command Post', category: 'Command Vehicle', count: 2 }
  ],
  medicalPlatoonVehicles: [
    { type: 'AMPV Medical Treatment', category: 'Support Vehicle', count: 2 },
    { type: 'AMPV Medical Evacuation', category: 'Support Vehicle', count: 4 }
  ],
  supportPlatoonVehicles: [
    { type: 'M977 HEMTT Cargo', category: 'Support Vehicle', count: 4 },
    { type: 'M978 HEMTT Fuel', category: 'Support Vehicle', count: 2 }
  ],
  maintenancePlatoonVehicles: [
    { type: 'M88A2 HERCULES', category: 'Recovery Vehicle', count: 2 },
    { type: 'M984 HEMTT Wrecker', category: 'Recovery Vehicle', count: 2 },
    { type: 'M977 HEMTT Maintenance', category: 'Support Vehicle', count: 2 }
  ]
};

// Define a type for the company keys
type CompanyKeys = keyof typeof UNIT_CONFIG.companies;

// Helper functions
//@ts-ignore: Ignore unused parameters
const generateVehicleId = (company: string, battalion: string, brigade: string, number: number) => 
  `${battalion}-${company}-${number.toString().padStart(3, '0')}`;

const generateBumperNumber = (company: string, battalion: string, number: number) => 
  `${battalion}-${company}-${number.toString().padStart(3, '0')}`;

const generateRegistration = (brigade: string, battalion: string, number: number) => 
  `${brigade}${battalion}${number.toString().padStart(6, '0')}`;

const formatDate = (date: Date): string => {
  return date.toISOString().split('T')[0];
};

const generateStatus = (maintenanceHistory: MaintenanceHistory): VehicleStatus => {
  const { operationalStatus, isDeadlined } = deriveOperationalStatus(maintenanceHistory);
  const maintenanceLevel = determineMaintenanceLevel(maintenanceHistory);
  
  // Get dates from maintenance history
  const lastRecord = maintenanceHistory.records[0];
  const lastMaintenanceDate = lastRecord ? lastRecord.date : formatDate(new Date());
  
  // Calculate next maintenance based on recurring services
  const nextService = maintenanceHistory.recurringServices
    .reduce((earliest, service) => {
      return new Date(service.nextDue) < new Date(earliest) ? 
        service.nextDue : earliest;
    }, maintenanceHistory.recurringServices[0].nextDue);

  return {
    isDeadlined,
    operationalStatus,
    maintenanceLevel,
    lastMaintenanceDate,
    nextScheduledMaintenance: nextService,
    fuelLevel: Math.floor(Math.random() * 40) + 60 // 60-100%
  };
};

// const generateHQVehicles = (company: string, startIndex: number): Vehicle[] => {
//   const vehicles: Vehicle[] = [];

//   const platforms = {
//     'HHC': [
//       { type: 'AMPV Mission Command', category: 'Command Vehicle' },
//       { type: 'M88A2 HERCULES', category: 'Recovery Vehicle' },
//       { type: 'AMPV Medical Treatment', category: 'Support Vehicle' },
//       { type: 'AMPV Medical Evacuation', category: 'Support Vehicle' }
//     ],
//     'default': [
//       { type: UNIT_CONFIG.companies[company as CompanyKeys].platformType, category: UNIT_CONFIG.companies[company as CompanyKeys].category }
//     ]
//   };

//   const platformList = company === 'HHC' ? platforms.HHC : platforms.default;

  
//   platformList.forEach((platform, idx) => {
//     const maintenanceHistory = generateMaintenanceHistory(platform.category as PlatformCategory);
//     const status = generateStatus(maintenanceHistory);

//     vehicles.push({
//       id: generateVehicleId(company, startIndex + idx),
//       bumperNumber: generateBumperNumber(company, startIndex + idx),
//       registrationNumber: generateRegistration(startIndex + idx),
//       commandId: `HQ-${company}-1-67AR`,
//       unitAssignment: {
//         platoon: "Headquarters Platoon",
//         company: company,
//         battalion: "1-67",
//         brigade: "2nd BCT",
//         division: "1st Armored"
//       },
//       platform: platform.type,
//       platformCategory: platform.category as PlatformCategory,
//       platformType: company === 'HHC' ? 'Support Vehicle' : 'Combat Vehicle',
//       status: status,
//       maintenanceHistory: maintenanceHistory
//     });
//   });

//   return vehicles;
// };


const generateHQVehicles = (
  company: string, 
  battalion: string, 
  brigade: string, 
  startIndex: number
): Vehicle[] => {
  const vehicles: Vehicle[] = [];
  
  if (company === 'HHC') {
    let currentIndex = startIndex;
    
    const platoons = {
      'Headquarters Platoon': UNIT_CONFIG.hqPlatoonVehicles,
      'Medical Platoon': UNIT_CONFIG.medicalPlatoonVehicles,
      'Support Platoon': UNIT_CONFIG.supportPlatoonVehicles,
      'Maintenance Platoon': UNIT_CONFIG.maintenancePlatoonVehicles
    };

    Object.entries(platoons).forEach(([platoonName, platformList]) => {
      platformList.forEach(platform => {
        for (let i = 0; i < platform.count; i++) {
          const maintenanceHistory = generateMaintenanceHistory(platform.category as PlatformCategory);
          const status = generateStatus(maintenanceHistory);

          vehicles.push({
            id: generateVehicleId(company, battalion, brigade, currentIndex),
            bumperNumber: generateBumperNumber(company, battalion, currentIndex),
            registrationNumber: generateRegistration(brigade, battalion, currentIndex),
            commandId: `${platoonName.split(' ')[0]}-${company}-${battalion}-${brigade}`,
            unitAssignment: {
              platoon: platoonName,
              company: company,
              battalion: battalion,
              brigade: brigade,
              division: DIVISION_CONFIG.name
            },
            platform: platform.type,
            platformCategory: platform.category as PlatformCategory,
            platformType: 'Support Vehicle',
            status: status,
            maintenanceHistory: maintenanceHistory
          });
          currentIndex++;
        }
      });
    });
  } else {
    const platform = {
      type: UNIT_CONFIG.companies[company as CompanyKeys].platformType,
      category: UNIT_CONFIG.companies[company as CompanyKeys].category
    };

    for (let i = 0; i < 2; i++) {
      const maintenanceHistory = generateMaintenanceHistory(platform.category as PlatformCategory);
      const status = generateStatus(maintenanceHistory);

      vehicles.push({
        id: generateVehicleId(company, battalion, brigade, startIndex + i),
        bumperNumber: generateBumperNumber(company, battalion, startIndex + i),
        registrationNumber: generateRegistration(brigade, battalion, startIndex + i),
        commandId: `HQ-${company}-${battalion}-${brigade}`,
        unitAssignment: {
          platoon: "Company Headquarters",
          company: company,
          battalion: battalion,
          brigade: brigade,
          division: DIVISION_CONFIG.name
        },
        platform: platform.type,
        platformCategory: platform.category as PlatformCategory,
        platformType: 'Combat Vehicle',
        status: status,
        maintenanceHistory: maintenanceHistory
      });
    }
  }

  return vehicles;
};

// const generateCompanyVehicles = (company: string, startIndex: number): Vehicle[] => {
//   const vehicles: Vehicle[] = [];
  
//   // Generate HQ vehicles first
//   vehicles.push(...generateHQVehicles(company, startIndex));
  
//   // Generate platoon vehicles
//   for (let platoon = 1; platoon <= UNIT_CONFIG.platoonsPerCompany; platoon++) {
//     const platoonName = `${platoon}${platoon === 1 ? 'st' : platoon === 2 ? 'nd' : 'rd'} Platoon`;
    
//     for (let vic = 1; vic <= UNIT_CONFIG.vehiclesPerPlatoon; vic++) {
//       const vehicleNum = (platoon * 100) + vic;
//       const platformCategory = UNIT_CONFIG.companies[company as CompanyKeys].category as PlatformCategory;
//       const maintenanceHistory = generateMaintenanceHistory(platformCategory);
//       const status = generateStatus(maintenanceHistory);
      
//       vehicles.push({
//         id: generateVehicleId(company, vehicleNum),
//         bumperNumber: generateBumperNumber(company, vehicleNum),
//         registrationNumber: generateRegistration(startIndex + vehicleNum),
//         commandId: `${platoon}PLT-${company}-1-67AR`,
//         unitAssignment: {
//           platoon: platoonName,
//           company: company,
//           battalion: "1-67",
//           brigade: "2nd BCT",
//           division: "1st Armored"
//         },
//         platform: UNIT_CONFIG.companies[company as CompanyKeys].platformType,
//         platformCategory: platformCategory,
//         platformType: 'Combat Vehicle',
//         status: status,
//         maintenanceHistory: maintenanceHistory
//       });
//     }
//   }
  
//   return vehicles;
// };

const generateCompanyVehicles = (
  company: string, 
  battalion: string, 
  brigade: string, 
  startIndex: number
): Vehicle[] => {
  const vehicles: Vehicle[] = [];
  
  vehicles.push(...generateHQVehicles(company, battalion, brigade, startIndex));
  
  if (company !== 'HHC') {
    const numPlatoons = UNIT_CONFIG.platoonsPerLineCompany;
    const vehiclesPerPlatoon = UNIT_CONFIG.vehiclesPerLinePlatoon;
    
    for (let platoon = 1; platoon <= numPlatoons; platoon++) {
      const platoonName = `${platoon}${platoon === 1 ? 'st' : platoon === 2 ? 'nd' : 'rd'} Platoon`;
      
      for (let vic = 1; vic <= vehiclesPerPlatoon; vic++) {
        const vehicleNum = (platoon * 100) + vic;
        const platformCategory = UNIT_CONFIG.companies[company as CompanyKeys].category as PlatformCategory;
        const maintenanceHistory = generateMaintenanceHistory(platformCategory);
        const status = generateStatus(maintenanceHistory);
        
        vehicles.push({
          id: generateVehicleId(company, battalion, brigade, vehicleNum),
          bumperNumber: generateBumperNumber(company, battalion, vehicleNum),
          registrationNumber: generateRegistration(brigade, battalion, startIndex + vehicleNum),
          commandId: `${platoon}PLT-${company}-${battalion}-${brigade}`,
          unitAssignment: {
            platoon: platoonName,
            company: company,
            battalion: battalion,
            brigade: brigade,
            division: DIVISION_CONFIG.name
          },
          platform: UNIT_CONFIG.companies[company as CompanyKeys].platformType,
          platformCategory: platformCategory,
          platformType: 'Combat Vehicle',
          status: status,
          maintenanceHistory: maintenanceHistory
        });
      }
    }
  }
  
  return vehicles;
};

const generateBattalionVehicles = (
  battalion: string, 
  brigade: string, 
  startIndex: number
): Vehicle[] => {
  let vehicles: Vehicle[] = [];
  let companyStartIndex = startIndex;
  
  Object.keys(UNIT_CONFIG.companies).forEach(company => {
    const companyVehicles = generateCompanyVehicles(company, battalion, brigade, companyStartIndex);
    vehicles = [...vehicles, ...companyVehicles];
    companyStartIndex += 500;
  });
  
  return vehicles;
};

export const generateDivisionVehicles = (): Vehicle[] => {
  let vehicles: Vehicle[] = [];
  let startIndex = 1;
  
  DIVISION_CONFIG.brigades.forEach(brigade => {
    brigade.battalions.forEach(battalion => {
      const battalionVehicles = generateBattalionVehicles(
        battalion.name, 
        brigade.name, 
        startIndex
      );
      vehicles = [...vehicles, ...battalionVehicles];
      startIndex += 10000; // Large gap between battalions
    });
  });
  
  return vehicles;
};

export const fullBattalionVehicles = generateDivisionVehicles();