import "./ClassIxPlanningView.css"
import DataTable from "react-data-table-component"
import { formatToDollar } from "../../utils/utils"
import { useNavigate } from 'react-router-dom'

const ClassIxPlanningView = () => {
  const navigate = useNavigate()
  
  return (
    <div className="classix-planning-view">
      <h1>BUDGET SUMMARY</h1>

      <div className="table-container">
        <table>
          <tr>
            <td className="label">AVERAGE READINESS</td>
            <td className="value">87%</td>
          </tr>
          <tr>
            <td className="label">AVERAGE COST PER % READY</td>
            <td className="value">$343,000</td>
          </tr>
          {/* <tr>
            <td className="label">AVERAGE MONTHLY SPEND</td>
            <td className="value">$28,572</td>
          </tr> */}
        </table>

        <table>
          <tr>
            <td className="label">TOTAL 2024 BUDGET</td>
            <td className="value">$5,000,000</td>
          </tr>
          <tr>
            <td className="label">YTD SPENT</td>
            <td className="value text-red">($200,000)</td>
          </tr>
          <tr>
            <td className="label">REMAINING BUDGET</td>
            <td className="value">$4,800,000</td>
          </tr>
        </table>
      </div>

      <br/>
      <h1>TIME/COST TO READINESS SCENARIOS</h1>

      <DataTable
        columns={columns}
        data={planningData}
        pagination={false}
        responsive
        className='classix-planning-data-table'
        conditionalRowStyles={[
          {
            when: (row: ScenarioData) => row.readinessPercent === 91,
            style: { border: '1px solid var(--BrandYellow) !important' }
          },
        ]}
      />

      <div className='action-container'>
        <button className='btn-create-class-ix' onClick={() => navigate('/classix/list')}>CREATE BLOCK LIST</button>
      </div>

      
    </div>
  )
}

export default ClassIxPlanningView



// ----------------------------------------------------------------
// Sample Planning Data

interface ScenarioData {
  id: string
  projectedState: string
  readinessPercent: number
  projectedDays: number
  projectedCost: number
  remainingBudget: number
  actions: string
}

const columns = [
  {
    name: 'READINESS',
    selector: (row: ScenarioData) => row.readinessPercent,
    cell: (row: ScenarioData) => (
      <div style={{paddingBottom: '10px', paddingTop: '10px'}}>
        {row.projectedState === 'current' ? 
        <div className='neutral-value'>{row.readinessPercent}%</div> 
        :
        <div className='value'>{row.readinessPercent}%</div>
        }
        {row.projectedState === 'current' && <div className='neutral-label'>CURRENT</div>}
        {row.projectedState === 'recommended' && <div className='neutral-label'>RECOMMENDED*</div>}
      </div>
    ),
    sortable: false,
    width: 'auto',
  },
  {
    name: 'PROJECTED TIME (DAYS)',
    selector: (row: ScenarioData) => row.projectedDays,
    cell: (row: ScenarioData) => (
      <div>
        {row.projectedState === 'current' ? 
        <div className='neutral-value'>{row.projectedDays}</div> 
        :
        <div className='value'>{row.projectedDays}</div>
        }
        {row.projectedState === 'current' && <div className='neutral-label'>CURRENT</div>}
      </div>
    ),
    sortable: false,
    width: 'auto',
  },
  {
    name: 'PROJECTED COST TO READINESS',
    selector: (row: ScenarioData) => row.projectedCost,
    cell: (row: ScenarioData) => (
      <div>        
        {row.projectedState === 'current' ? 
        <div className='value text-red'>( - )</div>
        : 
        <div className='value text-red'>{`(${formatToDollar(row.projectedCost)})`}</div>
        }
      </div>
    ),
    sortable: false,
    width: 'auto',
  },
  {
    name: 'REMAINING BUDGET AT READINESS',
    selector: (row: ScenarioData) => row.remainingBudget,
    cell: (row: ScenarioData) => (
      <div>
        {row.projectedState === 'current' ? 
        <div className='neutral-value'>{formatToDollar(row.remainingBudget)}</div> 
        :
        <div className='value'>{formatToDollar(row.remainingBudget)}</div>
        }
        {row.projectedState === 'current' && <div className='neutral-label'>CURRENT</div>}
      </div>
    ),
    sortable: false,
    width: 'auto',
  },
  // {
  //   name: 'MAINTENANCE ACTIONS REQUIRED',
  //   selector: (row: ScenarioData) => row.actions,
  //   cell: (row: ScenarioData) => (
  //     <div className="action" style={{textAlign: 'left'}}>
  //       {row.actions}
  //     </div>
  //   ),
  //   sortable: false,
  //   width: 'auto',
  // },
]


const planningData: ScenarioData[] = [
  {
    id: '1',
    projectedState: 'current',
    readinessPercent: 85,
    projectedDays: 0,
    projectedCost: 0,
    remainingBudget: 4800000,
    actions: '0 actions required',
  },
  {
    id: '2',
    projectedState: '',
    readinessPercent: 87,
    projectedDays: 5,
    projectedCost: 95000,
    remainingBudget: 4705000,
    actions: '25 actions required',
  },
  {
    id: '3',
    projectedState: '',
    readinessPercent: 89,
    projectedDays: 10,
    projectedCost: 240000,
    remainingBudget: 4560000,
    actions: '55 actions required',
  },
  {
    id: '4',
    projectedState: 'recommended',
    readinessPercent: 91,
    projectedDays: 21,
    projectedCost: 480000,
    remainingBudget: 4320000,
    actions: '100 actions required',
  },
  {
    id: '5',
    projectedState: '',
    readinessPercent: 93,
    projectedDays: 45,
    projectedCost: 920000,
    remainingBudget: 3880000,
    actions: '160 actions required',
  },
  {
    id: '6',
    projectedState: '',
    readinessPercent: 95,
    projectedDays: 90,
    projectedCost: 1600000,
    remainingBudget: 3200000,
    actions: '280 actions required',
  },
  {
    id: '7',
    projectedState: '',
    readinessPercent: 97,
    projectedDays: 120,
    projectedCost: 2600000,
    remainingBudget: 2200000,
    actions: '380 actions required',
  },
  {
    id: '8',
    projectedState: '',
    readinessPercent: 98,
    projectedDays: 180,
    projectedCost: 3600000,
    remainingBudget: 1200000,
    actions: '480 actions required',
  },
  // {
  //   id: '9',
  //   projectedState: '',
  //   readinessPercent: 99,
  //   projectedDays: 365,
  //   projectedCost: 4800000,
  //   remainingBudget: 0,
  //   actions: '600 maintenance actions required',
  // },
]

// ----------------------------------------------------------------