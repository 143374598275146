import { Vehicle } from "./vehicleData6";

// ------------------------------------------------------------------------------------------------
// Vehicle Data Retrieval
// ------------------------------------------------------------------------------------------------

/**
 * Retrieves a vehicle object by its ID from the vehicleData array
 * @param {string} id - The ID of the vehicle to find
 * @returns {Object|null} - Returns the vehicle object if found, null otherwise
 */
export const getVehicleById = (vehicleData: Vehicle[], id: string) => {
  return vehicleData.find(vehicle => vehicle.id === id) || null;
}




// ------------------------------------------------------------------------------------------------
// Vehicle Data Transformation (get vehicle list)
// ------------------------------------------------------------------------------------------------

export interface TransformedVehicle {
  id: string;
  platform: string;
  bumperNumber: string;
  registrationNumber: string;
  operationalStatus: string;
  deadlinedStatus: 'Yes' | 'No';
  maintenanceLevel: string;
  lastMaintenanceDate: string;
  nextMaintenanceDate: string;
  battalion: string;
  brigade: string;
  company: string;
  division: string;
  platoon: string;
  actions: string;
}

/**
 * Transforms vehicle data into a flat list of objects with selected fields
 * @param {Array} vehicles - Array of vehicle objects
 * @returns {Array} Flattened array of vehicle objects with selected fields
 */
export const vehicleList = (vehicles: Vehicle[]): TransformedVehicle[] => {
  return vehicles.map(vehicle => ({
    id: vehicle.id,
    platform: vehicle.platform,
    bumperNumber: vehicle.bumperNumber,
    registrationNumber: vehicle.registrationNumber,
    operationalStatus: vehicle.status.operationalStatus,
    deadlinedStatus: vehicle.status.isDeadlined ? 'Yes' : 'No',
    maintenanceLevel: vehicle.status.maintenanceLevel,
    lastMaintenanceDate: vehicle.status.lastMaintenanceDate,
    nextMaintenanceDate: vehicle.status.nextScheduledMaintenance,
    battalion: vehicle.unitAssignment?.battalion || '',
    brigade: vehicle.unitAssignment?.brigade || '',
    company: vehicle.unitAssignment?.company || '',
    division: vehicle.unitAssignment?.division || '',
    platoon: vehicle.unitAssignment?.platoon || '',
    actions: ''
  }));
}




// ------------------------------------------------------------------------------------------------
// Platform Types
// ------------------------------------------------------------------------------------------------

/**
 * Extracts unique platform types from vehicle data
 * @param vehicles - Array of vehicle objects
 * @returns Array of unique platform type strings
 */
export const getPlatforms = (vehicles: Vehicle[]): string[] => {
  // Use Set to automatically handle duplicates
  const uniquePlatforms = new Set<string>();
  
  vehicles.forEach(vehicle => {
      if (vehicle.platformType) {
        uniquePlatforms.add(vehicle.platform);
      }
  });
  
  // Convert Set back to array and sort alphabetically
  return Array.from(uniquePlatforms).sort();
}



// ------------------------------------------------------------------------------------------------
// Fleet Data Aggregation
// ------------------------------------------------------------------------------------------------


// Types
// ------------------------------------------------------------------------------------------------

export type CommandLevel = 'corps' | 'division' | 'brigade' | 'battalion' | 'company' | 'platoon';

const COMMAND_LEVELS: { [key: string]: CommandLevel } = {
  CORPS: "corps",
  DIVISION: "division",
  BRIGADE: "brigade",
  BATTALION: "battalion",
  COMPANY: "company",
  PLATOON: "platoon"
} as const;

export interface VehicleData {
  id: string;
  commandId: string;
  status: {
    operationalStatus: 'FMC' | 'PMC' | 'NMCM' | 'NMCS';
  };
  unitAssignment: {
    [key in CommandLevel]?: string;
  };
}

interface SubUnitStats {
  name: string;
  commandId: string;
  vehicleCount: number;
  operationalCount: number;
  deadlinedCount: number;
  fmcCount: number;
  pmcCount: number;
  nmcmCount: number;
  nmcsCount: number;
}

interface SummaryStats extends SubUnitStats {
  operationalPercent: number;
  deadlinedPercent: number;
  fmcPercent: number;
  pmcPercent: number;
  nmcmPercent: number;
  nmcsPercent: number;
}

interface GroupedVehicle {
  commandLevel: CommandLevel;
  name: string;
  subUnits: {
    [key: string]: SubUnitStats;
  };
}

interface FleetDataResult {
  commandLevel: CommandLevel;
  name: string;
  summary: SummaryStats[];
}

export type OperationStatus = 'FMC' | 'PMC' | 'NMC' | 'NMCM' | 'NMCS' | 'Deadlined'

export interface VehicleOperationState {
  selectedStatuses: OperationStatus[];
  setSelectedStatuses: (statuses: OperationStatus[]) => void;
}

// Helper Functions
// ------------------------------------------------------------------------------------------------

export function getChildCommandLevel(commandLevel: string): string {
  const commandLevels = ['corps', 'division', 'brigade', 'battalion', 'company', 'platoon'];
  
  const normalizedInput = commandLevel.toLowerCase();
  
  // Find the index of the input command level
  const currentIndex = commandLevels.indexOf(normalizedInput);
  
  // Return error if command level not found or is already at lowest level
  if (currentIndex === -1) {
      throw new Error('Invalid command level');
  }
  if (currentIndex === commandLevels.length - 1) {
      throw new Error('No child command level exists');
  }
  // Return the next command level in the hierarchy
  return commandLevels[currentIndex + 1];
}

export const getNextLevel = (commandLevel: CommandLevel): CommandLevel | null => {
  const levels = Object.values(COMMAND_LEVELS);
  const currentIndex = levels.indexOf(commandLevel);
  return currentIndex < levels.length - 1 ? levels[currentIndex + 1] : null;
};

export const isOperational = (vehicle: VehicleData): boolean => {
  return vehicle.status.operationalStatus === 'FMC' || 
         vehicle.status.operationalStatus === 'PMC';
};

export const isDeadlined = (vehicle: VehicleData): boolean => {
  return vehicle.status.operationalStatus === 'NMCM' || 
         vehicle.status.operationalStatus === 'NMCS';
};

export const roundPercent = (value: number, total: number): number => {
  return Math.round((value / total) * 100);
};

const getCommandId = (vehicle: VehicleData, commandLevel: CommandLevel): string => {
  switch (commandLevel) {
    case 'battalion':
      return vehicle.commandId.split('-').slice(1).join('-'); // e.g., "A-1-67AR" from "1PLT-A-1-67AR"
    case 'company':
      return vehicle.commandId; // e.g., "1PLT-A-1-67AR"
    // Add other cases as needed
    default:
      return vehicle.commandId;
  }
};


// Fleet Data Aggregation
// ------------------------------------------------------------------------------------------------

export const aggregateFleetData = (vehicles: VehicleData[], commandLevel: CommandLevel): FleetDataResult[] => {
  if (!Object.values(COMMAND_LEVELS).includes(commandLevel)) {
    throw new Error(`Invalid command level. Must be one of: ${Object.values(COMMAND_LEVELS).join(", ")}`);
  }

  const groupByLevel = getNextLevel(commandLevel);
  if (!groupByLevel) {
    throw new Error(`No lower level available for ${commandLevel}`);
  }

  // Group vehicles by the specified command level
  const groupedVehicles = vehicles.reduce<{ [key: string]: GroupedVehicle }>((acc, vehicle) => {
    const key = vehicle.unitAssignment[commandLevel];
    const subUnit = vehicle.unitAssignment[groupByLevel];
    
    if (!key || !subUnit) return acc;
    
    if (!acc[key]) {
      acc[key] = {
        commandLevel,
        name: key,
        subUnits: {}
      };
    }
    
    if (!acc[key].subUnits[subUnit]) {
      // Get all vehicles in this subUnit
      const subUnitVehicles = vehicles.filter(v => 
        v.unitAssignment[groupByLevel] === subUnit &&
        v.unitAssignment[commandLevel] === key
      );
      
      // Get the commandId from the first vehicle in this subUnit
      const commandId = subUnitVehicles[0] ? getCommandId(subUnitVehicles[0], commandLevel) : '';

      acc[key].subUnits[subUnit] = {
        name: subUnit,
        commandId,
        vehicleCount: 0,
        operationalCount: 0,
        deadlinedCount: 0,
        fmcCount: 0,
        pmcCount: 0,
        nmcmCount: 0,
        nmcsCount: 0
      };
    }

    const subUnitData = acc[key].subUnits[subUnit];
    
    subUnitData.vehicleCount++;
    
    switch (vehicle.status.operationalStatus) {
      case 'FMC':
        subUnitData.fmcCount++;
        subUnitData.operationalCount++;
        break;
      case 'PMC':
        subUnitData.pmcCount++;
        subUnitData.operationalCount++;
        break;
      case 'NMCM':
        subUnitData.nmcmCount++;
        subUnitData.deadlinedCount++;
        break;
      case 'NMCS':
        subUnitData.nmcsCount++;
        subUnitData.deadlinedCount++;
        break;
    }

    return acc;
  }, {});

  // Transform into array format
  return Object.entries(groupedVehicles).map(([, data]): FleetDataResult => {
    const summary = Object.entries(data.subUnits).map(([subUnitName, stats]): SummaryStats => {
      return {
        name: subUnitName,
        commandId: stats.commandId,
        vehicleCount: stats.vehicleCount,
        operationalCount: stats.operationalCount,
        operationalPercent: roundPercent(stats.operationalCount, stats.vehicleCount),
        deadlinedCount: stats.deadlinedCount,
        deadlinedPercent: roundPercent(stats.deadlinedCount, stats.vehicleCount),
        fmcCount: stats.fmcCount,
        fmcPercent: roundPercent(stats.fmcCount, stats.vehicleCount),
        pmcCount: stats.pmcCount,
        pmcPercent: roundPercent(stats.pmcCount, stats.vehicleCount),
        nmcmCount: stats.nmcmCount,
        nmcmPercent: roundPercent(stats.nmcmCount, stats.vehicleCount),
        nmcsCount: stats.nmcsCount,
        nmcsPercent: roundPercent(stats.nmcsCount, stats.vehicleCount)
      };
    });

    return {
      commandLevel: data.commandLevel,
      name: data.name,
      summary: summary
    };
  });
};

// Example usage:
// const exampleVehicles = [/* your vehicle array */];
// const report = aggregateFleetData(exampleVehicles, COMMAND_LEVELS.BATTALION);
// console.log(JSON.stringify(report, null, 2));


// ------------------------------------------------------------------------------------------------
// Maintenance status stats
// ------------------------------------------------------------------------------------------------

/**
 * Calculates maintenance statistics for a collection of vehicles
 * @param {Array} vehicles - Array of vehicle objects
 * @returns {Object} Object containing maintenance statistics
 */
export function calculateMaintenanceStats(vehicles: Vehicle[]) {
  const totalVehicles = vehicles.length;
  
  // Initialize counters
  const stats = {
      deadlinedCount: 0,
      deadlinedPercent: 0,
      fmcCount: 0,
      fmcPercent: 0,
      pmcCount: 0,
      pmcPercent: 0,
      nmcmCount: 0,
      nmcmPercent: 0,
      nmcsCount: 0,
      nmcsPercent: 0,
      operationalCount: 0,
      operationalPercent: 0
  };

  // Count occurrences
  vehicles.forEach(vehicle => {
      // Check deadline status
      if (vehicle.status.isDeadlined) {
          stats.deadlinedCount++;
      }

      // Check operational status
      switch (vehicle.status.operationalStatus) {
          case 'FMC':
              stats.fmcCount++;
              stats.operationalCount++; // FMC counts as operational
              break;
          case 'PMC':
              stats.pmcCount++;
              stats.operationalCount++; // PMC counts as operational
              break;
          case 'NMCM':
              stats.nmcmCount++;
              break;
          case 'NMCS':
              stats.nmcsCount++;
              break;
      }
  });

  // Calculate percentages
  if (totalVehicles > 0) {
      stats.deadlinedPercent = (stats.deadlinedCount / totalVehicles) * 100;
      stats.fmcPercent = (stats.fmcCount / totalVehicles) * 100;
      stats.pmcPercent = (stats.pmcCount / totalVehicles) * 100;
      stats.nmcmPercent = (stats.nmcmCount / totalVehicles) * 100;
      stats.nmcsPercent = (stats.nmcsCount / totalVehicles) * 100;
      stats.operationalPercent = (stats.operationalCount / totalVehicles) * 100;
  }

  // Round all percentages to 2 decimal places
  Object.keys(stats).forEach(key => {
      if (key.endsWith('Percent')) {
          stats[key as keyof typeof stats] = Math.round(stats[key as keyof typeof stats] * 100) / 100;
      }
  });

  return stats;
}




export interface FaultStats {
    totalFaults: number;
    brakesFaults: number;
    drivelineFaults: number;
    electricalFaults: number;
    engineFaults: number;
    transmissionFaults: number;
}

/**
 * Analyzes maintenance records and returns statistics about system faults
 * @param {Object} data - The maintenance records data object
 * @returns {Object} Statistics about maintenance records
 */
export function getMaintenanceFaultsStats(vehicleData: any) {
  console.log('vehicleData: ', vehicleData)
  // Initialize counters
  const stats = {
    totalFaults: 0,
    brakeFaults: 0,
    drivelineFaults: 0,
    electricalFaults: 0,
    engineFaults: 0,
    transmissionFaults: 0
  };

  // Safely access maintenance records
  const records = vehicleData?.maintenanceHistory?.records || [];

  // Count faults by type
  records.forEach((record: any) => {
    if (record.faults) {
      stats.totalFaults += record.faults.length;
      
      if (record.subsystem === 'Brakes') {
        stats.brakeFaults += record.faults.length;
      } else if (record.subsystem === 'Driveline') {
        stats.drivelineFaults += record.faults.length;
      } else if (record.subsystem === 'Electrical') {
        stats.electricalFaults += record.faults.length;
      } else if (record.subsystem === 'Engine') {
        stats.engineFaults += record.faults.length;
      } else if (record.subsystem === 'Transmission') {
        stats.transmissionFaults += record.faults.length;
      }
    }
  });

  return stats;
}