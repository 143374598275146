import "./SummaryMetricCard.css"
import Plot from 'react-plotly.js'
import { Layout, Data } from 'plotly.js';

type Props = {
  fmcCount: number;
  fmcPercent: number;
  pmcCount: number;
  pmcPercent: number;
  nmcmCount: number;
  nmcmPercent: number;
  nmcsCount: number;
  nmcsPercent: number;
}

const SummaryMetricCard = ({ 
  fmcCount,
  fmcPercent,
  pmcCount, 
  pmcPercent,
  nmcmCount, 
  nmcmPercent,
  nmcsCount, 
  nmcsPercent }: Props) => {

  const PieChart: React.FC<Props> = ({ fmcPercent, pmcPercent, nmcmPercent, nmcsPercent }) => {
    const chartDataArray: Data[] = [{
      type: 'pie',
      labels: ['FMC', 'PMC', 'NMCM', 'NMCS'],
      values: [fmcPercent, pmcPercent, nmcmPercent, nmcsPercent],
      showlegend: false,
      marker: {
        colors: ['#76252F', '#968917', '#246488', '#137229']  // First value will be green, second will be darker green
      },
      textinfo: 'none'
    }]

    const layout: Partial<Layout> = {
      paper_bgcolor: 'rgba(0,0,0,0)',
      plot_bgcolor: 'rgba(0,0,0,0)',
      showlegend: false,
      margin: { t: 0, b: 0, l: 0, r: 0 },
      width: 120,
      height: 120,
    }

    return (
      <Plot
        data={chartDataArray}
        layout={layout}
        config={{
          displayModeBar: false,
          staticPlot: true,
          responsive: true,
        }}
      />
    )
  }

  return (
    <div className="summary-metric-card">
      <p className="title">Readiness Summary</p>
      <div className="content">
        <div className="pie-chart">
          <PieChart 
            fmcPercent={fmcPercent} 
            fmcCount={fmcCount}
            pmcPercent={pmcPercent} 
            pmcCount={pmcCount}
            nmcmPercent={nmcmPercent} 
            nmcmCount={nmcmCount}
            nmcsPercent={nmcsPercent} 
            nmcsCount={nmcsCount}
          />
        </div>
        <div>
          <div>
            <span className="chip chip-bg-red">{fmcCount}</span>
            <span className="label">FMC</span>
            <span className="value">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {fmcPercent}%
            </span>
          </div>
          <br/>
          <div>
            <span className="chip chip-bg-yellow">{pmcCount}</span>
            <span className="label">PMC</span>
            <span className="value">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {pmcPercent}%
            </span>
          </div>
          <br/>
          <div>
            <span className="chip chip-bg-blue">{nmcmCount}</span>
            <span className="label">NMCM</span>
            <span className="value">
              &nbsp;&nbsp; {nmcmPercent}%
            </span>
          </div>
          <br/>
          <div>
            <span className="chip chip-bg-green">{nmcsCount}</span>
            <span className="label">NMCS</span>
            <span className="value">
              &nbsp;&nbsp;&nbsp;&nbsp; {nmcsPercent}%
            </span>
          </div>
        </div>
      </div>
      <div className="action"></div>
    </div>
  )
}

export default SummaryMetricCard
