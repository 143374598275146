import { useState } from 'react';

interface PlatformImageProps {
  platform: string;
  width?: number;
}

const PlatformImage = ({ platform, width = 100 }: PlatformImageProps) => {
  const [imageError, setImageError] = useState(false);
  
  // For Vite, use the base URL from import.meta
  // const imagePath = `${import.meta.env.BASE_URL}/images/${platform.replace(/ /g, '-')}.png`;
  // const imagePath = `./assets/images/${platform.replace(/ /g, '-')}.png`;
  const imagePath = new URL(`./images/${platform.replace(/ /g, '-')}.png`, window.location.origin).href;


  return (
    <div className="platform">
      {!imageError ? (
        <img
          src={imagePath}
          alt={platform}
          width={width}
          onError={() => setImageError(true)}
        />
      ) : (
        ''
      )}
      <div className="platform-name">{platform}</div>
    </div>
  );
};

export default PlatformImage;