import "./ClassIxViewNavbar.css"
import { useParams, useNavigate } from "react-router-dom"


interface Props {
  defaultTab?: string
}

const ClassIxViewNavbar = ({ defaultTab = 'Readiness' }: Props) => {
  const { tab } = useParams()
  const navigate = useNavigate()

  const tabMapping: { [key: string]: string } = {
    'planning': 'Class IX Planning',
    'list': 'Class IX Block List'
  }
  const activeTab = tabMapping[tab?.toLowerCase() ?? ""] || tab || defaultTab

  const tabs = [
    "Class IX Planning",
    "Class IX Block List"
  ]

  const handleTabChange = (tab: string) => {
    const urlTab =
      Object.entries(tabMapping).find(([_, value]) => value === tab)?.[0] ||
      tab.toLowerCase().replace(" ", "-")
    navigate(`/classix/${urlTab}`)
  }

  return (
    <div className="classix-view-navbar">
      <div className="container">
        {tabs.map((tab) => (
          <button
            key={tab}
            className={`${activeTab === tab ? "active-tab" : "inactive-tab"}`}
            onClick={() => handleTabChange(tab)}
          >
            {tab}
          </button>
        ))}
        <button className="placeholder-tab" disabled></button>
      </div>
    </div>
  )
}

export default ClassIxViewNavbar
