import "./ReadinessMetricCard.css"
import { PieChart } from '../charts/PieCharTwoLabels'

const ReadinessMetricCard = ({ readinessPercent, deadlinedPercent }: 
  { readinessPercent: number; deadlinedPercent: number; }) => {

  return (
    <div className="readiness-metric-card">
      <p className="title">Current Readiness</p>
      <div className="content">
        <div>
          <PieChart 
            value1={readinessPercent} 
            value2={deadlinedPercent} 
            status={'good'}
            width={100}
            height={100} />
          <div className="percent">&nbsp;</div>
        </div>
        <div className="value">{readinessPercent}%</div>
      </div>
      <div className="action"></div>
    </div>
  )
}

export default ReadinessMetricCard
