import './MaintainerVehicleDetail.css'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Navbar from '../components/Navbar'
import PlatformImage from '../components/UnitViews/PlatformImage'
import MaintainerVehicleDetailViewNavbar from '../maintainer/MaintainerVehicleDetailViewNavbar'

import { depotData } from '../data/staticDepotData'
import { Vehicle as VehicleData } from '../data/vehicleData6'
import MaintainerMetricPanel from '../maintainer/MaintainerMetricPanel'
import SuggestedMaintenanceTable from '../maintainer/SuggestedMaintenanceTable'
import ServiceHistoryDataTable from '../components/VehicleDetailViews/ServiceHistoryDataTable'

import { getMaintenanceFaultsStats, FaultStats } from '../data/dataUtil'



const MaintainerVehicleDetail = () => {
  const { id } = useParams()
  const [vehicle, setVehicle] = useState<VehicleData | null>(null)
  const [faultStats, setFaultStats] = useState<any>(null)

  const handleBackClick = () => {
    window.history.back()
  }

  useEffect(() => {
    // @ts-ignore
    const fetchedVehicle = getVehicleById(depotData, id ?? '')
    setVehicle(fetchedVehicle)
    setFaultStats(getMaintenanceFaultsStats(fetchedVehicle))
    console.log('Fault stats: ', getMaintenanceFaultsStats(fetchedVehicle))
  }, [id])

  const getVehicleById = (depotData: VehicleData[], id: string) => {
    const vehicle = depotData.find(vehicle => vehicle.id === id)
    console.log(vehicle)
    return vehicle || null;
  }

  return (
    <div className='maintainer-vehicle-detail-page'>
      <Navbar />

      <div className="data-container">

        <div className='side-view'>
          <span className='go-back brand-yellow' onClick={handleBackClick}>
            <i className="icon-chevron-left"></i>
            &nbsp;&nbsp; 
            GO BACK
          </span>
          
          <p className='vehicle-details-label'>VEHICLE DETAILS</p>

          <div className='platform-image'>
            <PlatformImage platform={vehicle?.platform ?? ''} width={160} />        
          </div>

          <div className='vehicle-title-panel'>
            <span className='label-1'>BUMPER # {vehicle?.bumperNumber}</span>
            <br/>
            <span className='label-2'>{vehicle?.commandId}</span>
          </div>

          <br/>

          <div className={`vehicle-status-card ${vehicle?.status.operationalStatus === 'PMC' ? 'status-orange' : ''} ${vehicle?.status.isDeadlined ? 'status-red' : ''}`}>
            <div className="content">
              <div>
                <div className="row">
                  <span className="value">
                  STATUS: {vehicle?.status.operationalStatus}
                  </span>
                </div>
                <br/>
                {vehicle?.status.isDeadlined && (
                  <div className="row">
                    <span className="deadlined-label">(Deadlined)</span>
                  </div>
                )}
              </div>
            </div>
          </div>

        </div>

        
        <div >
          {faultStats && <MaintainerVehicleDetailViewNavbar defaultTab="Service Request History" />}

          <div className='content-container'>
            {faultStats && <MaintainerMetricPanel faultStats={faultStats as FaultStats} />}
          
            <div className='main-view'>
              {vehicle ? <ServiceHistoryDataTable vehicleData={vehicle} /> : <p></p>}
            </div>

            <div className='suggested-maintenance-panel'>
              <h1>SUGGESTED MAINTENANCE</h1>
              <p style={{ color: '#efefef' }}>Our model prioritizes the following maintenance requirements to optimize maintenance costs and prevent potential failures.</p>

              <SuggestedMaintenanceTable /> 
            </div>

          </div>
        </div>
        
      </div>
    </div>
  )
}

export default MaintainerVehicleDetail