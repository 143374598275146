import './TopFilterPanel.css'
import { useContext } from 'react'
import AppContext from '../../context/AppContext'

const TopFilterPanel = ({ 
  selectedCommandLevel, setSelectedCommandLevel }: { selectedCommandLevel: string, 
  setSelectedCommandLevel: (value: string) => void }) => {
    
  const { commandData } = useContext(AppContext)

  function handleCommandLevelChange(e: React.ChangeEvent<HTMLSelectElement>) {
    if (e.target.value === '') return; // Return empty if the value is empty
    setSelectedCommandLevel(e.target.value)
  }

  return (
    <div className="top-filter-container">
      <div className="filters-column">
        <select value={selectedCommandLevel} onChange={handleCommandLevelChange} style={{ width: '320px' }}>
          {/* <option value="">- Select a Command -</option> */}
          {commandData.map((command, index) => (
            command.id !== 'Platoon' && command.id !== 'Corps' && (
              <option 
                value={command.id} 
                key={index}>
                  {command.level}
              </option>
            )
          ))}
        </select>
      </div>
    </div>
  )
}

export default TopFilterPanel
