import "./ClassIxBlockListView.css"



const ClassIxDetailView = () => {
  return (
    <div className="classix-block-list-view">

      <div className="info-container">
        <h1>1-82 Cavalry Battalion Class IX Block List</h1>
        <p>Status as of: December 2024</p>

        <div className="summary-container">
          <p>Summary:</p>

          <ul>
            <li>Total Vehicles: 87</li>
            <li>Total Critical Items (RED Status): 3</li>
            <li>Total Warning Items (YELLOW Status): 4</li>
            <li>Total Parts On Order: 9</li>
            <li>Most Common Shortage: Batteries and CTIS Components</li>
            <li>Highest Priority Need: Engine Cylinder Head for 2nd Platoon Alpha Company</li>
          </ul>
        </div>
      </div>

      <br/>


      {/* ALPHA COMPANY */}
      
      <div className="info-container">      
        <h2>Alpha Company (28 Vehicles)</h2>
        <h3>1st Platoon (8x HMMWV, 2x MRAP)</h3>

        <div className="parts-list">
          <h4>High Priority Parts:</h4>
          <ul>
            <li>
              <p>NSN: 2520-01-456-3091 | Transmission Assembly, HMMWV <br/>
              Qty On Hand: 2 | Min Stock: 2 | Reorder Point: 1 <br/>
              Status: GREEN</p>
            </li>
            <li>
              <p>
                NSN: 2815-01-589-4724 | Fuel Injection Pump, MRAP <br/>
                Qty On Hand: 4 | Min Stock: 3 | Reorder Point: 2 <br/>
                Status: GREEN
              </p>
            </li>
          </ul>

          <h4>Medium Priority Parts:</h4>
          <ul>
            <li>
              <p>NSN: 2530-01-459-2227 | Shock Absorber Assembly <br/>
              Qty On Hand: 16 | Min Stock: 12 | Reorder Point: 8 <br/>
              Status: YELLOW (2 Pending Requisition)</p>
            </li>
          </ul>
        </div>

        <h3>2nd Platoon (8x HMMWV, 2x M1078 LMTV)</h3>

        <div className="parts-list">
          <h4>High Priority Parts:</h4>
          <ul>
            <li>
              <p>
                NSN: 2815-01-413-7542 | Engine Cylinder Head, LMTV <br/> 
                Qty On Hand: 1 | Min Stock: 2 | Reorder Point: 1 <br/>
                Status: RED (1 Ordered - ETA 5 Days)
              </p>
            </li>
          </ul>
        </div>

        <h3>3rd Platoon (8x HMMWV)</h3>

        <div className="parts-list">
          <h4>Critical Shortages:</h4>
          <ul>
            <li>
              <p>
                NSN: 6140-01-485-1472 | Battery, Storage <br/>
                Qty On Hand: 4 | Min Stock: 8 | Reorder Point: 6 <br/>
                Status: RED (4 On Order)
              </p>
            </li>
          </ul>
        </div>

      </div>

      <br/>

      {/* BRAVO COMPANY */}

      <div className="info-container">
        <h2>Bravo Company (28 Vehicles)</h2>
        <h3>1st Platoon (6x M1078 LMTV, 4x HEMTT)</h3>

        <div className="parts-list">
          <h4>High Priority Parts:</h4>
          <ul>
            <li>
              <p>NSN: 2530-01-493-1823 | Axle Assembly, HEMTT <br/>
              Qty On Hand: 2 | Min Stock: 2 | Reorder Point: 1 <br/>
              Status: GREEN</p>
            </li>
          </ul>
        </div>

        <h3>2nd Platoon (6x M1078 LMTV, 4x HEMTT)</h3>

        <div className="parts-list">
          <h4>Medium Priority Parts:</h4>
          <ul>
            <li>
              <p>
                NSN: 4910-01-479-1236 | Hydraulic Winch Assembly <br/>
                Qty On Hand: 3 | Min Stock: 4 | Reorder Point: 2 <br/>
                Status: YELLOW
              </p>
            </li>
          </ul>
        </div>

        <h3>3rd Platoon (8x M1078 LMTV)</h3>

        <div className="parts-list">
          <ul>
            <li>
              <p>
              None Currently Reported
              </p>
            </li>
          </ul>
        </div>
      </div>

      <br/>

      {/* CHARLIE COMPANY */}

      <div className="info-container">      
        <h2>Charlie Company (25 Vehicles)</h2>
        <h3>1st Platoon (9x M939 Series)</h3>

        <div className="parts-list">
          <h4>High Priority Parts:</h4>
          <ul>
            <li>
              <p>
                NSN: 2530-01-475-2964 | Brake Master Cylinder <br/>
                Qty On Hand: 5 | Min Stock: 6 | Reorder Point: 4 <br/>
                Status: YELLOW
              </p>
            </li>
          </ul>
        </div>

        <h3>2nd Platoon (8x M939 Series)</h3>

        <div className="parts-list">
          <h4>Medium  Priority Parts:</h4>
          <ul>
            <li>
              <p>
                NSN: 6130-01-523-6995 | Alternator Assembly <br/>
                Qty On Hand: 12 | Min Stock: 8 | Reorder Point: 6 <br/>
                Status: GREEN
              </p>
            </li>
          </ul>
        </div>

        <h3>3rd Platoon (8x M939 Series)</h3>

        <div className="parts-list">
          <h4>Critical Shortages:</h4>
          <ul>
            <li>
              <p>
                NSN: 2540-01-498-1453 | CTIS Components <br/>
                Qty On Hand: 2 | Min Stock: 6 | Reorder Point: 4 <br/>
                Status: RED (4 On Order)
              </p>
            </li>
          </ul>
        </div>
      </div>
      
      <br/>


      {/* HHC*/}

      {/* CHARLIE COMPANY */}

      <div className="info-container">      
        <h2>HHC (6 Vehicles)</h2>

        <div className="parts-list">
          <h4>High Priority Parts:</h4>
          <ul>
            <li>
              <p>
                NSN: 2520-01-495-3255 | Transfer Case <br/>
                Qty On Hand: 1 | Min Stock: 2 | Reorder Point: 1 <br/>
                Status: YELLOW
              </p>
            </li>
          </ul>
        </div>
      </div>

    </div>
  )
}

export default ClassIxDetailView