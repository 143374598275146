import Plot from 'react-plotly.js'
import { Layout, Data } from 'plotly.js'


interface PieChartProps {
  value1: number;
  value2: number;
  status: string;
  width: number;
  height: number;
}

export const PieChart: React.FC<PieChartProps> = ({
  value1, value2, status, width, height }) => {

  let colors: string[] = []
  switch (status) {
    case 'good':
      colors = ['#2b7628', '#2b523a']
      break
    case 'bad':
      colors = ['#8b0c15', '#D3000D']
      break
    default:
      // do nothing
  }

  const chartDataArray: Data[] = [{
    type: 'pie',
    labels: ['Readiness', 'Deadlined'],
    values: [value1, value2],
    showlegend: false,
    marker: {
      colors: colors
    },
    textinfo: 'none'
  }]

  const layout: Partial<Layout> = {
    paper_bgcolor: 'rgba(0,0,0,0)',
    plot_bgcolor: 'rgba(0,0,0,0)',
    showlegend: false,
    margin: { t: 0, b: 0, l: 0, r: 0 },
    width: width,
    height: height,
    // pad: 2
  }

  return (
    <Plot
      data={chartDataArray}
      layout={layout}
      config={{
        displayModeBar: false,
        staticPlot: true,
        responsive: true,
      }}
    />
  )
}