import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { AppContextProvider } from './context/AppContextProvider'
import Fleet from './pages/Fleet'
import Info from './pages/Info'
import VehicleDetail from './pages/VehicleDetail.tsx'
import Maintainer from './pages/Maintainer.tsx'
import { commandLevels }  from './data/commandData.ts'
import MaintainerVehicleDetail from './pages/MaintainerVehicleDetail.tsx'
import ClassIx from './pages/ClassIx.tsx'

// Vehicle Data is generated each time the app mount
import { fullBattalionVehicles, generateDivisionVehicles, Vehicle } from './data/vehicleData6.ts'
import { getPlatforms } from './data/dataUtil.ts'
import Login from './pages/Login.tsx'

// TODO: Once the data is accurate, generate and save to staticVehicleData.ts and use staticVehicleData.ts
// import { vehicleData } from './data/staticVehicleData.ts'


function App() { 
  const vehiclePlatforms: string[] = getPlatforms(generateDivisionVehicles() as Vehicle[])
  const defaultCommandLevel = 'Battalion'

  const router = createBrowserRouter([
    { path: '/', element: <Login /> },
    // { path: '/', element: <Fleet defaultTab="Readiness" /> },
    { path: '/command/fleet', element: <Fleet defaultTab="Readiness" /> },
    { path: '/command/fleet/:tab', element: <Fleet /> },
    { path: '/command/vehicle-list', element: <Fleet defaultTab="Vehicle List" /> },
    { path: '/command/vehicle/detail/:id', element: <VehicleDetail /> },
    { path: '/maintainer/board', element: <Maintainer /> },
    { path: '/maintainer/vehicle/detail/:id', element: <MaintainerVehicleDetail /> },
    { path: '/info', element: <Info /> },
    { path: '/classix', element: <ClassIx defaultTab="Class IX Planning" /> },
    { path: '/classix/planning', element: <ClassIx defaultTab="Class IX Planning" /> },
    { path: '/classix/list', element: <ClassIx defaultTab="Class IX Block List" /> },
  ]);

  return (
    <AppContextProvider 
      commandData={commandLevels} 
      vehicleData={fullBattalionVehicles} 
      vehiclePlatforms={vehiclePlatforms}
      selectedCommandLevel={defaultCommandLevel}
      selectedOperationStatuses={[]}
      selectedSubsystems={[]}
      selectedPlatforms={[]}
    >
      <RouterProvider router={router} />
    </AppContextProvider>
  )
}

export default App