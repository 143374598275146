import './VehicleBoard.css'

import { useNavigate } from 'react-router-dom'

import DataTable from 'react-data-table-component'
import { depotData } from '../data/staticDepotData'
import { Vehicle as VehicleData } from '../data/vehicleData6'
import PlatformImage from '../components/UnitViews/PlatformImage'


const VehicleBoard = () => {

  const navigate = useNavigate()

  const handleVehicleClick = (id: string) => {
    navigate(`/maintainer/vehicle/detail/${id}`)
  }

  const columns = [
    {
      name: 'PLATFORM',
      selector: (row: VehicleData) => row.id,
      cell: (row: VehicleData) => (
        <div onClick={() => handleVehicleClick(row.id)} className='clickable'>
          <PlatformImage platform={row.platform} />
        </div>
      ),
      sortable: true,
      width: '190px',
    },
    {
      name: 'BUMPER #',
      selector: (row: VehicleData) => row.bumperNumber,
      cell: (row: VehicleData) => (
        <div onClick={() => handleVehicleClick(row.id)} className='clickable'>
          {row.bumperNumber}
        </div>
      ),
      sortable: true,
      width: '130px',
    },
    {
      name: 'REG #',
      selector: (row: VehicleData) => row.registrationNumber,
      cell: (row: VehicleData) => (
        <div onClick={() => handleVehicleClick(row.id)} className='clickable'>
          {row.registrationNumber}
        </div>
      ),
      sortable: true,
      width: 'auto',
    },
    {
      name: 'LOCATION',
      selector: (row: VehicleData) => row.bumperNumber,
      cell: (row: VehicleData) => (
        <div onClick={() => handleVehicleClick(row.id)} className='clickable'>
          MTM
        </div>
      ),
      sortable: true,
      width: 'auto',
    },
    {
      name: 'STATUS',
      selector: (row: VehicleData) => row.status.operationalStatus,
      cell: (row: VehicleData) => (
        <div onClick={() => handleVehicleClick(row.id)} className='clickable'>
          {row.status.operationalStatus}
        </div>
      ),
      sortable: true,
      width: 'auto',
    },
    {
      name: (
        <>
        DEADLINED<br/>(NMCM / NMCS)
        </>
      ),
      selector: (row: VehicleData) => row.status.operationalStatus,
      cell: (row: VehicleData) => (
        <div 
          className='clickable'
          style={{ 
            backgroundColor: (row.status.operationalStatus === 'NMCM' || row.status.operationalStatus === 'NMCS') ? '#850811' : 'transparent',
            padding: '12px',
          }}
        >
          {row.status.operationalStatus === 'NMCM' || row.status.operationalStatus === 'NMCS' ? 'YES' : 'NO'}
        </div>
      ),
      sortable: true,
      width: '150px',
    },
  ]


  
  return (
    <div className='vehicle-board'>
      <DataTable
        columns={columns}
        data={depotData as unknown as VehicleData[]}
        pagination
        responsive
        className='depot-vehicle-data-table'
      />
    </div>
  )
}

export default VehicleBoard