import './ActiveFaultsView.css'
import React from 'react'
import DataTable from 'react-data-table-component'

interface ActiveFaultsViewProps {
}

interface FaultData {
  id: string;
  platform: string;
  tamcn: string;
  vehicleId: string;
  cbmStatus: string;
  timestamp: string;
  spn: string;
  fmi: string;
  faultProt: string;
  faultSubsystem: string;
  faultDescription: string;
  workPackages: string;
}

const columns = [
  {
    name: 'ID',
    selector: (row: FaultData) => row.id,
    sortable: true,
    width: '60px',
  },
  {
    name: 'Platform',
    selector: (row: FaultData) => row.platform,
    sortable: true,
    width: '100px',
  },
  {
    name: 'TAMCN',
    selector: (row: FaultData) => row.tamcn,
    sortable: true,
    width: '100px',
  },
  {
    name: 'Vehicle\nID',
    selector: (row: FaultData) => row.vehicleId,
    sortable: true,
    width: '100px',
  },
  {
    name: 'CBM\nStatus',
    selector: (row: FaultData) => row.cbmStatus,
    sortable: true,
    width: '120px',
  },
  {
    name: (
      <div style={{ whiteSpace: 'pre-line' }}>
        Faults Recorded{'\n'}Timestamp
      </div>
    ),
    selector: (row: FaultData) => row.timestamp,
    sortable: true,
    width: '150px',
  },
  {
    name: 'SPN',
    selector: (row: FaultData) => row.spn,
    sortable: true,
    width: '100px',
  },
  {
    name: 'FMI',
    selector: (row: FaultData) => row.fmi,
    sortable: true,
    width: '80px',
  },
  {
    name: 'Fault\nProt.',
    selector: (row: FaultData) => row.faultProt,
    sortable: true,
    width: '100px',
  },
  {
    name: (
      <div style={{ whiteSpace: 'pre-line' }}>
        Fault{'\n'}Subsystem
      </div>
    ),
    selector: (row: FaultData) => row.faultSubsystem,
    sortable: true,
    width: '120px',
  },
  {
    name: 'Fault\nDescription',
    selector: (row: FaultData) => row.faultDescription,
    sortable: true,
    grow: 2,
    wrap: true,
  },
  {
    name: 'Work\nPackages',
    selector: (row: FaultData) => row.workPackages,
    sortable: true,
    width: '150px',
  },
];

const testData: FaultData[] = [
  {
    id: "F001",
    platform: "JLTV",
    tamcn: "D1234",
    vehicleId: "V789",
    cbmStatus: "CBM Enabled",
    timestamp: "2024-03-20 14:30:00",
    spn: "SPN524",
    fmi: "FMI3",
    faultProt: "P0128",
    faultSubsystem: "Engine",
    faultDescription: "Engine Coolant Temperature Below Threshold",
    workPackages: "WP-2024-123"
  },
  {
    id: "F002",
    platform: "MTVR",
    tamcn: "D5678",
    vehicleId: "V456",
    cbmStatus: "CBM Enabled",
    timestamp: "2024-03-20 15:45:00",
    spn: "SPN100",
    fmi: "FMI4",
    faultProt: "P0401",
    faultSubsystem: "Transmission",
    faultDescription: "Transmission Oil Pressure High",
    workPackages: "WP-2024-124"
  },
  {
    id: "F003",
    platform: "JLTV",
    tamcn: "D1234",
    vehicleId: "V790",
    cbmStatus: "CBM Enabled",
    timestamp: "2024-03-20 16:15:00",
    spn: "SPN190",
    fmi: "FMI2",
    faultProt: "P0563",
    faultSubsystem: "Electrical",
    faultDescription: "System Voltage High",
    workPackages: "WP-2024-125"
  }
];

const ActiveFaultsView: React.FC<ActiveFaultsViewProps> = () => {
  const data: FaultData[] = testData;

  return (
    <div>
      <p style={{ color: '#333', fontSize: '14px', marginBottom: '8px' }}>{testData.length} Active Fault vehicles selected</p>
      <DataTable
        columns={columns}
        data={data}
        pagination
        responsive
      />
    </div>
  )
}

export default ActiveFaultsView;
