import './CheckboxFilterPanel.css'
import { useContext } from 'react'
import AppContext from '../../context/AppContext'
import { OperationStatus } from '../../data/dataUtil'
import { SubSystem } from '../../data/vehicleData6'


interface CheckboxFilterPanelProps {
  title: string
  checkboxes: string[]
  checkboxGroup: string
}

const CheckboxFilterPanel = ({ title, checkboxes, checkboxGroup }: CheckboxFilterPanelProps) => {
  const { selectedOperationStatuses, setSelectedOperationStatuses } = useContext(AppContext)
  const { selectedSubsystems, setSelectedSubsystems } = useContext(AppContext)


  const handleCheckboxChange = (status: string) => {

    // Add or remove checkox from state based on the checkbox group
    if (checkboxGroup === 'operationStatus') {  // --- OPERATION STATUS

      const operationStatus = status as OperationStatus
    
      if (selectedOperationStatuses.includes('Deadlined')) {
        // Check for NMCM and NMCS if 'Deadlined' is selected
        if (selectedOperationStatuses.includes('NMCM') || selectedOperationStatuses.includes('NMCS')) {
          // NMCM or NMCS is selected while Deadlined is active
        }
      }

      if (selectedOperationStatuses.includes(operationStatus)) {
        // Remove the status if it's already selected
        setSelectedOperationStatuses(selectedOperationStatuses.filter(s => s !== operationStatus))
      } else {
        // Add the status if it's not selected
        setSelectedOperationStatuses([...selectedOperationStatuses, operationStatus])
      }
      // console.log('selectedOperationStatuses: ', selectedOperationStatuses)

    } else if (checkboxGroup === 'subsystem') {  // --- SUBSYSTEM

      const subsystem = status as SubSystem
      if (selectedSubsystems.includes(subsystem)) {
        // Remove the status if it's already selected
        setSelectedSubsystems(selectedSubsystems.filter(s => s !== subsystem))
      } else {
        // Add the status if it's not selected
        setSelectedSubsystems([...selectedSubsystems, subsystem])
      }
    } //-end if

  }

  
  return (
    <div className="checkbox-filter-panel" style={{ textAlign: 'left' }}>
      <span className="title">{title}</span>
      <div className="checkbox-container">

        {checkboxGroup === 'operationStatus' ? (
          checkboxes.map((checkbox, index) => (
            <label key={index}>
              <input
                type="checkbox"
                checked={selectedOperationStatuses.includes(checkbox as OperationStatus)}
                disabled={checkbox === 'NMCS'}
                onChange={() => {
                  if (checkbox === 'Deadlined') {
                    const newStatuses = [...selectedOperationStatuses];
                    if (newStatuses.includes('Deadlined')) {
                      // Remove 'Deadlined' and NMCM, NMCS if 'Deadlined' is unchecked
                      setSelectedOperationStatuses(newStatuses.filter(s => s !== 'Deadlined' && s !== 'NMCM' && s !== 'NMCS'));
                    } else {
                      // Add 'Deadlined' and NMCM, NMCS if 'Deadlined' is checked
                      if (!newStatuses.includes('NMCM')) {
                        newStatuses.push('NMCM');
                      }
                      if (!newStatuses.includes('NMCS')) {
                        newStatuses.push('NMCS');
                      }
                      newStatuses.push('Deadlined');
                      setSelectedOperationStatuses(newStatuses);
                    }
                  } else {
                    handleCheckboxChange(checkbox);
                  }
                }}
              />
              <span className="checkbox-label">{checkbox}</span>
              <br />
            </label>
          ))

        ) : (
          
          checkboxes.map((checkbox, index) => (
            <label key={index}>
              <input
                type="checkbox"
                checked={selectedSubsystems.includes(checkbox as SubSystem)}
                onChange={() => handleCheckboxChange(checkbox)}
              />
              <span className="checkbox-label">{checkbox}</span>
              <br />
            </label>
          ))
        )}

      </div>
    </div>
  )
  
}

export default CheckboxFilterPanel
