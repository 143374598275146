import './SuggestedMaintenanceTable.css'
import DataTable from 'react-data-table-component'


const SuggestedMaintenanceTable = () => {
  return (
    <div>
      <DataTable
        columns={columns}
        data={data}
        pagination
        responsive
        paginationComponentOptions={paginationComponentOptions}
        className='suggested-maintenance-table'
      />
    </div>
  )
}

export default SuggestedMaintenanceTable


const columns = [
  {
    name: 'Priority',
    selector: (row: any) => row.priority,
    sortable: true,
    cell: (row: any) => (
      <span className={`priority-badge ${row.priority.toLowerCase()}`}>
        {row.priority}
      </span>
    )
  },
  {
    name: 'Component',
    selector: (row: any) => row.component,
    sortable: true
  },
  {
    name: 'Suggested Action',
    selector: (row: any) => row.action,
    wrap: true,
    grow: 2
  },
  // {
  //   name: 'Due Date',
  //   selector: (row: any) => row.dueDate,
  //   sortable: true,
  //   format: (row: any)   => new Date(row.dueDate).toLocaleDateString()
  // },
  // {
  //   name: 'Hours Until Due',
  //   selector: (row: any) => row.hoursUntilDue,
  //   sortable: true,
  //   cell: (row: any) => (
  //     <span className={row.hoursUntilDue < 24 ? 'text-red-500' : ''}>
  //       {row.hoursUntilDue}h
  //     </span>
  //   )
  // },
  // {
  //   name: 'Status',
  //   selector: (row: any) => row.status,
  //   sortable: true,
  //   cell: (row: any) => (
  //     <span className={`status-badge ${row.status.toLowerCase()}`}>
  //       {row.status}
  //     </span>
  //   )
  // },
  // {
  //   name: 'Assigned To',
  //   selector: (row: any) => row.assignedTo,
  //   sortable: true
  // }
];

const paginationComponentOptions = {
  rowsPerPageText: 'Rows per page:',
  rangeSeparatorText: 'of',
  selectAllRowsItem: true,
  selectAllRowsItemText: 'All'
};


const data = [
  {
    id: 1,
    priority: 'HIGH',
    component: 'Track Assembly',
    action: 'Replace worn track pads and inspect tension system',
    dueDate: '2024-12-10',
    hoursUntilDue: 12,
    status: 'PENDING',
    assignedTo: 'SGT Johnson'
  },
  {
    id: 2,
    priority: 'MEDIUM',
    component: 'Engine Oil System',
    action: 'Oil change and filter replacement',
    dueDate: '2024-12-15',
    hoursUntilDue: 72,
    status: 'SCHEDULED',
    assignedTo: 'SPC Williams'
  },
  {
    id: 3,
    priority: 'HIGH',
    component: 'Turret Drive',
    action: 'Replace servo control unit and calibrate system',
    dueDate: '2024-12-11',
    hoursUntilDue: 24,
    status: 'IN_PROGRESS',
    assignedTo: 'SSG Martinez'
  },
  {
    id: 4,
    priority: 'LOW',
    component: 'TOW Launcher',
    action: 'Routine inspection and wire harness check',
    dueDate: '2024-12-20',
    hoursUntilDue: 168,
    status: 'SCHEDULED',
    assignedTo: 'SPC Anderson'
  },
  {
    id: 5,
    priority: 'MEDIUM',
    component: 'Brake System',
    action: 'Replace brake pads and inspect hydraulic lines',
    dueDate: '2024-12-18',
    hoursUntilDue: 96,
    status: 'PENDING',
    assignedTo: 'SGT Thompson'
  }
];