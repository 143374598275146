import './ServiceHistoryDataTable.css'
import { Vehicle as VehicleData, MaintenanceRecord } from "../../data/vehicleData6"
import DataTable, { TableColumn } from 'react-data-table-component'


interface Props {
  vehicleData: VehicleData;
}

const ServiceHistoryDataTable: React.FC<Props> = ({ vehicleData }: Props) => {

  return (
    <div className="service-history-data-table">
      <DataTable
        columns={columns as TableColumn<MaintenanceRecord>[]}
        data={vehicleData.maintenanceHistory.records}
        pagination
        responsive
        className='service-data-table'
      />
    </div> 
  )
}

export default ServiceHistoryDataTable



// ------------------------------------------------------------

const columns = [
  {
    name: 'DATE',
    selector: (row: MaintenanceRecord) => row.date,
    cell: (row: MaintenanceRecord) => (
      <div>
        {row.date}
      </div>
    ),
    sortable: true,
    width: '180px',
  },
  {
    name: 'WORK ORDER',
    selector: (row: MaintenanceRecord) => row.workOrderNumber,
    cell: (row: MaintenanceRecord) => (
      <div>
        {row.workOrderNumber}
      </div>
    ),
    sortable: true,
    width: '180px',
  },
  {
    name: 'STATUS',
    selector: (row: MaintenanceRecord) => row.workOrderNumber,
    //@ts-ignore
    cell: (row: MaintenanceRecord) => (
      <div>
        COMPLETE
      </div>
    ),
    sortable: true,
    width: '180px',
  },
  {
    name: 'TECH ID',
    selector: (row: MaintenanceRecord) => row.technicianId,
    cell: (row: MaintenanceRecord) => (
      <div>
        {row.technicianId}
      </div>
    ),
    sortable: true,
    width: '180px',
  },
  {
    name: 'FAULTS',
    selector: (row: MaintenanceRecord) => row.faults,
    cell: (row: MaintenanceRecord) => (
      <div>
        <div>{row.faults.length}</div>
        <div className='neutral-label'>Total</div>
      </div>
    ),
    sortable: true,
    width: '180px',
  },
  {
    name: 'NOTES',
    selector: (row: MaintenanceRecord) => row.qualityControl.remarks,
    cell: (row: MaintenanceRecord) => (
      <div style={{ textAlign: 'left', width: '100%' }}>
        {/* {row.qualityControl.passed ? 
          <span className='success-label'>Passed</span> : 
          <span className='error-label'>Failed</span>
        }  */}
        
        {row.qualityControl.remarks}. {row.subsystem} repairs.

      </div>
    ),
    sortable: false,
    width: 'auto',
  },
]