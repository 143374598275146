import "./MetricPanel.css"
import ReadinessMetricCard from "./cards/ReadinessMetricCard"
import DeadlinedMetricCard from "./cards/DeadlinedMetricCard"
import SummaryMetricCard from "./cards/SummaryMetricCard"
import DowntimeMetricCard from "./cards/DowntimeMetricCard"
import TimeToReadinessMetricCard from "./cards/TimeToReadinessMetricCard"
import { calculateMaintenanceStats } from "../data/dataUtil"
import { Vehicle } from "../data/vehicleData6"


const MetricPanel = ({ vehicleData }: { vehicleData: Vehicle[] }) => {

  const stats = calculateMaintenanceStats(vehicleData)
  // console.log('maintenanceStats', stats)

  return (
    <div className="metric-panel"> 
      
      <SummaryMetricCard 
        fmcPercent={stats.fmcPercent}
        pmcPercent={stats.pmcPercent}
        nmcmPercent={stats.nmcmPercent}
        nmcsPercent={stats.nmcsPercent}
        fmcCount={stats.fmcCount}
        pmcCount={stats.pmcCount}
        nmcmCount={stats.nmcmCount}
        nmcsCount={stats.nmcsCount}
      />

      <TimeToReadinessMetricCard 
        timeToReadinessDays={21} 
        timeToReadinessCost={480000} 
      />

      <ReadinessMetricCard 
        readinessPercent={stats.operationalPercent} 
        deadlinedPercent={stats.deadlinedPercent} 
      />

      <DeadlinedMetricCard 
        readinessPercent={stats.operationalPercent} 
        deadlinedPercent={stats.deadlinedPercent}  
        deadlinedCount={stats.deadlinedCount}  
      />
      
      <DowntimeMetricCard downtimeDays={57} />

      {/* <VehicleOverviewCard  
        overdueCount={144} 
        activeFaultsCount={1543} 
        warningsCount={781}  
      /> */}
    </div>
  )
}

export default MetricPanel
