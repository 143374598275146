import "./MaintainerVehicleDetailViewNavbar.css"
import { useParams, useNavigate } from "react-router-dom"
import AddMaintenanceForm from './AddMaintenanceForm'
import { useState } from 'react'

interface UnitPanelProps {
  defaultTab?: string
}

const UnitPanel = ({ defaultTab = 'Readiness' }: UnitPanelProps) => {
  const { tab } = useParams()
  const navigate = useNavigate()

  const tabMapping: { [key: string]: string } = {
    // 'fauls': "Active Faults & Warnings",
    "detail": "Service Request History",
  }

  //@ts-ignore
  const activeTab = tabMapping[tab?.toLowerCase() ?? ""] || tab || defaultTab

  const tabs = [
    // "Active Faults & Warnings",
    "Service Request History",
  ]

  const [isGutterOpen, setGutterOpen] = useState(false)

  //@ts-ignore
  const handleTabChange = (tab: string) => {
    const urlTab =
      Object.entries(tabMapping).find(([_, value]) => value === tab)?.[0] ||
      tab.toLowerCase().replace(" ", "-")
    navigate(`/maintainer/vehicle/${urlTab}`)
  }

  const handleGutterToggle = () => {
    setGutterOpen(!isGutterOpen)
  }

  const onCancel = () => {
    setGutterOpen(false)
  }

  const onSave = () => {
    setGutterOpen(false)
  }

  return (
    <div className="maintainer-vehicle-view-navbar">
      <div className="container">
        {tabs.map((tab) => (
          <button
            key={tab}
            // className={`${activeTab === tab ? "active-tab" : "inactive-tab"}`}
            className='active-tab-no-button'
          >
            {tab}
          </button>
        ))}

        <button className="placeholder-tab" disabled></button>

        <button className="inactive-tab maintenance-record-button" onClick={handleGutterToggle}>
          <span className='go-back brand-yellow'>
            <i className="icon-chevron-left"></i>
            &nbsp;&nbsp; ADD RECORD
          </span>
        </button>
      </div>
      {isGutterOpen && (
        <div className={`side-gutter ${isGutterOpen ? 'open' : ''}`}>
          <AddMaintenanceForm onCancel={onCancel} onSave={onSave} />
          <button onClick={handleGutterToggle}>Close</button>
        </div>
      )}
    </div>
  )
}

export default UnitPanel
