export const commandLevels = [
  {
    id: "Corps",
    level: "Corps",
  },
  {
    id: "Division",
    level: "Division",
  },
  {
    id: "Brigade",
    level: "Brigade",
  },
  { 
    id: "Battalion",
    level: "Battalion",
  },
  {
    id: "Company",
    level: "Company",
  },
  {   
    id: "Platoon",
    level: "Platoon",
  }
]
export const commandData = [
  // {
  //   id: "army",
  //   level: "U.S. Army",
  //   commander: "General (4-star)",
  //   parentId: null,
  //   personnel_size: "approximately 485,000 active duty",
  //   vehicle_fleet: {
  //     combat_vehicles: "~15,000",
  //     tactical_vehicles: "~225,000",
  //     support_vehicles: "~150,000",
  //     aircraft: "~4,000"
  //   }
  // },
  {
    id: "army_command",
    level: "Army Command",
    commander: "General (4-star)",
    parentId: "army",
    personnel_size: "varies by command",
    vehicle_fleet: {
      combat_vehicles: "varies by command",
      tactical_vehicles: "~75,000 per command",
      support_vehicles: "~50,000 per command",
      aircraft: "varies by command"
    }
  },
  {
    id: "corps",
    level: "Corps",
    commander: "Lieutenant General (3-star)",
    parentId: "army_command",
    personnel_size: "20,000-45,000",
    vehicle_fleet: {
      combat_vehicles: "~3,000-4,000",
      tactical_vehicles: "~15,000",
      support_vehicles: "~10,000",
      aircraft: "~300"
    }
  },
  {
    id: "division",
    level: "Division",
    commander: "Major General (2-star)",
    parentId: "corps",
    personnel_size: "10,000-15,000",
    vehicle_fleet: {
      combat_vehicles: "~300-500",
      tactical_vehicles: "~4,000",
      support_vehicles: "~2,500",
      aircraft: "~100"
    }
  },
  {
    id: "brigade",
    level: "Brigade Combat Team (BCT)",
    commander: "Colonel (O-6)",
    parentId: "division",
    personnel_size: "3,000-5,000",
    variants: ["Infantry BCT", "Armored BCT", "Stryker BCT"],
    vehicle_fleet: {
      infantry_bct: {
        combat_vehicles: "~100",
        tactical_vehicles: "~1,000",
        support_vehicles: "~500",
        aircraft: "0-10"
      },
      armored_bct: {
        combat_vehicles: "~200",
        tactical_vehicles: "~1,200",
        support_vehicles: "~600",
        aircraft: "0-10"
      },
      stryker_bct: {
        combat_vehicles: "~300",
        tactical_vehicles: "~1,100",
        support_vehicles: "~550",
        aircraft: "0-10"
      }
    }
  },
  {
    id: "battalion",
    level: "Battalion/Squadron",
    commander: "Lieutenant Colonel (O-5)",
    parentId: "brigade",
    personnel_size: "300-1,000",
    variants: {
      regular: "Battalion",
      cavalry: "Squadron"
    },
    vehicle_fleet: {
      combat_vehicles: "30-70",
      tactical_vehicles: "~200",
      support_vehicles: "~100",
      aircraft: "0-4"
    }
  },
  {
    id: "company",
    level: "Company/Battery/Troop",
    commander: "Captain (O-3)",
    parentId: "battalion",
    personnel_size: "100-200",
    variants: {
      infantry: "Company",
      artillery: "Battery",
      cavalry: "Troop"
    },
    vehicle_fleet: {
      combat_vehicles: "10-14",
      tactical_vehicles: "~40",
      support_vehicles: "~20",
      aircraft: "0-2"
    }
  },
  {
    id: "platoon",
    level: "Platoon",
    commander: "Lieutenant (O-1/O-2)",
    parentId: "company",
    personnel_size: "16-44",
    vehicle_fleet: {
      combat_vehicles: "4-5",
      tactical_vehicles: "~10",
      support_vehicles: "~5",
      aircraft: "0"
    }
  },
  {
    id: "squad",
    level: "Squad",
    commander: "Staff Sergeant (E-6)/Sergeant (E-5)",
    parentId: "platoon",
    personnel_size: "9-10",
    vehicle_fleet: {
      combat_vehicles: "1-2",
      tactical_vehicles: "1-2",
      support_vehicles: "0-1",
      aircraft: "0"
    }
  },
  {
    id: "team",
    level: "Team",
    commander: "Sergeant (E-5)/Corporal (E-4)",
    parentId: "squad",
    personnel_size: "4-5",
    vehicle_fleet: {
      combat_vehicles: "0-1",
      tactical_vehicles: "0-1",
      support_vehicles: "0",
      aircraft: "0"
    }
  }
];

// Helper function to find children of a given unit
export const findChildren = (parentId: string) => {
  return commandData.filter(unit => unit.parentId === parentId);
};

// Helper function to find parent of a given unit
export const findParent = (unitId: string) => {
  const unit = commandData.find(u => u.id === unitId);
  return unit ? commandData.find(u => u.id === unit.parentId) : null;
};

// Example usage:
// Get all direct subordinate units of a division:
// console.log(findChildren("division"));

// Get the parent unit of a battalion:
// console.log(findParent("battalion"));

