import "./ClassIx.css"
import { useContext } from 'react'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

import AppContext from '../context/AppContext'
import Navbar from "../components/Navbar"
import TopFilterPanel from "../components/Filters/TopFilterPanel"
import ClassIxViewNavbar  from "../components/ClassIxViews/ClassIxViewNavbar"
import ClassIxPlanningView from '../components/ClassIxViews/ClassIxPlanningView'
import ClassIxBlockListView from '../components/ClassIxViews/ClassIxBlockListView'



interface Props {
  defaultTab?: string;
}

function ClassIx({ defaultTab = 'Class IX Planning' }: Props) {
  const navigate = useNavigate()
  const { tab } = useParams()
  const { selectedCommandLevel, setSelectedCommandLevel } = useContext(AppContext)

  const tabMapping: { [key: string]: string } = {
    'planning': 'Class IX Planning',
    'list': 'Class IX Block List'
  }
  const activeTab = tabMapping[tab?.toLowerCase() ?? ''] || tab || defaultTab

  const handleBackClick = () => {
    navigate('/command/fleet/readiness')
  }

  return (
    <div className="fleet-page">
      <Navbar />
      <TopFilterPanel selectedCommandLevel={selectedCommandLevel} setSelectedCommandLevel={setSelectedCommandLevel} />

      <div className="data-container">
        <div className="side-view">
          <span className='go-back brand-yellow' onClick={handleBackClick}>
            <i className="icon-chevron-left"></i>
            &nbsp;&nbsp; 
            GO BACK
          </span>
        </div>

        <div>
          <div className="unit-view-panel">
            <ClassIxViewNavbar defaultTab={defaultTab} />
          </div>
          <div className="main-view">          
            <div>
              {activeTab === "Class IX Planning" && <ClassIxPlanningView />}
              {activeTab === "Class IX Block List" && <ClassIxBlockListView />}
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}

export default ClassIx