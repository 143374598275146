import "./FaultsWarningsMetricCard.css"

interface Props {
  title: string;
  faultCount: number;
  imagePath: string;
}

const FaultsWarningsMetricCard = ({ title, faultCount, imagePath }: Props) => {


  return (


    <div className="faults-warnings-metric-card">
      <p className="title">{title}</p>
      <div className="content">
        <div>
          <img src={imagePath} alt={title} />
        </div>
        <div className="values">
          <div className="label">FAULTS</div>
          <div className={faultCount > 0 ? "active-value" : "inactive-value"}>{faultCount}</div>
        </div>
      </div>
    </div>
  )
}

export default FaultsWarningsMetricCard
