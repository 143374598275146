import "./DowntimeMetricCard.css"


const DeadlinedMetricCard = ({ downtimeDays }: 
  { downtimeDays: number; }) => {


  return (
    <div className="downtime-metric-card">
      <p className="title">
        Average Downtime<br/>
        <span className="subtitle">(Deadlined Vehicles)</span>
      </p>
      
      <div className="content">
        <div className="value">{downtimeDays}</div>
        <span className="label">Days</span>
      </div>
      <div className="action"></div>
    </div>
  )
}

export default DeadlinedMetricCard
