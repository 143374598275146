import "./Navbar.css"
import { useState } from "react"
import { useNavigate, useLocation } from "react-router-dom"


const Navbar = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [dropdownVisible, setDropdownVisible] = useState(false)
  const isMaintainerView = location.pathname.includes('/maintainer')


  return (
    <nav>
      <div className="nav-container">
        <div className="left-buttons">
          <button className="button-2" onClick={() => setDropdownVisible(!dropdownVisible)}>
            <i className="icon-reorder nav-icon"></i> 
            {isMaintainerView ? (
              <>&nbsp; <span className="nav-text">MAINTAINER VIEW</span></> 
            ) : (
              <>&nbsp; <span className="nav-text">COMMAND VIEW</span></>
            )}
          </button>
          {dropdownVisible && (
            <div className="dropdown" onMouseLeave={() => setDropdownVisible(!dropdownVisible)}>
              <button onClick={() => navigate('/command/fleet')}>COMMAND VIEW</button>
              <button onClick={() => navigate('/maintainer/board')}>MAINTAINER VIEW</button>
            </div>
          )}
          
            {isMaintainerView ? (
              <button className="breadcrumb" onClick={() => navigate('/maintainer/board')}>
                <i className="icon-home nav-icon large-icon"></i>
                &nbsp; <span className="nav-text">VEHICLE BOARD</span>
              </button>
            ) : (
              <button className="breadcrumb" onClick={() => navigate('/command/fleet/readiness')}>
                <i className="icon-home nav-icon large-icon"></i>
                &nbsp; <span className="nav-text">READINESS OVERVIEW</span>
              </button>
            )}
          
        </div>

        <div className="right-buttons">
          <button className="button-2">
            <i className="icon-comment-alt nav-icon"></i> 
            &nbsp; <span className="nav-text">FEEDBACK</span>
          </button>
          {/* <button className="button-2" onClick={() => navigate('/info')}>
            <i className="icon-info-sign nav-icon"></i>
            &nbsp; <span className="nav-text">INFO</span>
          </button> */}
          <button className="button-2">
            <i className="icon-file-text nav-icon"></i> 
            &nbsp; <span className="nav-text">
              {location.pathname.includes("/command/vehicle/detail/") || 
              location.pathname.includes("/maintainer/vehicle/detail/") ||
              location.pathname.includes("/classix/planning") ||
              location.pathname.includes("/classix/list") ? "PRINT" : "EXPORT"}
            </span>
          </button>
          <button className="button-2" onClick={() => navigate('/')}>
            <i className="icon-signout nav-icon"></i>
            &nbsp; <span className="nav-text">SIGNOUT</span>
          </button>
        </div>
      </div>
    </nav>
  )
}

export default Navbar
