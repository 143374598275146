import "./Info.css"
import Navbar from "../components/Navbar"


const Info = () => {
  return (
    <div className="info-view">
      <Navbar />

      <h1>Dashboard Mockups</h1>
      <h3 className="subtitle">Mockups are used as refrences. The final product will have subtle differences.</h3>

      <br />
      <div className="original-fleet-view">
        <h2>Original Dashboard</h2>
        <img src="/images/figma-original-fleet-view.png" width={1300} />
      </div>
      <br/><br/>

      <h2>Readiness Overview</h2>
      <img src="/images/figma-fleet-view.png" />
      <br/><br/><br/>

      <h2>Vehicle View</h2>
      <img src="/images/figma-vehicle-view.png" />
      <br/><br/><br/>
      
      <h2>Map View</h2>
      <img src="/images/figma-map-view.png" />
      <br/><br/><br/>
      
      <h2>Scenario Planning</h2>
      <img src="/images/figma-classIX-planning-view.png" />
      <br/><br/>
      <i className="icon-arrow-down large-icon"></i>
      <br/><br/>
      <img src="/images/figma-classIX-planning-view-2.png" />
      
      <br /><br /><br /><br />

    </div>
  )
}

export default Info