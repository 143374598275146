import AppContext from '../../context/AppContext';
import './ButtonFilterPanel.css'
import { useContext } from 'react'

interface ButtonFilterPanelProps {
  title: string
  buttons: { label: string }[]
  buttonGroup: string
}

//@ts-ignore
const ButtonFilterPanel = ({ title, buttons, buttonGroup }: ButtonFilterPanelProps) => {
  const { selectedPlatforms, setSelectedPlatforms } = useContext(AppContext)

  const handleButtonClick = (platform: string) => {
    if (selectedPlatforms.includes(platform)) {
      setSelectedPlatforms(selectedPlatforms.filter(p => p !== platform))
    } else {
      setSelectedPlatforms([...selectedPlatforms, platform])
    }
  }

  return (
    <div className="button-filter-panel">
      <span className="title">{title}</span>
      <div className="button-container">
        {buttons.map((button, index) => (
          <button
            key={index}
            onClick={() => handleButtonClick(button.label)}
            className={selectedPlatforms.includes(button.label) ? 'selected' : ''}
          >
            {button.label}
          </button>
        ))}
      </div>
    </div>
  )
}

export default ButtonFilterPanel
