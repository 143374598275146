import './Login.css';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';


const LoginForm = () => {
  const navigate = useNavigate();

  const [credentials, setCredentials] = useState({
    username: '',
    password: ''
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setCredentials({ ...credentials, [name]: value });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Check credentials
    if (credentials.username === 'Barbaricum' && credentials.password === 'BarbaricumCORE') {
      navigate('/command/fleet')
    } else {
      alert('Invalid username or password');
    }
  };

  return (
    <div className='login-page'>
    
      <img src='images/core-logo.png' alt='Core Logo'  />
      <br/><br/>

      <div className='login-form'>
        {/* <div className='title'>Login</div> */}
        <form onSubmit={handleSubmit}>
          <div className='input-group'>
            <label className='label'>Username</label>
            <input
              type='text'
              name='username'
              value={credentials.username}
              onChange={handleChange}
              required
            />
          </div>
          <div className='input-group'>
            <label className='label'>Password</label>
            <input
              type='password'
              name='password'
              value={credentials.password}
              onChange={handleChange}
              required
            />
          </div>
          <div className='button-container'>
            <button type='submit' className='btn-login'>Signin</button>
          </div>
        </form>
      </div>

    </div>
  );
};

export default LoginForm;