import "./UnitViewNavbar.css"
import { useParams, useNavigate } from "react-router-dom"


interface UnitPanelProps {
  defaultTab?: string
}

const UnitPanel = ({ defaultTab = 'Readiness' }: UnitPanelProps) => {
  const { tab } = useParams()
  const navigate = useNavigate()

  const tabMapping: { [key: string]: string } = {
    readiness: "Readiness",
    "vehicle-list": "Vehicle List",
    // "active-faults": "Active Faults",
    // "potential-warnings": "Potential Warnings",
    // "service-request-history": "Service Request History",
    // "total-service-request-history": "Total Service Request History", 
  }
  const activeTab = tabMapping[tab?.toLowerCase() ?? ""] || tab || defaultTab

  const tabs = [
    "Readiness",
    "Vehicle List",
    // "Active Faults",
    // "Potential Warnings",
    // "Service Request History",
    // "Total Service Request History",
  ]

  const handleTabChange = (tab: string) => {
    const urlTab =
      Object.entries(tabMapping).find(([_, value]) => value === tab)?.[0] ||
      tab.toLowerCase().replace(" ", "-")
    navigate(`/command/fleet/${urlTab}`)
  }

  return (
    <div className="unit-view-navbar">
      <div className="container">
        {tabs.map((tab) => (
          <button
            key={tab}
            className={`${activeTab === tab ? "active-tab" : "inactive-tab"}`}
            onClick={() => handleTabChange(tab)}
          >
            {tab}
          </button>
        ))}
        
        <button className="placeholder-tab" disabled></button>
        
        <button className="inactive-tab classix-button" onClick={() => navigate('/classix')}>
          <span className='go-back brand-yellow'>
            &nbsp;&nbsp; CLASS IX PLANNING
          </span>
        </button>
      </div>
    </div>
  )
}

export default UnitPanel
