import { useNavigate } from "react-router-dom";
import "./TimeToReadinessMetricCard.css"
import { formatToDollar } from "../../utils/utils";


const TimeToReadinessMetricCard = ({ timeToReadinessDays, timeToReadinessCost }: 
  { timeToReadinessDays: number; timeToReadinessCost: number; }) => {

  const navigate = useNavigate()

  return (
    <div className="time-to-readiness-metric-card">
      <span className="title">Time to <span className="text-yellow">90%</span> Readiness</span>
      <span className="value">{timeToReadinessDays} <span className="label">DAYS</span></span>
      <span className="title">Cost to Readiness</span>
      <span className="value">{formatToDollar(timeToReadinessCost)}</span>
      <a className="action" onClick={() => navigate('/classix/planning')}>
        CLASS IX PLANNING
      </a>
    </div>
  )
}

export default TimeToReadinessMetricCard
