// // Generic helper function to find children in any hierarchical data structure
// const findChildren = (data, parentValue, parentField = 'parentId') => {
//   return data.filter(item => item[parentField] === parentValue);
// };

// // Generic helper function to find parent in any hierarchical data structure
// const findParent = (data, itemValue, idField = 'id', parentField = 'parentId') => {
//   const item = data.find(entry => entry[idField] === itemValue);
//   return item ? data.find(entry => entry[idField] === item[parentField]) : null;
// };


/**
 * Formats a number to a dollar amount
 * @param amount - The number to format
 * @returns string
 */
export function formatToDollar(amount: number): string {
  if (isNaN(amount)) {
    // throw new Error("Input must be a valid number");
    return amount.toString()
  }

  return `$${amount.toLocaleString('en-US')}`
}


/**
 * Returns the current date in the format yyyy-mm-dd
 * @returns string
 */
export function getCurrentDate(): string {
  const date = new Date()
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const day = date.getDate().toString().padStart(2, '0')
  const year = date.getFullYear().toString()

  return `${year}-${month}-${day}`;
}


/**
 * Returns the current year in the format yyyy
 * @returns string
   */
export function getCurrentYear(): string {
  const date = new Date()
  const year = date.getFullYear().toString()
  return year
}

