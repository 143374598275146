import "./VehicleDetailViewNavbar.css"
import { useParams, useNavigate } from "react-router-dom"


interface UnitPanelProps {
  defaultTab?: string
}

const UnitPanel = ({ defaultTab = 'Readiness' }: UnitPanelProps) => {
  const { tab } = useParams()
  //@ts-ignore: ignore unused function
  const navigate = useNavigate()

  const tabMapping: { [key: string]: string } = {
    // 'fauls': "Active Faults & Warnings",
    "detail": "Service Request History",
  }
  const activeTab = tabMapping[tab?.toLowerCase() ?? ""] || tab || defaultTab

  const tabs = [
    // "Active Faults & Warnings",
    "Service Request History",
  ]

  // const handleTabChange = (tab: string) => {
  //   const urlTab =
  //     Object.entries(tabMapping).find(([_, value]) => value === tab)?.[0] ||
  //     tab.toLowerCase().replace(" ", "-")
  //   navigate(`/command/vehicle/detail/${urlTab}`)
  // }

  return (
    <div className="vehicle-view-navbar">
      <div className="container">
        {tabs.map((tab) => (
          <button
            key={tab}
            className={`${activeTab === tab ? "active-tab" : "inactive-tab"}`}
            // onClick={() => handleTabChange(tab)}
          >
            {tab}
          </button>
        ))}
        <button className="placeholder-tab" disabled></button>
      </div>
    </div>
  )
}

export default UnitPanel
